import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Essenced7_slide from "./Essenced7_slide";

const Tour_EssenceDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Essenced7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Kandy </h2>
      </div>
      <p>Leisure</p>
    </div>
  );
};

export default Tour_EssenceDay7;
