import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Wildlifed13_slide from "./Wildlifed13_slide";

const Tour_WildlifeDay13 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Wildlifed13_slide />
      </div>
      <div className="line">
        <h3>Day 13 : </h3>
        <h2>Kalpitiya - CMB Airport [04:00]</h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_WildlifeDay13;
