import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Rushd10_slide from "./Rushd10_slide";

const Tour_RushDay10 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Rushd10_slide />
      </div>
      <div className="line">
        <h3>Day 10 : </h3>
        <h2>Sinharaja Forest Reserve </h2>
      </div>
      <p>Trek through the Sinharaja Rainforest -UNESCO [03:00]</p>
    </div>
  );
};

export default Tour_RushDay10;
