import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sample from "../assets/images/SlickSlider/newimages/history.png";
import "../assets/css/TourSlides.css";


import one from "../assets/images/slslides/Sigiriya.png"
import two from "../assets/images/slslides/SriLankanSpiceGarden.png"

const Budsd6_slide = () => {

    var settings = {
        dots: false,
        infinite: true,
    
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
      };
    return (
        <div className="slider">
        <Slider {...settings}  >
            <div className="tile">
                <img alt="" src={one} />
                <h2>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</h2>
            </div>
            <div className="tile">
                <img alt="" src={two} />
                <h2>Walk through a Sri Lankan spice garden with Lunch [01:30]</h2>
            </div>
            
            
            
            

            
        </Slider>
        </div>
    )
}

export default Budsd6_slide
