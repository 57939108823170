import React from "react";

import "../assets/css/Tour_Destinations.css";
import Destinations13_slide from "./Destinations13_slide";

import Destinations1_slide from "./Destinations1_slide";

const Destinations13 = () => {
  return (
    <div className="tour_Destinations">
      <div className="slides">
        <Destinations13_slide />
      </div>
      {/* <div className="line"></div> */}
    </div>
  );
};

export default Destinations13;
