import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_IslandDay3 from "./TourSlides_IslandDay3";

const Tour_IslandDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_IslandDay3 />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Ella </h2>
      </div>
      <p>Little Adam’s Peak Hike [02:30]</p>
      <p>Nine Arch Bridge [00:30]</p>
    </div>
  );
};

export default Tour_IslandDay3;
