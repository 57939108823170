import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_HistoryDay1 from "./TourSlides_HistoryDay1";

const Tour_HistoryDay1 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_HistoryDay1 />
      </div>
      <div className="line">
        <h3>Day 01 : </h3>
        <h2>CMB Airport- Sigiriya [03:30]</h2>
      </div>
      <p>Unwind</p>
    </div>
  );
};

export default Tour_HistoryDay1;
