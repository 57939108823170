import anantaraVeli from "../assets/images/Maldives/sampeimg.jpg";
import Cocoon from "../assets/images/Maldives/sampeimg.jpg";
import Dhigali from "../assets/images/Maldives/sampeimg.jpg";
import HardRock from "../assets/images/Maldives/sampeimg.jpg";
import Kudafushi from "../assets/images/Maldives/sampeimg.jpg";
import Kurumba from "../assets/images/Maldives/sampeimg.jpg";
import SaiiLagoon from "../assets/images/Maldives/sampeimg.jpg";
import SunSiyam from "../assets/images/Maldives/sampeimg.jpg";
import Emereld from "../assets/images/Maldives/sampeimg.jpg";
import lti from "../assets/images/Maldives/sampeimg.jpg";
import Movenpick from "../assets/images/Maldives/sampeimg.jpg";
import Pullman from "../assets/images/Maldives/sampeimg.jpg";
import Seaside from "../assets/images/Maldives/sampeimg.jpg";
import IruFushi from "../assets/images/Maldives/sampeimg.jpg";
import YouandMe from "../assets/images/Maldives/sampeimg.jpg";
import Amilla from "../assets/images/Maldives/sampeimg.jpg";
import Dhigu from "../assets/images/Maldives/sampeimg.jpg";
import Baros from "../assets/images/Maldives/sampeimg.jpg";
import Como from "../assets/images/Maldives/sampeimg.jpg";
import ComoMaali from "../assets/images/Maldives/sampeimg.jpg";
import Gili from "../assets/images/Maldives/sampeimg.jpg";
import Huruwalhi from "../assets/images/Maldives/sampeimg.jpg";
import Kandolhu from "../assets/images/Maldives/sampeimg.jpg";
import Milaidhoo from "../assets/images/Maldives/sampeimg.jpg";
import Niyama from "../assets/images/Maldives/sampeimg.jpg";
import Patina from "../assets/images/Maldives/sampeimg.jpg";
import SixSenses from "../assets/images/Maldives/sampeimg.jpg";
import Vommuli from "../assets/images/Maldives/sampeimg.jpg";
import Vakkaru from "../assets/images/Maldives/sampeimg.jpg";
import Velessaru from "../assets/images/Maldives/sampeimg.jpg";
import WMaldives from "../assets/images/Maldives/sampeimg.jpg";
import Kudadoo from "../assets/images/Maldives/sampeimg.jpg";
import Raffles from "../assets/images/Maldives/sampeimg.jpg";
import Sonevafushi from "../assets/images/Maldives/sampeimg.jpg";
import SonevaJani from "../assets/images/Maldives/sampeimg.jpg";
import Nautilus from "../assets/images/Maldives/sampeimg.jpg";
import Waldorf from "../assets/images/Maldives/sampeimg.jpg";
import Conrad from "../assets/images/Maldives/sampeimg.jpg";

export default [
  {
    id: 1,
    image: anantaraVeli,
    title: "Anantara Veli",
    category: "Deluxe",
  },
  {
    id: 2,
    image: Cocoon,
    title: "Cocoon Maldives",
    category: "Deluxe",
  },
  {
    id: 3,
    image: Dhigali,
    title: "Dhigali Maldives",
    category: "Deluxe",
  },
  {
    id: 4,
    image: HardRock,
    title: "Hard Rock Hotel",
    category: "Deluxe",
  },
  {
    id: 5,
    image: Kudafushi,
    title: "Kudafushi Resort & Spa",
    category: "Deluxe",
  },
  {
    id: 6,
    image: Kurumba,
    title: "Kurumba Maldives",
    category: "Deluxe",
  },
  {
    id: 7,
    image: SaiiLagoon,
    title: "SAii Laggon, Curio Collection by Hilton",
    category: "Deluxe",
  },
  {
    id: 8,
    image: SunSiyam,
    title: "Sun Siyam Vilu Reef",
    category: "Deluxe",
  },
  {
    id: 9,
    image: Emereld,
    title: "Emerald Maldives",
    category: "SuperDeluxe",
  },
  {
    id: 10,
    image: lti,
    title: "LTI Maafushivaru",
    category: "SuperDeluxe",
  },
  {
    id: 11,
    image: Movenpick,
    title: "Movenpick Resort Kuredhivaru",
    category: "SuperDeluxe",
  },
  {
    id: 12,
    image: Pullman,
    title: "Pullman Maldives Maamutaa",
    category: "SuperDeluxe",
  },
  {
    id: 13,
    image: Seaside,
    title: "Seaside Finolhu",
    category: "SuperDeluxe",
  },
  {
    id: 14,
    image: IruFushi,
    title: "Sun Siyam Iru Fushi",
    category: "SuperDeluxe",
  },
  {
    id: 15,
    image: YouandMe,
    title: "You & Me Maldives",
    category: "SuperDeluxe",
  },
  {
    id: 16,
    image: Amilla,
    title: "Amilla Fushi",
    category: "Luxury",
  },
  {
    id: 17,
    image: Dhigu,
    title: "Anantara Dhigu",
    category: "Luxury",
  },
  {
    id: 18,
    image: Baros,
    title: "Baros Maldives",
    category: "Luxury",
  },
  {
    id: 19,
    image: Como,
    title: "COMO Cocoa Island",
    category: "Luxury",
  },
  {
    id: 20,
    image: ComoMaali,
    title: "COMO Maalifushi",
    category: "Luxury",
  },
  {
    id: 21,
    image: Conrad,
    title: "Conrad Maldives",
    category: "Luxury",
  },
  {
    id: 22,
    image: Gili,
    title: "Gili Lankanfushi",
    category: "Luxury",
  },
  {
    id: 23,
    image: Huruwalhi,
    title: "Hurawalhi Island",
    category: "Luxury",
  },
  {
    id: 24,
    image: Kandolhu,
    title: "Kandolhu Maldives",
    category: "Luxury",
  },
  {
    id: 25,
    image: Milaidhoo,
    title: "Milaidhoo Island",
    category: "Luxury",
  },
  {
    id: 26,
    image: Niyama,
    title: "Niyama Private Islands",
    category: "Luxury",
  },
  {
    id: 27,
    image: Patina,
    title: "Patina Maldives",
    category: "Luxury",
  },
  {
    id: 28,
    image: SixSenses,
    title: "Six Senses Laamu",
    category: "Luxury",
  },
  {
    id: 29,
    image: Vommuli,
    title: "The St. Regis Maldives Vommuli Resort",
    category: "Luxury",
  },
  {
    id: 30,
    image: Vakkaru,
    title: "Vakkaru Maldives",
    category: "Luxury",
  },
  {
    id: 31,
    image: Velessaru,
    title: "Velessaru Maldives",
    category: "Luxury",
  },
  {
    id: 32,
    image: WMaldives,
    title: "W Maldives",
    category: "Luxury",
  },
  {
    id: 33,
    image: Kudadoo,
    title: "Kudadoo Private Island",
    category: "Ultra Luxury",
  },
  {
    id: 34,
    image: Raffles,
    title: "Raffles Maldives Meradhoo",
    category: "Ultra Luxury",
  },
  {
    id: 35,
    image: Sonevafushi,
    title: "Soneva Fushi",
    category: "Ultra Luxury",
  },
  {
    id: 36,
    image: SonevaJani,
    title: "Soneva Jani",
    category: "Ultra Luxury",
  },
  {
    id: 37,
    image: Nautilus,
    title: "The Nautilus Maldives",
    category: "Ultra Luxury",
  },
  {
    id: 38,
    image: Waldorf,
    title: "Waldorf Astoria Maldives Ithaafushi",
    category: "Ultra Luxury",
  },
];
