import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/gallefortt.jpg";
import two from "../assets/images/slhotels/leopardsafari.jpg";
import three from "../assets/images/slhotels/camellia.jpg";
import four from "../assets/images/slhotels/stonehouse.jpg";
import five from "../assets/images/slhotels/ulagalla.jpg";
import six from "../assets/images/slhotels/residenceuga.jpg";

const SignatureCollection_Bond = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="Galle Fort Hotel "
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="Leopard Safaris Yala (AITC)"
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Signature "
        hotel="Camellia Hills (AI) "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Signature "
        hotel="Stone House "
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Signature "
        hotel="Ulagalla by Uga "
      />
      <HotelSL
        image={six}
        color="#d5b23f"
        catName="Signature "
        hotel="Residence by Uga  "
      />
    </div>
  );
};

export default SignatureCollection_Bond;
