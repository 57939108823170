import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Rushd13_slide from "./Rushd13_slide";

const Tour_RushDay13 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Rushd13_slide />
      </div>
      <div className="line">
        <h3>Day 13 : </h3>
        <h2>South Coast Beach -CMB Airport [02:30]</h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_RushDay13;
