import React from "react";
import styled from "styled-components";
import Tour_DimensionDay1 from "./Dimensiond1";
import Tour_DimensionDay10 from "./Dimensiond10";
import Tour_DimensionDay11 from "./Dimensiond11";
import Tour_DimensionDay12 from "./Dimensiond12";
import Tour_DimensionDay13 from "./Dimensiond13";
import Tour_DimensionDay14 from "./Dimensiond14";
import Tour_DimensionDay2 from "./Dimensiond2";
import Tour_DimensionDay3 from "./Dimensiond3";
import Tour_DimensionDay4 from "./Dimensiond4";
import Tour_DimensionDay5 from "./Dimensiond5";
import Tour_DimensionDay6 from "./Dimensiond6";
import Tour_DimensionDay7 from "./Dimensiond7";
import Tour_DimensionDay8 from "./Dimensiond8";
import Tour_DimensionDay9 from "./Dimensiond9";
import Slider from "react-slick";
import Destinations from "./Destinations";
import Destinations1 from "./Destionations1";
import "../assets/css/Days_DestinationsMobile.css";
import Destinations2 from "./Destinations2";
import Destinations3 from "./Destinations3";
import Destinations4 from "./Destinations4";
import Destinations5 from "./Destinations5";
import Destinations6 from "./Destinations6";
import Destinations7 from "./Destinations7";
import Destinations8 from "./Destinations8";
import Destinations9 from "./Destinations9";
import Destinations10 from "./Destinations10";
import Destinations11 from "./Destinations11";
import Destinations12 from "./Destinations12";
import Destinations13 from "./Destinations13";
import Destinations14 from "./Destinations14";

const Container = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    z-index: 1;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    border-radius: 10px;
    height: max-content;
    max-height: 70vh !important;
    width: 300px;
    margin-bottom: 20px;
  }
`;

const Slides = styled.div`
  @media screen and (max-width: 768px) {
    flex: 0.5;
    max-width: 300px !important;
    width: 300px !important;
  }
`;

const Days_DestinationsMobile = () => {
  return (
    <div className="superDesContainerMobile">
      <Slider
        className="mainMobile_Destinations"
        arrows={false}
        autoplay={true}
        autoplaySpeed={2000}
        swipeToSlide={true}
        slidesToScroll={1}
      >
        <div className="slide">
          <Destinations1 />
        </div>
        <div className="slide">
          <Destinations2 />
        </div>
        <div className="slide">
          <Destinations3 />
        </div>
        <div className="slide">
          <Destinations4 />
        </div>
        <div className="slide">
          <Destinations5 />
        </div>
        <div className="slide">
          <Destinations6 />
        </div>
        <div className="slide">
          <Destinations7 />
        </div>
        <div className="slide">
          <Destinations8 />
        </div>
        <div className="slide">
          <Destinations9 />
        </div>
        <div className="slide">
          <Destinations10 />
        </div>
        <div className="slide">
          <Destinations11 />
        </div>
        <div className="slide">
          <Destinations12 />
        </div>
        <div className="slide">
          <Destinations13 />
        </div>
        <div className="slide">
          <Destinations14 />
        </div>
      </Slider>
    </div>
  );
};

export default Days_DestinationsMobile;
