import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Rushd3_slide from "./Rushd3_slide";

const Tour_RushDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Rushd3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Knuckles Mountain Range</h2>
      </div>
      <p>High Knuckles Trek [09:00]</p>
    </div>
  );
};

export default Tour_RushDay3;
