import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/gallefortt.jpg";
import two from "../assets/images/slhotels/ceylonwild.jpg";
import three from "../assets/images/slhotels/98acres.jpg";
import four from "../assets/images/slhotels/aarunya.jpg";
import five from "../assets/images/slhotels/jetwinguyana.jpg";
import six from "../assets/images/slhotels/wallawwa.jpg";

const SignatureCollection_Essence = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="Galle Fort Hotel "
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="Ceylon Wild Safaris (AITC)"
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Signature "
        hotel="98 Acres Resort & Spa "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Signature "
        hotel="Aarunya Nature Resort & Spa "
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Signature "
        hotel="Jetwing Vil Uyana "
      />
      <HotelSL
        image={six}
        color="#d5b23f"
        catName="Signature "
        hotel="The Wallawwa "
      />
    </div>
  );
};

export default SignatureCollection_Essence;
