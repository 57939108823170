import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import Bungalow from "../assets/images/slhotels/bungalowgalle.jpg";
import ella from "../assets/images/slhotels/ellaflower.jpg";
import ozo from "../assets/images/slhotels/ozo.jpg";
import aliya from "../assets/images/slhotels/aliyaresort.jpg";
import lagoon from "../assets/images/slhotels/jetwinglagoon.jpg";

const StandardCollection_Island = () => {
  return (
    <div className="collection">
      <h3>Standard Collection</h3>
      <HotelSL
        image={Bungalow}
        color="#dec267"
        catName="Standard "
        hotel="The Bungalow Galle Fort"
      />
      <HotelSL
        image={ella}
        color="#dec267"
        catName="Standard "
        hotel="Ella Flower Garden  "
      />
      <HotelSL
        image={ozo}
        color="#dec267"
        catName="Standard "
        hotel="Ozo Kandy "
      />
      <HotelSL
        image={aliya}
        color="#dec267"
        catName="Standard "
        hotel="Aliya Resort & Spa"
      />
      <HotelSL
        image={lagoon}
        color="#dec267"
        catName="Standard "
        hotel="Jetwing Lagoon "
      />
    </div>
  );
};

export default StandardCollection_Island;
