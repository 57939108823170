import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Wildlifed8_slide from "./Wildlifed8_slide";

const Tour_WildlifeDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Wildlifed8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Gal Oya National Park - Polonnaruwa [02:30] - Sigiriya [01:30]</h2>
      </div>
      <p>Cycle through the Ancient Kingdom of Polonnaruwa-UNESCO [02:30]</p>
    </div>
  );
};

export default Tour_WildlifeDay8;
