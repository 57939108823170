import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Rushd11_slide from "./Rushd11_slide";

const Tour_RushDay11 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Rushd11_slide />
      </div>
      <div className="line">
        <h3>Day 11 : </h3>
        <h2>Sinharaja Forest Reserve -South Coast Beach [03:30]</h2>
      </div>
      <p>Walk inside the ramparts of Galle Fort -UNESCO [01:00]</p>
    </div>
  );
};

export default Tour_RushDay11;
