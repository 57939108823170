import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond5_slide from "./Dimensiond5_slide";
const Tour_DimensionDay5 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond5_slide />
      </div>
      <div className="line">
        <h3>Day 05 : </h3>
        <h2>Kandy </h2>
      </div>
      <p>Leisure </p>
    </div>
  );
};

export default Tour_DimensionDay5;
