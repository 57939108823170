import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";
import cape from "../assets/images/slhotels/capeweligama.jpg";
import jungle from "../assets/images/slhotels/junglebeach.jpg";

const LuxuryCollectionBeach_History = () => {
  return (
    <div className="collection">
      <h3>Luxury Collection</h3>
      <HotelSL
        image={cape}
        color="#d4af38"
        catName="Luxury "
        hotel="Cape Weligama "
      />
      <HotelSL
        image={jungle}
        color="#d4af38"
        catName="Luxury "
        hotel=" Jungle Beach by Uga"
      />
    </div>
  );
};

export default LuxuryCollectionBeach_History;
