import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_PostcardDay4 from "./TourSlides_PostcardDay4";

const Tour_PostcardDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_PostcardDay4 />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Sigiriya - Kandy [03:30]</h2>
      </div>
      <p>Visit the Temple of the Sacred Tooth Relic -UNESCO [01:30]</p>
      <p>Visit the Peradeniya Royal Botanical Gardens [01:00]</p>
    </div>
  );
};

export default Tour_PostcardDay4;
