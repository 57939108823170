import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond7_slide from "./Immersiond7_slide";

const Tour_immersionDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Ella- Nuwara Eliya [03:00] - Kandy [03:00]</h2>
      </div>
      <p>Scenic train journey from Ella to Nuwara Eliya [03:00]</p>
      <p>Visit a tea factory in the tea country [00:40]</p>
      <p>Visit the Peradeniya Royal Botanical Gardens [01:00]</p>
    </div>
  );
};

export default Tour_immersionDay7;
