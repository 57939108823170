import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Wildlifed9_slide from "./Wildlifed9_slide";

const Tour_WildlifeDay9 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Wildlifed9_slide />
      </div>
      <div className="line">
        <h3>Day 09 : </h3>
        <h2>Sigiriya - Wilpattu National Park [02:30]</h2>
      </div>
      <p>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</p>
    </div>
  );
};

export default Tour_WildlifeDay9;
