import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond3_slide from "./Dimensiond3_slide";

const Tour_DimensionDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Anuradhapura </h2>
      </div>
      <p>Visit the Ancient Kingdom of Anuradhapura-UNESCO [02:00]</p>
    </div>
  );
};

export default Tour_DimensionDay3;
