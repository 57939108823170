import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import printers from "../assets/images/slhotels/fortprinters.jpg";
import ekho from "../assets/images/slhotels/ekho.jpg";
import radh from "../assets/images/slhotels/radh.jpg";
import lodge from "../assets/images/slhotels/lakelodge.jpg";
import beach from "../assets/images/slhotels/beachboutique.jpg";

const SuperiorCollection_Island = () => {
  return (
    <div className="collection">
      <h3>Superior Collection</h3>
      <HotelSL
        image={printers}
        color="#ddbf61"
        catName="Superior "
        hotel="The Fort Printers"
      />
      <HotelSL
        image={ekho}
        color="#ddbf61"
        catName="Superior "
        hotel="EKHO Ella "
      />
      <HotelSL
        image={radh}
        color="#ddbf61"
        catName="Superior "
        hotel="The Radh Hotel "
      />
      <HotelSL
        image={lodge}
        color="#ddbf61"
        catName="Superior "
        hotel="Lake Lodge Kandalama "
      />
      <HotelSL
        image={beach}
        color="#ddbf61"
        catName="Superior "
        hotel="The Beach Boutique"
      />
    </div>
  );
};

export default SuperiorCollection_Island;
