import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd5_slide from "./Serenityd5_slide";

const Tour_SerenityDay5 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd5_slide />
      </div>
      <div className="line">
        <h3>Day 05 : </h3>
        <h2>Sigiriya - Kandy [03:30]</h2>
      </div>
      <p>Fly above the Cultural Triangle in a hot air balloon [01:00]</p>
      <p>Spa Treatment [00:45]</p>
    </div>
  );
};

export default Tour_SerenityDay5;
