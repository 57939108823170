import { Translate } from "@material-ui/icons";
import React, { Component } from "react";
import Slider from "react-slick";
import "../assets/css/Days_History.css"
import Tour_HistoryDay1 from "./Tour_HistoryDay1";
import Tour_HistoryDay2 from "./Tour_HistoryDay2";
import Tour_HistoryDay3 from "./Tour_HistoryDay3";
import Tour_HistoryDay4 from "./Tour_HistoryDay4";
import Tour_HistoryDay5 from "./Tour_HistoryDay5";
import Tour_HistoryDay6 from "./Tour_HistoryDay6";
import Tour_HistoryDay7 from "./Tour_HistoryDay7";
import Tour_HistoryDay8 from "./Tour_HistoryDay8";
import Tour_TravelDay1 from "./Traveld1";
import Tour_TravelDay2 from "./Traveld2";
import Tour_TravelDay3 from "./Traveld3";
import Tour_TravelDay4 from "./Traveld4";
import Tour_TravelDay5 from "./Traveld5";
import Tour_TravelDay6 from "./Traveld6";
import Tour_TravelDay7 from "./Traveld7";
import Tour_TravelDay8 from "./Traveld8";

export default class Days_History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      bdcolor: false,
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
    };
  }

  changeColord1 = (e) => {
    this.setState({
      d1bdcolor: "#f1c40f",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #f1c40f",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
    })
  }
  changeColord2 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#f1c40f",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #f1c40f",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
    })
  }

  changeColord3 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#f1c40f",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #f1c40f",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
    })
  }

  changeColord4 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#f1c40f",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #f1c40f",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
    })
  }

  changeColord5 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#f1c40f",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #f1c40f",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
    })
  }

  changeColord6 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#f1c40f",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #f1c40f",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
    })
  }

  changeColord7 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#f1c40f",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #f1c40f",
      d8bxshadow: "0 0 4px #008080",
    })
  }

  changeColord8 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#f1c40f",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #f1c40f",
    })
  }

    

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      d1bdcolor: "#f1c40f",
      d1bxshadow: "0 0 4px #f1c40f"

    });

  }

  render() {
      const styles = {
          backgroundColor: "blue",
      }

    return (
      <div className="superContainer_History">
    
        <Slider
            
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={8}
          swipeToSlide={true}
          focusOnSelect={true}
          vertical={true}
          centerMode={true}
          className="navSlider"
        >
        
        <div className="box">
          <div onClick={this.changeColord1} className="navxx" style={{ borderColor: `${this.state.d1bdcolor}`, boxShadow: `${this.state.d1bxshadow}` }}  >
              <h3 tabIndex="1"  className="dayNo" >
                  Day 01
              </h3> 
              <p className="dayTour">
              CMB Airport- Sigiriya [03:30]
              </p>
          </div>
        </div>
        <div className="box">
          <div onClick={this.changeColord2} className="navxx"  style={{ borderColor: `${this.state.d2bdcolor}`, boxShadow: `${this.state.d2bxshadow}` }} >
              <h3 tabIndex="2" className="dayNo">
                  Day 02
              </h3>
              <p className="dayTour">
              Sigiriya - Anuradhapura  [01:30] - Sigiriya  
              </p>
          </div>
        </div>
        <div className="box">
          <div onClick={this.changeColord3} className="navxx" style={{ borderColor: `${this.state.d3bdcolor}`, boxShadow: `${this.state.d3bxshadow}` }} >
              <h3 className="dayNo">
                  Day 03
              </h3>
              <p className="dayTour">
              Sigiriya - Polonnaruwa  [01:30] - Sigiriya
              </p>
          </div>
        </div>
        <div className="box">
          <div onClick={this.changeColord4} className="navxx" style={{ borderColor: `${this.state.d4bdcolor}`, boxShadow: `${this.state.d4bxshadow}` }} >
              <h3 className="dayNo">
                  Day 04
              </h3>
              <p className="dayTour">
              Sigiriya - Kandy  [03:30]
              </p>
          </div>
        </div>
        <div className="box">
          <div onClick={this.changeColord5} className="navxx" style={{ borderColor: `${this.state.d5bdcolor}`, boxShadow: `${this.state.d5bxshadow}` }} >
              <h3 className="dayNo">
                  Day 05
              </h3>
              <p className="dayTour">
              Kandy 
              </p>
          </div>
        </div>
        <div className="box">
          <div onClick={this.changeColord6} className="navxx" style={{ borderColor: `${this.state.d6bdcolor}`, boxShadow: `${this.state.d6bxshadow}` }} >
              <h3 className="dayNo">
                  Day 06
              </h3>
              <p className="dayTour">
              Kandy - Colombo  [03:00] - Galle  [02:00]
              </p>
          </div>
        </div>
        <div className="box">
          <div onClick={this.changeColord7} className="navxx" style={{ borderColor: `${this.state.d7bdcolor}`, boxShadow: `${this.state.d7bxshadow}` }} >
              <h3 className="dayNo">
                  Day 07
              </h3>
              <p className="dayTour">
              Galle 
              </p>
          </div>
        </div>
        <div className="box">
          <div onClick={this.changeColord8} className="navxx" style={{ borderColor: `${this.state.d8bdcolor}`, boxShadow: `${this.state.d8bxshadow}` }} >
              <h3 className="dayNo">
                  Day 08
              </h3>
              <p className="dayTour">
              Galle - CMB Airport  [02:30]
              </p>
          </div>
        </div>
          
        </Slider>
        
        <Slider
        className="main"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          arrows={false}
          autoplay={false}
          swipeToSlide={false}
          slidesToScroll={0}
        >
         <div className="slide">
             <Tour_HistoryDay1 />
         </div>
         <div className="slide">
             <Tour_HistoryDay2 />
         </div>
         <div className="slide">
             <Tour_HistoryDay3 />
         </div>
         <div className="slide">
             <Tour_HistoryDay4 />
         </div>
         <div className="slide">
             <Tour_HistoryDay5 />
         </div>
         <div className="slide">
             <Tour_HistoryDay6 />
         </div>
         <div className="slide">
             <Tour_HistoryDay7 />
         </div>
         <div className="slide">
             <Tour_HistoryDay8 />
         </div>
         
        </Slider>

        <Slider
        className="mainMobile"
          arrows={false}
          autoplay={true}
          autoplaySpeed={2000}
          swipeToSlide={true}
          slidesToScroll={1}
        >
         <div className="slide">
             <Tour_HistoryDay1 />
         </div>
         <div className="slide">
             <Tour_HistoryDay2 />
         </div>
         <div className="slide">
             <Tour_HistoryDay3 />
         </div>
         <div className="slide">
             <Tour_HistoryDay4 />
         </div>
         <div className="slide">
             <Tour_HistoryDay5 />
         </div>
         <div className="slide">
             <Tour_HistoryDay6 />
         </div>
         <div className="slide">
             <Tour_HistoryDay7 />
         </div>
         <div className="slide">
             <Tour_HistoryDay8 />
         </div>
         
        </Slider>
      </div>
    );
  }
}
