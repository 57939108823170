import React from "react";
import "../assets/css/TeamSection.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import person from "../assets/images/person.jpg";
import ann from "../assets/images/team/anndias.png";
import david from "../assets/images/team/davidgomes.png";
import dilruni from "../assets/images/team/dilruni.png";
import girish from "../assets/images/team/girish.png";
import irene from "../assets/images/team/irene.png";
import kamishka from "../assets/images/team/kamishka.png";
import nishadi from "../assets/images/team/nishadinew.png";
import thilini from "../assets/images/team/thilini.png";
import Slider from "react-slick";

const TeamSection = () => {
  var settings = {
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="team">
      <div className="heading">
        <h1>
          Our <span>Champions</span>
        </h1>
      </div>
      {/* <div className="containerModule">
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={nishadi} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Nishadi De Silva
                <br />
                <span>Destination Manager - Sri Lanka</span>
              </h3>
              <h3>"Wanderlust"</h3>
              <p>
                Consumed by her love for travel, Nishadi has been to every
                corner of Sri Lanka. Now she shares the same passion with
                travelers from around the world by allowing them to experience
                this glorious little island full of nature, wildlife, culture,
                and smiling faces.
              </p>
            </div>
          </div>
          
        </div>
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={thilini} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Thilini Mahanama
                <br />
                <span>
                  Product Development Manager - Signature Collection &
                  Experiences{" "}
                </span>
              </h3>
              <h3>"Foodie"</h3>
              <p>
                Thilini Mahanama Product Development Manager - Signature
                Collection & Experiences “Foodie” Happiness comes in many forms
                and as for Thilini, it comes through her love for food. Just
                like that, she adds the ingredients of happiness to your
                holidays by curating authentic & flavorful experiences.
              </p>
            </div>
          </div>
          
        
        </div>
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={david} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                David Gomes
                <br />
                <span>Destination Manager – Maldives</span>
              </h3>
              <h3>"Reveler"</h3>
              <p>
                For over 07 years, David’s appetite for Parties & Celebrations
                has taken him to various Islands in the Maldives. This has given
                him the opportunity to experience the essence of these island
                resorts through their visitors, making him the perfect travel
                counsellor.{" "}
              </p>
            </div>
          </div>
          
        </div>
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={ann} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Ann Dias
                <br />
                <span>Managing Director - Sri Lanka</span>
              </h3>
              <h3>"Tree-Hugger"</h3>
              <p>
                Since inception, our most prestigious partner has been none
                other than Mother Nature. Ann’s undying love for nature has
                inspired all of us to become a committed organization to
                responsible travel. Now, we proudly share her vision and journey
                with our beloved clients.{" "}
              </p>
            </div>
          </div>
          
        </div>
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={girish} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Girish Menon
                <br />
                <span>Chief Operations Officer</span>
              </h3>
              <h3>"Intrepid"</h3>
              <p>
                Girish, aka “The fearless”, is known for his adventurous spirit
                and unique ideas. His approach in maintaining a revolutionized
                operation has guided our products and services to be in par with
                global standards.
              </p>
            </div>
          </div>
          
        </div>
        
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={kamishka} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Kamishka Morais
                <br />
                <span>Manager- Logistics & Customer Care </span>
              </h3>
              <h3>"Entertainer"</h3>
              <p>
                Entertainment is Kamishka’s middle name. His passion for music
                has given him the ability to engage with different personalities
                and to effortlessly create the most comfortable environment for
                our esteemed clients.
              </p>
            </div>
          </div>
          
        </div>
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={dilruni} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Dilruni Perera
                <br />
                <span>Head or Marketing </span>
              </h3>
              <h3>"Magician"</h3>
              <p>
                “Expect the unexpected” is Dilnuri’s motto. She has more than
                one trick up her sleeves to astonish our clients. Be ready to be
                baffled by her creativity and the most impressive offers that
                she pulls out of her magic hat.
              </p>
            </div>
          </div>
          
        </div>
        
      </div> */}
      <Slider
        {...settings}
        className="containerModuleSlider"
        rows={1}
        swipeToSlide={true}
        slidesToShow={4}
        slidesToScroll={1}
        autoplay={true}
        dots={false}
        infinite={true}
        autoplaySpeed={2000}
        pauseOnHover={true}
      >
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={nishadi} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Nishadi De Silva
                <br />
                <span>Destination Manager</span>
              </h3>
              <h3>"Wanderlust"</h3>
              <p>
                Consumed by her love for travel, Nishadi has been to every
                corner of Sri Lanka. Now she shares the same passion with
                travelers from around the world by allowing them to experience
                this glorious little island full of nature, wildlife, culture,
                and smiling faces.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={david} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                David Gomes
                <br />
                <span>Executive Director</span>
              </h3>
              <h3>"Reveler"</h3>
              <p>
                For over 07 years, David’s appetite for Parties &amp;
                Celebrations has taken him to various parts in the Maldives
                &amp; Sri Lanka. This has given him the opportunity to
                experience the essence of these islands through their visitors,
                making him the perfect travel counsellor.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={ann} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Ann Dias
                <br />
                <span>Managing Director</span>
              </h3>
              <h3>"Tree-Hugger"</h3>
              <p>
                Since inception, our most prestigious partner has been none
                other than Mother Nature. Ann’s undying love for nature has
                inspired all of us to become a committed organization to
                responsible travel. Now, we proudly share her vision and journey
                with our beloved clients.{" "}
              </p>
            </div>
          </div>
          
        </div> */}
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={girish} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Girish Menon
                <br />
                <span>Chief Executive Officer</span>
              </h3>
              <h3>"Intrepid"</h3>
              <p>
                Girish, aka “The fearless”, is known for his adventurous spirit
                and unique ideas. His approach in maintaining a revolutionized
                operation has guided our products and services to be in par with
                global standards.
              </p>
            </div>
          </div>
          {/* <ul className="sci">
            <li>
              <a href="#">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a href="#">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a href="#">
                <InstagramIcon />
              </a>
            </li>
          </ul> */}
        </div>
        {/* <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={irene} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Irene Santiyago
                <br />
                <span>Representative – Spain, Portugal & Italy </span>
              </h3>
              <h3>"Entertainer"</h3>
              <p>
                Consumed by her love for travel, Nishadi has been to every
                corner of Sri Lanka. Now she shares the same passion with
                travelers from around the world by allowing them to experience
                this glorious little island full of nature, wildlife, culture,
                and smiling faces.
              </p>
            </div>
          </div>
          <ul className="sci">
            <li>
              <a href="#">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a href="#">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a href="#">
                <InstagramIcon />
              </a>
            </li>
          </ul>
        </div> */}
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={kamishka} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Kamishka Morais
                <br />
                <span>Manager - Logistics & Customer Care </span>
              </h3>
              <h3>"Entertainer"</h3>
              <p>
                Entertainment is Kamishka’s middle name. His passion for music
                has given him the ability to engage with different personalities
                and to effortlessly create the most comfortable environment for
                our esteemed clients.
              </p>
            </div>
          </div>
          {/* <ul className="sci">
            <li>
              <a href="#">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a href="#">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a href="#">
                <InstagramIcon />
              </a>
            </li>
          </ul> */}
        </div>
        <div className="card">
          <div className="content">
            <div className="imgBx">
              <img src={dilruni} alt="" />
            </div>
            <div className="contentBx">
              <h3>
                Dilruni Perera
                <br />
                <span>Head of Marketing </span>
              </h3>
              <h3>"Magician"</h3>
              <p>
                “Expect the unexpected” is Dilnuri’s motto. She has more than
                one trick up her sleeves to astonish our clients. Be ready to be
                baffled by her creativity and the most impressive offers that
                she pulls out of her magic hat.
              </p>
            </div>
          </div>
          {/* <ul className="sci">
            <li>
              <a href="#">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a href="#">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a href="#">
                <InstagramIcon />
              </a>
            </li>
          </ul> */}
        </div>
      </Slider>
    </section>
  );
};

export default TeamSection;
