import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import blue from "../assets/images/slhotels/bluemagpie.jpg";
import wild from "../assets/images/slhotels/cinnamonwild.jpg";
import flower from "../assets/images/slhotels/ellaflower.jpg";
import citadel from "../assets/images/slhotels/cinnamoncitadel.jpg";
import amaya from "../assets/images/slhotels/amayalake.jpg";
import palm from "../assets/images/slhotels/palmgarden.jpg";
import goldi from "../assets/images/slhotels/goldisands.jpg";

const StandardCollection_Immersion = () => {
  return (
    <div className="collection">
      <h3>Standard Collection</h3>
      <HotelSL
        image={blue}
        color="#dec267"
        catName="Standard "
        hotel="Blue Magpie Lodge "
      />
      <HotelSL
        image={wild}
        color="#dec267"
        catName="Standard "
        hotel="Cinnamon Wild Yala "
      />
      <HotelSL
        image={flower}
        color="#dec267"
        catName="Standard "
        hotel="Ella Flower Garden  "
      />
      <HotelSL
        image={citadel}
        color="#dec267"
        catName="Standard "
        hotel="Cinnamon Citadel  "
      />
      <HotelSL
        image={amaya}
        color="#dec267"
        catName="Standard "
        hotel="Amaya Lake Resort and Spa   "
      />
      <HotelSL
        image={palm}
        color="#dec267"
        catName="Standard "
        hotel="Palm Garden Village Hotel "
      />
      <HotelSL
        image={goldi}
        color="#dec267"
        catName="Standard "
        hotel="Goldi Sands Hotel "
      />
    </div>
  );
};

export default StandardCollection_Immersion;
