import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd11_slide from "./Serenityd11_slide";

const Tour_SerenityDay11 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd11_slide />
      </div>
      <div className="line">
        <h3>Day 11 : </h3>
        <h2>Galle </h2>
      </div>
      <p>Leisure </p>
      <p>Sunset catamaran cruise in Mirissa [03:30]</p>
    </div>
  );
};

export default Tour_SerenityDay11;
