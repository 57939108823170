import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Getawayd8_slide from "./Getawayd8_slide";

const Tour_GetawayDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Getawayd8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Ella - South Coast Beach [04:00]</h2>
      </div>
      <p>Elephant Transit Home [01:00]</p>
    </div>
  );
};

export default Tour_GetawayDay8;
