import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Traveld8_slide from "./Traveld8_slide";

const Tour_TravelDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Traveld8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Galle </h2>
      </div>
      <p>Cook a meal with a local family [04:00]</p>
      <p>Walk inside the ramparts of Galle Fort -UNESCO [01:00]</p>
    </div>
  );
};

export default Tour_TravelDay8;
