import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Ignited3_slide from "./Ignited3_slide";

const Tour_IgniteDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Galle -Udawalawe [02:00]- Haputale [03:00]</h2>
      </div>
      <p>Elephant Transit Home [01:00]</p>
    </div>
  );
};

export default Tour_IgniteDay3;
