import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/colombocourt.jpg";
import two from "../assets/images/slhotels/ehalagala.jpg";
import three from "../assets/images/slhotels/radh.jpg";
import four from "../assets/images/slhotels/ekho.jpg";
import five from "../assets/images/slhotels/flameback.jpg";
import six from "../assets/images/slhotels/fortprinters.jpg";

const SuperiorCollection_Postcard = () => {
  return (
    <div className="collection">
      <h3>Superior Collection</h3>
      <HotelSL
        image={one}
        color="#ddbf61"
        catName="Superior "
        hotel="Colombo Court Hotel & Spa"
      />
      <HotelSL
        image={two}
        color="#ddbf61"
        catName="Superior "
        hotel="Ehalagala Lake Resort"
      />
      <HotelSL
        image={three}
        color="#ddbf61"
        catName="Superior "
        hotel="The Radh Hotel"
      />
      <HotelSL
        image={four}
        color="#ddbf61"
        catName="Superior "
        hotel="EKHO Ella"
      />
      <HotelSL
        image={five}
        color="#ddbf61"
        catName="Superior "
        hotel="Flameback Eco Lodge (TC)"
      />
      <HotelSL
        image={six}
        color="#ddbf61"
        catName="Superior "
        hotel="The Fort Printers"
      />
    </div>
  );
};

export default SuperiorCollection_Postcard;
