import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Bondd8_slide from "./Bondd8_slide";

const Tour_BondDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Bondd8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Kandy - Anuradhapura [04:00] </h2>
      </div>
      <p>Visit the Temple of the Sacred Tooth Relic -UNESCO [01:30]</p>
    </div>
  );
};

export default Tour_BondDay8;
