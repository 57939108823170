import React from "react";

import "../assets/css/Tour_Destinations.css";
import Destinations10_slide from "./Destinations10_slide";

import Destinations1_slide from "./Destinations1_slide";

const Destinations10 = () => {
  return (
    <div className="tour_Destinations">
      <div className="slides">
        <Destinations10_slide />
      </div>
      {/* <div className="line"></div> */}
    </div>
  );
};

export default Destinations10;
