import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Rushd8_slide from "./Rushd8_slide";

const Tour_RushDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Rushd8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Kithulgala - Udawalawe National Park [04:30]</h2>
      </div>
      <p>Elephant Transit Home [01:00]</p>
    </div>
  );
};

export default Tour_RushDay8;
