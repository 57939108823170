import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/waligama.jpg";
import two from "../assets/images/slhotels/trincoblu.jpg";

const StandardCollectionBeach_Postcard = () => {
  return (
    <div className="collection">
      <h3>Standard Collection</h3>
      <HotelSL
        image={one}
        color="#dec267"
        catName="Standard "
        hotel="Weligama Bay Marriott Resort & Spa"
      />
      <HotelSL
        image={two}
        color="#dec267"
        catName="Standard "
        hotel="Trinco Blu by Cinnamon "
      />
    </div>
  );
};

export default StandardCollectionBeach_Postcard;
