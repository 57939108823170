import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Traveld4_slide from "./Traveld4_slide";

const Tour_TravelDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Traveld4_slide />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Sigiriya - Kandy [03:30]</h2>
      </div>
      <p>Visit the Temple of the Sacred Tooth Relic -UNESCO [01:30]</p>
    </div>
  );
};

export default Tour_TravelDay4;
