import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond1_slide from "./Immersiond1_slide";

const Tour_immersionDay1 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond1_slide />
      </div>
      <div className="line">
        <h3>Day 01 : </h3>
        <h2>CMB Airport -Galle [02:30]</h2>
      </div>
      <p>Unwind</p>
    </div>
  );
};

export default Tour_immersionDay1;
