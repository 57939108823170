import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Getawayd7_slide from "./Getawayd7_slide";

const Tour_GetawayDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Getawayd7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Ella </h2>
      </div>
      <p>Little Adam’s Peak Hike [02:30]</p>
      <p>Nine Arch Bridge [00:30]</p>
      <p>Flying Ravana Mega Zipline [01:00]</p>
    </div>
  );
};

export default Tour_GetawayDay7;
