import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sample from "../assets/images/SlickSlider/newimages/history.png";
import "../assets/css/TourSlides.css";

import one from "../assets/images/slslides/TukKandy.png";
import two from "../assets/images/slslides/VisitTempleOfTooth.png";


const Essenced8_slide = () => {

    var settings = {
        dots: false,
        infinite: true,
    
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
      };
    return (
        <div className="slider">
        <Slider {...settings}  >
            <div className="tile">
                <img alt="" src={one} />
                <h2>Tuk Tuk ride through the streets of Kandy  [00:30]</h2>
            </div>
            <div className="tile">
                <img alt="" src={two} />
                <h2>Visit the Temple of the Sacred Tooth Relic -UNESCO   [01:30]</h2>
            </div>
            

            
        </Slider>
        </div>
    )
}

export default Essenced8_slide
