import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Essenced2_slide from "./Essenced2_slide";

const Tour_EssenceDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Essenced2_slide />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Galle - Yala National Park [02:30]</h2>
      </div>
      <p>Walk inside the ramparts of Galle Fort -UNESCO [01:00]</p>
      <p>Afternoon Jeep Safari in Yala National Park [03:00]</p>
    </div>
  );
};

export default Tour_EssenceDay2;
