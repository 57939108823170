import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Wildlifed3_slide from "./Wildlifed3_slide";

const Tour_WildlifeDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Wildlifed3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Sinharaja Forest Reserve</h2>
      </div>
      <p>Trek through the Sinharaja Rainforest -UNESCO [03:00]</p>
    </div>
  );
};

export default Tour_WildlifeDay3;
