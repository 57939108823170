import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Rushd9_slide from "./Rushd9_slide";

const Tour_RushDay9 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Rushd9_slide />
      </div>
      <div className="line">
        <h3>Day 09 : </h3>
        <h2>Udawalawe National Park- Sinharaja Forest Reserve [04:15]</h2>
      </div>
      <p>Morning Jeep Safari in Udawalawe National Park [03:00]</p>
    </div>
  );
};

export default Tour_RushDay9;
