import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Traveld6_slide from "./Traveld6_slide";

const Tour_TravelDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Traveld6_slide />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Ella </h2>
      </div>
      <p>Little Adam’s Peak Hike [02:30]</p>
      <p>Nine Arch Bridge [00:30]</p>
    </div>
  );
};

export default Tour_TravelDay6;
