import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sample from "../assets/images/SlickSlider/newimages/history.png";
import "../assets/css/TourSlides.css";

import one from "../assets/images/slslides/WilpattuJeepSafari.png";


const Immersiond11_slide = () => {

    var settings = {
        dots: false,
        infinite: true,
    
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
      };
    return (
        <div className="slider">
        <Slider {...settings}  >
            <div className="tile">
                <img alt="" src={one} />
                <h2>Morning Jeep Safari in Wilpattu National Park [03:00]</h2>
            </div>
           
           
            
            
            
            
        </Slider>
        </div>
    )
}

export default Immersiond11_slide
