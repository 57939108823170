import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_IslandDay4 from "./TourSlides_IslandDay4";

const Tour_IslandDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_IslandDay4 />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Ella- Nuwara Eliya [03:00] - Kandy [03:00]</h2>
      </div>
      <p>Scenic train journey from Ella to Nuwara Eliya [03:00]</p>
      <p>Visit a tea factory in the tea country [00:40]</p>
      <p>Visit the Peradeniya Royal Botanical Gardens [01:00]</p>
    </div>
  );
};

export default Tour_IslandDay4;
