import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Getawayd5_slide from "./Getawayd5_slide";

const Tour_GetawayDay5 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Getawayd5_slide />
      </div>
      <div className="line">
        <h3>Day 05 : </h3>
        <h2>Kandy </h2>
      </div>
      <p>Tuk Tuk ride through the streets of Kandy [00:30]</p>
      <p>Visit the Temple of the Sacred Tooth Relic -UNESCO [01:30]</p>
      <p>Visit the Peradeniya Royal Botanical Gardens [01:00]</p>
    </div>
  );
};

export default Tour_GetawayDay5;
