import React from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { menuData } from "../assets/Data/MenuData";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/images/logonew.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";

const DropdownContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(10px);
  display: grid;
  align-items: center;
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const CloseIcon = styled(FaTimes)`
  color: white;
`;

const DropdownWrapper = styled.div``;

const DropdownMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 80px);
  text-align: center;
  margin-bottom: 4rem;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(4, 60px);
  }
`;

const DropdownLink = styled(HashLink)`
  display: flex;
  color: #008080;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "century-gothic", sans-serif;
  text-shadow: 0 0 25px rgba(0, 0, 0, 0.8);
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-bottom: 23px;
  &:hover {
    color: #000d1a;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 150px;
  margin: auto;
  margin-top: -50px;
`;

const DropIcons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Dropdown = ({ toggle, isOpen }) => {
  return (
    <DropdownContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropdownWrapper>
        <DropdownMenu>
          <Image src={logo} alt="" />
          <DropdownLink to="#home" smooth={true} offset={-70} duration={500}>
            HOME
          </DropdownLink>
          <DropdownLink
            to="#srilanka"
            smooth={true}
            offset={-70}
            duration={500}
          >
            SRI LANKA
          </DropdownLink>
          <DropdownLink
            to="#maldives"
            smooth={true}
            offset={-70}
            duration={500}
          >
            MALDIVES
          </DropdownLink>
          <DropdownLink to="/partners">PARTNER WITH US</DropdownLink>
          <DropdownLink to="#reachus" smooth={true} offset={-70} duration={500}>
            REACH US
          </DropdownLink>
          <DropIcons>
            <a
              style={{
                textDecoration: "none",
                margin: "10px",
                color: "#008080",
              }}
              href="https://www.facebook.com/silantravels/?fref=ts"
            >
              <FacebookIcon style={{ fontSize: "40px" }} />
            </a>
            <a
              style={{
                textDecoration: "none",
                margin: "10px",
                color: "#008080",
              }}
              href="https://www.instagram.com/silantravels"
            >
              <InstagramIcon style={{ fontSize: "40px" }} />
            </a>
            <a
              style={{
                textDecoration: "none",
                margin: "10px",
                color: "#008080",
              }}
              href="https://twitter.com/SilanTravels"
            >
              <PinterestIcon style={{ fontSize: "40px" }} />
            </a>
          </DropIcons>
          {/* {menuData.map((item, index) => (
            <DropdownLink to={item.link} key={index}>
              {item.title}
            </DropdownLink>
          ))} */}
        </DropdownMenu>
        {/* <BtnWrap>
          <Button primary="true" round="true" bug="true" to="/contact">
            Contact Us
          </Button>
        </BtnWrap> */}
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default Dropdown;
