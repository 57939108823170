import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/paradisebeach.jpg";
import two from "../assets/images/slhotels/amayabeach.jpg";

const StandardCollectionBeach_Immersion = () => {
  return (
    <div className="collection">
      <h3>Standard Collection</h3>
      <HotelSL
        image={one}
        color="#dec267"
        catName="Standard "
        hotel="Paradise Beach Club"
      />
      <HotelSL
        image={two}
        color="#dec267"
        catName="Standard "
        hotel="Amaya Beach Pasikudah "
      />
    </div>
  );
};

export default StandardCollectionBeach_Immersion;
