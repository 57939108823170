import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Budsd12_slide from "./Budsd12_slide";

const Tour_BudsDay12 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Budsd12_slide />
      </div>
      <div className="line">
        <h3>Day 12 : </h3>
        <h2>Colombo - CMB Airport [02:30]</h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_BudsDay12;
