import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";
import amaya from "../assets/images/slhotels/amayalake.jpg";
import cinnamon from "../assets/images/slhotels/cinnamoncitadel.jpg";
import bungalow from "../assets/images/slhotels/bungalowgalle.jpg";

const StandardCollection_History = () => {
  return (
    <div className="collection">
      <h3>Standard Collection</h3>
      <HotelSL
        image={amaya}
        color="#dec267"
        catName="Standard "
        hotel="Amaya Lake Resort & Spa "
      />
      <HotelSL
        image={cinnamon}
        color="#dec267"
        catName="Standard "
        hotel="Cinnamon Citadel  "
      />
      <HotelSL
        image={bungalow}
        color="#dec267"
        catName="Standard "
        hotel="The Bungalow Galle Fort "
      />
    </div>
  );
};

export default StandardCollection_History;
