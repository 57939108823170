import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";
import waligama from "../assets/images/slhotels/waligama.jpg";
import trinco from "../assets/images/slhotels/trincoblu.jpg";

const StandardCollectionBeach_History = () => {
  return (
    <div className="collection">
      <h3>Standard Collection</h3>
      <HotelSL
        image={waligama}
        color="#dec267"
        catName="Standard "
        hotel="Weligama Bay Marriott Resort & Spa"
      />
      <HotelSL
        image={trinco}
        color="#dec267"
        catName="Standard "
        hotel="Trinco Blu by Cinnamon  "
      />
    </div>
  );
};

export default StandardCollectionBeach_History;
