import React from "react";
import Hotel from "./Hotel";
import anantaraimage from "../assets/images/Maldives/anantaraveli.png";
import cocoonimage from "../assets/images/Maldives/coccon.jpg";
import dhigaliimage from "../assets/images/Maldives/dhigali.png";
import hardrockimage from "../assets/images/Maldives/hardrock.png";
import kudafushiimage from "../assets/images/Maldives/kudafushi.png";
import kurumbaimage from "../assets/images/Maldives/kurumba.png";
import saiiimage from "../assets/images/Maldives/saii.png";
import sunsiyamimage from "../assets/images/Maldives/sunsiyam.png";

const Hotels_Deluxe = ({
  CocoonPopup,
  AnantaraPopup,
  DhigaliPopup,
  HardRockPopup,
  KudafushiPopup,
  KurumbaPopup,
  SaiiPopup,
  SunsiyamPopup,
}) => {
  return (
    <div className="hotels">
      <h1
        style={{ fontFamily: `"Cormorant Garamond", serif`, fontSize: "19px" }}
        className="Heading"
      >
        Deluxe Collection
      </h1>
      <div className="HotelsContainer">
        <div className="row">
          <Hotel
            runner={AnantaraPopup}
            image={anantaraimage}
            title="Anantara Veli"
          />
          <Hotel
            runner={CocoonPopup}
            image={cocoonimage}
            title="Cocoon Maldives"
          />

          <Hotel
            runner={DhigaliPopup}
            image={dhigaliimage}
            title="Dhigali Maldives"
          />
          <Hotel
            runner={HardRockPopup}
            image={hardrockimage}
            title="Hard Rock Hotel"
          />
          <Hotel
            runner={KudafushiPopup}
            image={kudafushiimage}
            title="Kudafushi Resort & Spa"
          />
        </div>
        <div className="row">
          <Hotel
            runner={KurumbaPopup}
            image={kurumbaimage}
            title="Kurumba Maldives"
          />
          <Hotel
            runner={SaiiPopup}
            image={saiiimage}
            titlelong="SAii Laggon, Curio Collection by Hilton"
          />
          <Hotel
            runner={SunsiyamPopup}
            image={sunsiyamimage}
            title="Sun Siyam Vilu Reef"
          />
        </div>
      </div>
    </div>
  );
};

export default Hotels_Deluxe;
