import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import galle from "../assets/images/slhotels/gallefortt.jpg";
import acres from "../assets/images/slhotels/98acres.jpg";
import theva from "../assets/images/slhotels/theva.jpg";
import grass from "../assets/images/slhotels/wildgrass.jpg";
import villa from "../assets/images/slhotels/villahundria.jpg";

const DeluxeCollection_Island = () => {
  return (
    <div className="collection">
      <h3>Deluxe Collection</h3>
      <HotelSL
        image={galle}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Galle Fort Hotel  "
      />
      <HotelSL
        image={acres}
        color="#d5b23f"
        catName="Deluxe "
        hotel="98 Acres Resort & Spa  "
      />
      <HotelSL
        image={theva}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Theva Residency "
      />
      <HotelSL
        image={grass}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Wild Grass Nature Resort "
      />
      <HotelSL
        image={villa}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Villa Hundira  "
      />
    </div>
  );
};

export default DeluxeCollection_Island;
