import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Bondd7_slide from "./Bondd7_slide";

const Tour_BondDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Bondd7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Hatton - Kandy [02:00] </h2>
      </div>
      <p>Scenic train journey from Hatton to Kandy [03:00]</p>
      <p>Visit the Peradeniya Royal Botanical Gardens [01:00]</p>
    </div>
  );
};

export default Tour_BondDay7;
