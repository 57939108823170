import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";
import kk from "../assets/images/slhotels/kk.jpg";
import Anantaya from "../assets/images/slhotels/anantayaresort.jpg";

const DeluxeCollectionBeach_History = () => {
  return (
    <div className="collection">
      <h3>Deluxe Collection</h3>
      <HotelSL image={kk} color="#d5b23f" catName="Deluxe " hotel="KK Beach " />
      <HotelSL
        image={Anantaya}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Anantaya Resort and Spa Passikuda"
      />
    </div>
  );
};

export default DeluxeCollectionBeach_History;
