import React from "react";
import styled from "styled-components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import slide1 from "./NewSlides/slide1.png";
import slide2 from "./NewSlides/divF.png";
import slide3 from "./NewSlides/slide3.png";
import slide4 from "./NewSlides/slide4.png";
import slide5 from "./NewSlides/slide5.png";
import slide6 from "./NewSlides/luxF.png";
import slide7 from "./NewSlides/slide7.png";
import slide8 from "./NewSlides/slide8.png";
// JSX
import HeroSlider, {
  Slide,
  Nav,
  OverlayContainer,
  SideNav,
  ButtonsNav,
} from "hero-slider";
import Wrapper from "../UI/Wrapper/Wrapper";
import Title from "../UI/Title/Title";
import Subtitle from "../UI/Subtitle/Subtitle";

// Images
const bogliasco = "https://i.imgur.com/Gu5Cznz.jpg";
const countyClare = "https://i.imgur.com/idjXzVQ.jpg";
const craterRock = "https://i.imgur.com/8DYumaY.jpg";
const giauPass = "https://i.imgur.com/8IuucQZ.jpg";

const SliderWrapper = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
`;

const SmallTitle = styled.p`
  @import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dosis:wght@700&display=swap");

  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  font-family: "century-gothic", sans-serif;
  font-size: 25px;
  /* margin-bottom: 20px; */
  width: 75%;
  margin-top: -80px;
  margin-bottom: -30px;
  /* margin-top: -100px; */

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 79%;
  margin-bottom: 100px;

  @media screen and (max-width: 1366px) {
    margin-bottom: 60px;
  }
`;

const Para = styled.p`
  @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap");

  font-family: "Raleway", sans-serif;
  color: #fff;
  width: max-content;
  font-size: 19px;
  text-shadow: rgba(0, 0, 0, 0.9);
  line-height: 1.6rem;
  padding: 4px;
  border-radius: 5px;
  margin-top: -20px;

  @media screen and (max-width: 1366px) {
    font-size: 15px;
    margin-top: -30px;
  }
`;

const MainTitle = styled.h1`
  /* font-family: "Yeseva One", cursive; */
  /* font-family: "Bebas Neue", cursive; */
  @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

  font-family: "Cormorant Garamond", serif;

  color: #fff;
  font-size: 60px;
  text-transform: uppercase;
  text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.8);
  width: 89%;
  font-weight: 400;
  line-height: 100px;
  text-align: left;

  margin-bottom: 10px;

  span {
    color: #d7be69 !important;
    font-family: "Cormorant Garamond", serif;
    text-transform: uppercase;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.8);
  }

  @media screen and (max-width: 1366px) {
    font-size: 50px;
    margin-bottom: 5px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

    span {
      color: #d0b49f;
      font-family: "Cormorant Garamond", serif;
      font-size: 50px;
      text-transform: uppercase;
      text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.8);
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
    width: 80%;
    line-height: 55px;
  }
`;

const Button = styled.button`
  align-self: flex-start;
  padding: 10px 25px;
  font-family: "Exo 2", sans-serif;
  color: white;
  text-transform: uppercase;
  background: transparent;
  border: 1px solid white;
  border-radius: 40px;
  margin-left: 860px;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 600;
  transition: 0.3s;

  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`;

const BasicSlider = () => {
  return (
    <SliderWrapper id="home">
      <HeroSlider
        slidingAnimation="left_to_right"
        orientation="horizontal"
        initialSlide={1}
        onBeforeChange={(previousSlide, nextSlide) =>
          console.log("onBeforeChange", previousSlide, nextSlide)
        }
        onChange={(nextSlide) => console.log("onChange", nextSlide)}
        onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.33)",
        }}
        settings={{
          slidingDuration: 250,
          isSmartSliding: false,
          slidingDelay: 100,
          shouldAutoplay: true,
          shouldDisplayButtons: false,
          autoplayDuration: 5000,
          height: "100vh",
        }}
      >
        <Slide
          background={{
            backgroundImage: slide1,
            backgroundAttachment: "fixed",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Sri Lanka</SmallTitle> */}
              <ContentWrapper>
                <MainTitle>
                  The Land Of <span>Smiles</span>
                </MainTitle>
                <Para>
                  “The land of smiles with 2500+ years of written history &amp;
                  ancient civilizations.”
                </Para>
              </ContentWrapper>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide2,
            backgroundAttachment: "fixed",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              <ContentWrapper>
                <MainTitle>
                  <span>D</span>iversity
                </MainTitle>
                <Para>
                  “History, archaeology, religion, culture, food, arts &amp;
                  music diverse with multi-cultures, <br /> multi-ethnics &amp;
                  multi-religious people living under one flag.”
                </Para>
              </ContentWrapper>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide3,
            backgroundAttachment: "fixed",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              <ContentWrapper>
                <MainTitle>
                  Geographical <span>Marvel</span>
                </MainTitle>
                <Para>
                  “Sunny Beaches to Lush Green Mountains 2500+ ft. above sea
                  level within just 4-5 hour.”
                </Para>
              </ContentWrapper>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide4,
            backgroundAttachment: "fixed",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              <ContentWrapper>
                <MainTitle>
                  <span>B</span>iodiversity
                </MainTitle>
                <Para>
                  “Biodiversity filled with magnificent whales, Elephants,
                  Leopards, <br /> Sloth Bears, Deers &amp; Birds.”
                </Para>
              </ContentWrapper>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide5,
            backgroundAttachment: "fixed",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              <ContentWrapper>
                <MainTitle>
                  <span>A</span>dventure
                </MainTitle>
                <Para>
                  “Intrepid Adventures with rafting, kite surfing, surfing,
                  hiking, trekking, <br /> canyoning &amp; camping.”
                </Para>
              </ContentWrapper>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide6,
            backgroundAttachment: "fixed",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              <ContentWrapper>
                <MainTitle>
                  <span>L</span>uxury
                </MainTitle>
                <Para>
                  “Enjoy a royal lavishness through a combination of Modern
                  &amp; Colonial luxury <br /> presented with world class Sri
                  Lankan hospitality.”
                </Para>
              </ContentWrapper>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide7,
            backgroundAttachment: "fixed",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              <ContentWrapper>
                <MainTitle>
                  <span>Wellness</span> & Ayurveda
                </MainTitle>
                <Para>
                  “Indigenous ayurveda medicine &amp; wellness heritage dating
                  back to more <br />
                  than 5000 years.”
                </Para>
              </ContentWrapper>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide8,
            backgroundAttachment: "fixed",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              <ContentWrapper>
                <MainTitle>
                  <span>M</span>aldives
                </MainTitle>
                <Para>
                  “Escape to the uninterrupted bespoke views of the white sandy
                  beaches <br /> or the astonishing turquoise lagoons.”
                </Para>
              </ContentWrapper>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <SideNav />
        {/* <ButtonsNav /> */}
        <Nav />
      </HeroSlider>
    </SliderWrapper>
  );
};

export default BasicSlider;
