import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond2_slide from "./Immersiond2_slide";

const Tour_immersionDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond2_slide />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Galle -Sinharaja Forest Reserve [03:30]</h2>
      </div>
      <p>Walk inside the ramparts of Galle Fort -UNESCO [01:00]</p>
    </div>
  );
};

export default Tour_immersionDay2;
