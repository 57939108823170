import React, { useEffect, useState } from 'react';
import "../assets/css/Sample.css";
import travel from "../assets/images/travelnew.jpg";

import Footer from "./Footer";

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Multiselect from "multiselect-react-dropdown";
import { HashLink } from "react-router-hash-link";


import Days_Travel from './Days_Travel';
import CollectionNav_Premium from './CollectionNav_Premium';
import SignatureCollection_Travel from './Signature_Travel';
import PremiumCollection_Travel from './Premium_Travel';
import NavbarSample from './NavbarSample';




const Sample = () => { 

    const beachhotelData = [
        {
            cat: "Entry Level Hotels",
        },
        {
            cat: "Standard Boutique",
        },
        {
            cat: "Deluxe Boutique",
        },
        {
            cat: "Luxury Boutique"
        }

    ]

    const hotelData = [
        {
            cat: "Signature Collection",
        },
        {
            cat: "Premium Signature Collection",
        },
    ]

    useEffect(() => {
        document.title = "Travel like a Local";
        
    }, [])

    
    

    const [hotel, setHotel]  = useState([]);
    const [beach, setBeach]  = useState([]);
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
      }

    return (
        <div className="sriLanka_Package">
            
            {/* navbar */}
            <div className="headtest">
                <div className="leftSide">
                    <img alt="" src={travel} />
                </div>
                <div className="rightSide">
                    <h3>Sri Lanka</h3>
                    <h1>Travel like a Local</h1>
                    
                    <HashLink className="button" smooth={true}
                                offset={-70}
                                duration={500}
                                to="#enquire">Enquire</HashLink>
                </div>
            </div>
            <div className="tourDetails">
                <Days_Travel />
                
            </div>
            <div className="hotels">
                <h1>Accommodation</h1>
                <CollectionNav_Premium targetSignature="/travellikealocal" targetPremium="/travellikealocal/premium" signaturePrice="120" premiumPrice="300"  />
                
                    <Switch>
                        <Route path="/travellikealocal/premium" exact render={() => ( <PremiumCollection_Travel /> )} />
                        <Route path="/travellikealocal"  render={() => ( <SignatureCollection_Travel /> )} />
                    </Switch>
                
            </div>
            <div className="hotels">
                <h1>Beach <span className="special">Extensions</span></h1>
                <CollectionNav_Premium targetSignature="/travellikealocal" targetPremium="/travellikealocal/beach_premium" signaturePrice="120" premiumPrice="300" />

                <Switch>
                    
                    {/* <Route path="/travellikealocal/beach_premium" exact render={() => ( < PremiumCollectionBeach_Essence /> )} />
                    <Route path="/travellikealocal"  render={() => ( < SignatureCollectionBeach_Essence /> )} /> */}
                    
                </Switch>
            </div>

 
            <div id="enquire" className="form">
                <form method="POST" action="https://formsubmit.co/nimeshadls8@gmail.com">
                <h2>Enquire Now</h2>
                    <input type="hidden" name="_subject" value="Sri Lanka Tour Submission" />
                    <input className="field" type="text" name="name" placeholder="Name" />
                    <input type="email" name="email" placeholder="Email" className="field" />
                    <input type="text" name="tour name" value="Travel like a Local"  style={{ display: "none" }} />
                    <div className="seperate">
                        <input className="field" type="text" name="nationality" placeholder="Nationality" />
                        <input className="field" type="number" name="number of adults" placeholder="Adults (Max: 10)" />
                        <input className="field" type="number" name="number of children" placeholder="Children (Max: 10)" />
                    </div>
                    <div className="seperate1">
                        <input className="field" type="date" name="date" placeholder="Date" />
                        <input className="field" type="number" name="nights" placeholder="Nights (Max: 20)"  />
                    </div>
                    <Multiselect style={{ searchBox: { border: "none", backgroundColor: "#f3f3f3", marginBottom: "10px", padding: "10px" }, }} placeholder="Hotel Category" type="text" displayValue="cat" onSelect={(e) => {
                    setHotel(Array.isArray(e) ? e.map((x) => x.cat) : "");
                  }}
                  onRemove={(e) => {
                    setHotel(Array.isArray(e) ? e.map((x) => x.cat) : "");
                  }}
                  
                  options={hotelData}
                  singleSelect={true}
                  avoidHighlightFirstOption={true}
                  />
                  {hotel && (
                  <input
                    name="Hotel category"
                    style={{ display: "none" }}
                    value={JSON.stringify(hotel)}
                  />
                )}
                <div className="seperate2">
                <label>Beach Extension</label>    
                <input checked={checked} onChange={handleChange} className="checker" type="checkbox" name="beach extension" />
                </div>
                {checked ? 
                <Multiselect style={{ searchBox: { border: "none", backgroundColor: "#f3f3f3", marginBottom: "10px", padding: "10px" }, }} placeholder="Hotel Category (Beach)" type="text" displayValue="cat" onSelect={(e) => {
                    setBeach(Array.isArray(e) ? e.map((x) => x.cat) : "");
                  }}
                  onRemove={(e) => {
                    setBeach(Array.isArray(e) ? e.map((x) => x.cat) : "");
                  }}
                  
                  options={beachhotelData}
                  singleSelect={true}
                  avoidHighlightFirstOption={true} /> : "" }
                  {beach && (
                  <input
                    name="Hotel category (Beach)"
                    style={{ display: "none" }}
                    value={JSON.stringify(beach)}
                  />
                )}
                    <textarea className="inputField"
                                type="text"
                                className="field"
                                rows="5"
                                placeholder="Customize Your Tour"
                                name="Customize your tour" />

                    <input className="button" type="submit" />
                </form>
            </div>

            <div className="notes">
                <h2>Notes</h2>
                <h3>ECO - Eco Lodges</h3>
                <h3>TC- Tented Camps</h3>
                <h3>AITC - Al inclusive Tented Camps</h3>
                <p>Hotels are subject to availability.</p>
            <p>Travel durations are approximate and will depend on the traffic &amp; weather conditions.</p>
            <p>Duration for experiences are excluding the driving time to the location.</p>
            <p>The Signature &amp; Premium Signature Collections are not a category of hotels, but a set of selected properties to complement the essence of each tour respectively.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Sample
