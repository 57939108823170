import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/backofbeyond.jpg";
import two from "../assets/images/slhotels/wildglamping.jpg";
import three from "../assets/images/slhotels/riverview.jpg";
import four from "../assets/images/slhotels/borderlands.jpg";
import five from "../assets/images/slhotels/banyancamp.jpg";
import six from "../assets/images/slhotels/rainforesteco.jpg";
import seven from "../assets/images/slhotels/kk.jpg";

const SignatureCollection_Rush = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="Back of Beyond (ECO)"
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="Wild Glamping Knuckles (TC) "
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Signature "
        hotel="River View Wathsala Inn "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Signature "
        hotel="Borderlands (ECO)"
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Signature "
        hotel="Banyan Camp (ECO) "
      />
      <HotelSL
        image={six}
        color="#d5b23f"
        catName="Signature "
        hotel="Rainforest Eco Lodge (ECO) "
      />
      <HotelSL
        image={seven}
        color="#d5b23f"
        catName="Signature "
        hotel="KK Beach "
      />
    </div>
  );
};

export default SignatureCollection_Rush;
