import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_IslandDay2 from "./TourSlides_IslandDay2";

const Tour_IslandDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_IslandDay2 />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Galle - Udawalawe [02:00] - Ella [02:00]</h2>
      </div>
      <p>Elephant Transit Home [01:00]</p>
      <p>Ravana Falls [00:15]</p>
    </div>
  );
};

export default Tour_IslandDay2;
