import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_PostcardDay3 from "./TourSlides_PostcardDay3";

const Tour_PostcardDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_PostcardDay3 />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Sigiriya - Polonnaruwa [01:30] - Sigiriya</h2>
      </div>
      <p>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</p>
      <p>Cycle through the Ancient Kingdom of Polonnaruwa-UNESCO [02:30]</p>
    </div>
  );
};

export default Tour_PostcardDay3;
