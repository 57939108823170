import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/kk.jpg";
import two from "../assets/images/slhotels/karpaha.jpg";

const SignatureCollectionBeach_Essence = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="KK Beach "
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="Karpaha Sands, Kalkudah"
      />
    </div>
  );
};

export default SignatureCollectionBeach_Essence;
