import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond6_slide from "./Dimensiond6_slide";

const Tour_DimensionDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond6_slide />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Kandy </h2>
      </div>
      <p>Leisure </p>
    </div>
  );
};

export default Tour_DimensionDay6;
