import { Translate } from "@material-ui/icons";
import React, { Component } from "react";
import Slider from "react-slick";
import "../assets/css/Days_Destinations.css";

import Tour_DimensionDay1 from "./Dimensiond1";
import Tour_DimensionDay2 from "./Dimensiond2";
import Tour_DimensionDay3 from "./Dimensiond3";
import Tour_DimensionDay4 from "./Dimensiond4";
import Tour_DimensionDay5 from "./Dimensiond5";
import Tour_DimensionDay6 from "./Dimensiond6";
import Tour_DimensionDay7 from "./Dimensiond7";
import Tour_DimensionDay8 from "./Dimensiond8";
import Tour_DimensionDay9 from "./Dimensiond9";
import Tour_DimensionDay10 from "./Dimensiond10";
import Tour_DimensionDay11 from "./Dimensiond11";
import Tour_DimensionDay12 from "./Dimensiond12";
import Tour_DimensionDay13 from "./Dimensiond13";
import Tour_DimensionDay14 from "./Dimensiond14";
import Destinations1 from "./Destionations1";
import Dimensions1 from "./Destionations1";
import Destinations2 from "./Destinations2";
import Destinations3 from "./Destinations3";
import Destinations4 from "./Destinations4";
import Destinations5 from "./Destinations5";
import Destinations6 from "./Destinations6";
import Destinations7 from "./Destinations7";
import Destinations8 from "./Destinations8";
import Destinations9 from "./Destinations9";
import Destinations10 from "./Destinations10";
import Destinations11 from "./Destinations11";
import Destinations12 from "./Destinations12";
import Destinations13 from "./Destinations13";
import Destinations14 from "./Destinations14";

export default class Days_Destinations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      bdcolor: false,
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d9bdcolor: "#008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d13bdcolor: "#008080",
      d14bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bxshadow: "0 0 2px #008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bxshadow: "0 0 2px #008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    };
  }

  changeColor = (e) => {
    this.setState({
      bdcolor: true,
    });
  };

  changeColord1 = (e) => {
    this.setState({
      d1bdcolor: "#f1c40f",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d9bdcolor: "#008080",
      d1bxshadow: "0 0 2px #f1c40f",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };
  changeColord2 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#f1c40f",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #f1c40f",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord3 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#f1c40f",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #f1c40f",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord4 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#f1c40f",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #f1c40f",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord5 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#f1c40f",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #f1c40f",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord6 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#f1c40f",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #f1c40f",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord7 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#f1c40f",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #f1c40f",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord8 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#f1c40f",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #f1c40f",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord9 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#f1c40f",
      d9bxshadow: "0 0 2px #f1c40f",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord10 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#f1c40f",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #f1c40f",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord11 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#f1c40f",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #f1c40f",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord12 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#f1c40f",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #f1c40f",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord13 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#f1c40f",
      d13bxshadow: "0 0 2px #f1c40f",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord14 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#f1c40f",
      d14bxshadow: "0 0 2px #f1c40f",
      d15bdcolor: "#008080",
      d15bxshadow: "0 0 2px #008080",
    });
  };

  changeColord15 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 2px #008080",
      d2bxshadow: "0 0 2px #008080",
      d3bxshadow: "0 0 2px #008080",
      d4bxshadow: "0 0 2px #008080",
      d5bxshadow: "0 0 2px #008080",
      d6bxshadow: "0 0 2px #008080",
      d7bxshadow: "0 0 2px #008080",
      d8bxshadow: "0 0 2px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 2px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 2px #008080",
      d11bxshadow: "0 0 2px #008080",
      d12bxshadow: "0 0 2px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 2px #008080",
      d14bdcolor: "#008080",
      d14bxshadow: "0 0 2px #008080",
      d15bdcolor: "#f1c40f",
      d15bxshadow: "0 0 2px #f1c40f",
    });
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      nav1: this.slider1,
      nav2: this.slider2,
      d1bdcolor: "#f1c40f",
      d1bxshadow: "0 0 2px #f1c40f",
    });
  }

  render() {
    return (
      <div className="superContainer_Destinations">
        <Slider
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          slidesToShow={15}
          swipeToSlide={true}
          focusOnSelect={true}
          infinite={false}
          className="navSlider"
        >
          <div className="box">
            <div
              onClick={this.changeColord1}
              className="navxx"
              style={{
                borderColor: `${this.state.d1bdcolor}`,
                boxShadow: `${this.state.d1bxshadow}`,
              }}
            >
              <h3 tabIndex="1" className="dayNo">
                GALLE
              </h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord2}
              className="navxx"
              style={{
                borderColor: `${this.state.d2bdcolor}`,
                boxShadow: `${this.state.d2bxshadow}`,
              }}
            >
              <h3 tabIndex="2" className="dayNo">
                KANDY
              </h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord3}
              className="navxx"
              style={{
                borderColor: `${this.state.d3bdcolor}`,
                boxShadow: `${this.state.d3bxshadow}`,
              }}
            >
              <h3 className="dayNo">CULTURAL TRIANGLE</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord4}
              className="navxx"
              style={{
                borderColor: `${this.state.d4bdcolor}`,
                boxShadow: `${this.state.d4bxshadow}`,
              }}
            >
              <h3 className="dayNo">HILL COUNTRY</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord5}
              className="navxx"
              style={{
                borderColor: `${this.state.d5bdcolor}`,
                boxShadow: `${this.state.d5bxshadow}`,
              }}
            >
              <h3 className="dayNo">YALA</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord6}
              className="navxx"
              style={{
                borderColor: `${this.state.d6bdcolor}`,
                boxShadow: `${this.state.d6bxshadow}`,
              }}
            >
              <h3 className="dayNo">COLOMBO</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord7}
              className="navxx"
              style={{
                borderColor: `${this.state.d7bdcolor}`,
                boxShadow: `${this.state.d7bxshadow}`,
              }}
            >
              <h3 className="dayNo">JAFFNA</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord8}
              className="navxx"
              style={{
                borderColor: `${this.state.d8bdcolor}`,
                boxShadow: `${this.state.d8bxshadow}`,
              }}
            >
              <h3 className="dayNo">EAST COAST</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord9}
              className="navxx"
              style={{
                borderColor: `${this.state.d9bdcolor}`,
                boxShadow: `${this.state.d9bxshadow}`,
              }}
            >
              <h3 className="dayNo">GAL OYA</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord10}
              className="navxx"
              style={{
                borderColor: `${this.state.d10bdcolor}`,
                boxShadow: `${this.state.d10bxshadow}`,
              }}
            >
              <h3 className="dayNo">UDAWALAWE</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord11}
              className="navxx"
              style={{
                borderColor: `${this.state.d11bdcolor}`,
                boxShadow: `${this.state.d11bxshadow}`,
              }}
            >
              <h3 className="dayNo">SOUTH COAST</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord12}
              className="navxx"
              style={{
                borderColor: `${this.state.d12bdcolor}`,
                boxShadow: `${this.state.d12bxshadow}`,
              }}
            >
              <h3 className="dayNo">SINHARAJA</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord13}
              className="navxx"
              style={{
                borderColor: `${this.state.d13bdcolor}`,
                boxShadow: `${this.state.d13bxshadow}`,
              }}
            >
              <h3 className="dayNo">WEST COAST</h3>
            </div>
          </div>
          <div className="box">
            <div
              onClick={this.changeColord14}
              className="navxx"
              style={{
                borderColor: `${this.state.d14bdcolor}`,
                boxShadow: `${this.state.d14bxshadow}`,
              }}
            >
              <h3 className="dayNo">KALPITIYA</h3>
            </div>
          </div>
        </Slider>
        <Slider
          className="main"
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          arrows={false}
          autoplay={false}
          swipeToSlide={false}
          slidesToScroll={0}
        >
          <div className="slide">
            <Destinations1 />
          </div>
          <div className="slide">
            <Destinations2 />
          </div>
          <div className="slide">
            <Destinations3 />
          </div>
          <div className="slide">
            <Destinations4 />
          </div>
          <div className="slide">
            <Destinations5 />
          </div>
          <div className="slide">
            <Destinations6 />
          </div>
          <div className="slide">
            <Destinations7 />
          </div>
          <div className="slide">
            <Destinations8 />
          </div>
          <div className="slide">
            <Destinations9 />
          </div>
          <div className="slide">
            <Destinations10 />
          </div>
          <div className="slide">
            <Destinations11 />
          </div>
          <div className="slide">
            <Destinations12 />
          </div>
          <div className="slide">
            <Destinations13 />
          </div>
          <div className="slide">
            <Destinations14 />
          </div>
        </Slider>
      </div>
    );
  }
}
