import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond10_slide from "./Dimensiond10_slide";

const Tour_DimensionDay10 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond10_slide />
      </div>
      <div className="line">
        <h3>Day 10 : </h3>
        <h2>Belihuloya </h2>
      </div>
      <p>Leisure </p>
    </div>
  );
};

export default Tour_DimensionDay10;
