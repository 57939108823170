import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import TourSlides_IslandDay1 from "./TourSlides_IslandDay1";

const Tour_IslandDay1 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_IslandDay1 />
      </div>
      <div className="line">
        <h3>Day 01 : </h3>
        <h2>CMB Airport - Galle [02:30]</h2>
      </div>
      <p>Unwind</p>
      <p>Walk inside the ramparts of Galle Fort -UNESCO [01:00]</p>
    </div>
  );
};

export default Tour_IslandDay1;
