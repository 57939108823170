import React from "react";
import Home from "./Home";
import GlobalStyle from "./globalStyle";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SriLanka from "./SriLanka";
import HistoryandHeritage from "./components/HistoryandHeritage";
import Partners from "./components/Partners";
import IslandRomance from "./components/IslandRomance";
import Postcard from "./components/Postcard";
import Immersion from "./components/Immersion";
import Getaway from "./components/Getaway";
import Ignite from "./components/Ignite";
import Essence from "./components/Essence";
import Travel from "./components/Travel";
import Rush from "./components/Rush";
import Wildlife from "./components/Wildlife";
import Buds from "./components/Buds";
import Serenity from "./components/Serenity";

import Dimension from "./components/Dimension";
import Bond from "./components/Bond";
import Sample from "./components/Sample";

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/srilanka" component={SriLanka} />
        <Route path="/historyandheritage" component={HistoryandHeritage} />
        <Route path="/islandromance" component={IslandRomance} />
        <Route path="/escapetopostcard" component={Postcard} />
        <Route path="/immersionintowild" component={Immersion} />
        <Route path="/familygetaway" component={Getaway} />
        <Route path="/reignitetheflame" component={Ignite} />
        <Route path="/essenceoflove" component={Essence} />
        <Route path="/travellikealocal" component={Travel} />
        <Route path="/adrenalinerush" component={Rush} />
        <Route path="/exhilaratingwildlife" component={Wildlife} />
        <Route path="/tastebuds" component={Buds} />
        <Route path="/serenityinserendib" component={Serenity} />
        <Route path="/anotherdimension" component={Dimension} />
        <Route path="/lovedones" component={Bond} />
        <Route path="/test" component={Sample} />
        <Route path="/partners" component={Partners} />
      </Switch>
    </Router>
  );
};

export default App;
