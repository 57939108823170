import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/maniumpathy.jpg";
import two from "../assets/images/slhotels/watergarden.jpg";
import three from "../assets/images/slhotels/kandyanmanor.jpg";
import four from "../assets/images/slhotels/nineskies.jpg";
import five from "../assets/images/slhotels/tvrampart.jpg";

const PremiumCollection_Travel = () => {
  return (
    <div className="collection">
      <h3>Premium Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Maniumpathy"
      />
      <HotelSL
        image={two}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Water Garden Sigiriya "
      />
      <HotelSL
        image={three}
        color="#d4af38"
        catName="Premium Signature "
        hotel="The Kandyan Manor Homestay "
      />
      <HotelSL
        image={four}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Nine Skies Bungalow (AI)"
      />
      <HotelSL
        image={five}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Taru Villas - Rampart Street"
      />
    </div>
  );
};

export default PremiumCollection_Travel;
