import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd6_slide from "./Serenityd6_slide";

const Tour_SerenityDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd6_slide />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Kandy </h2>
      </div>
      <p>Leisure</p>
    </div>
  );
};

export default Tour_SerenityDay6;
