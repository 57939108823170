import React from "react";

import kudadooimage from "../assets/images/Maldives/kudadoo.png";
import rafflesimage from "../assets/images/Maldives/raffles.png";
import sonevafushiimage from "../assets/images/Maldives/sonevafushi.png";
import sonevajaniimage from "../assets/images/Maldives/sonevajani.png";
import nautilusimage from "../assets/images/Maldives/nautilus.png";
import waldorfimage from "../assets/images/Maldives/waldorf.png";
import Hotel_UltraLuxury from "./Hotel_UltraLuxury";

const Hotels_UltraLuxury = ({
  KudadooPopup,
  RafflesPopup,
  SonevaPopup,
  SonevajaniPopup,
  NautilusPopup,
  WaldorfPopup,
}) => {
  return (
    <div className="hotels">
      <h1
        style={{ fontFamily: `"Cormorant Garamond", serif`, fontSize: "19px" }}
        className="Heading"
      >
        Ultra Luxury Collection
      </h1>
      <div className="HotelsContainer">
        <div className="row">
          <Hotel_UltraLuxury
            runner={KudadooPopup}
            image={kudadooimage}
            title="Kudadoo Private Island"
          />
          <Hotel_UltraLuxury
            runner={RafflesPopup}
            image={rafflesimage}
            title="Raffles Maldives Meradhoo"
          />
          <Hotel_UltraLuxury
            runner={SonevaPopup}
            image={sonevafushiimage}
            title="Soneva Fushi"
          />
          <Hotel_UltraLuxury
            runner={SonevajaniPopup}
            image={sonevajaniimage}
            title="Soneva Jani"
          />
          <Hotel_UltraLuxury
            runner={NautilusPopup}
            image={nautilusimage}
            title="The Nautilus Maldives"
          />
        </div>
        <div className="row">
          <Hotel_UltraLuxury
            runner={WaldorfPopup}
            image={waldorfimage}
            titlelong="Waldorf Astoria Maldives Ithaafushi"
          />
        </div>
      </div>
    </div>
  );
};

export default Hotels_UltraLuxury;
