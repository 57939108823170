import React from "react";
import { useState } from "react";
import items from "./MaldivesData";
import "../assets/css/Maldives.css";
import Menu from "./Menu";
import ButtonF from "./ButtonF";
const allCategories = ["All", ...new Set(items.map((item) => item.category))];

console.log(allCategories);

const MaldivesSection = () => {
  const [menuItem, setMenuItem] = useState(items);
  const [buttons, setButtons] = useState(allCategories);

  const filter = (button) => {
    if (button === "All") {
      setMenuItem(items);
      return;
    }

    const filteredData = items.filter((item) => item.category === button);
    setMenuItem(filteredData);
  };

  return (
    <div className="Fil">
      <div className="title">
        <h1>Maldives</h1>
      </div>

      <ButtonF button={buttons} filter={filter} />
      <Menu menuItem={menuItem} />
    </div>
  );
};

export default MaldivesSection;
