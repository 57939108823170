import React from "react";
import amillaimage from "../assets/images/Maldives/amilla.png";
import dhiguimage from "../assets/images/Maldives/dhigu.png";
import barosimage from "../assets/images/Maldives/baros.png";
import cocoaimage from "../assets/images/Maldives/cocoa.png";
import maalifushiimage from "../assets/images/Maldives/maalifushi.png";
import conradimage from "../assets/images/Maldives/conrad.png";
import giliimage from "../assets/images/Maldives/gili.png";
import hurawalhiimage from "../assets/images/Maldives/hurawalhi.png";
import milaidooimage from "../assets/images/Maldives/milaidoo.png";
import kandolhuimage from "../assets/images/Maldives/kandolhu.png";
import niyamaimage from "../assets/images/Maldives/niyama.png";
import patinaimage from "../assets/images/Maldives/patina.png";
import siximage from "../assets/images/Maldives/six.png";
import regisimage from "../assets/images/Maldives/regis.png";
import vakkaruimage from "../assets/images/Maldives/vakkaru.png";
import velessaruimage from "../assets/images/Maldives/velessaru.png";
import wimage from "../assets/images/Maldives/w.png";
import Hotel_Luxury from "./Hotel_Luxury";

const Hotels_Luxury = ({
  AmillaPopup,
  AnantaraDhiguPopup,
  BarosPopup,
  COMOPopup,
  MaalifushiPopup,
  ConradPopup,
  GiliPopup,
  HuraPopup,
  KandolhuPopup,
  MilaiPopup,
  NiyamaPopup,
  PatinaPopup,
  SixPopup,
  RegisPopup,
  VakkaruPopup,
  VelessaruPopup,
  WPopup,
}) => {
  return (
    <div className="hotels">
      <h1
        style={{ fontFamily: `"Cormorant Garamond", serif`, fontSize: "19px" }}
        className="Heading"
      >
        Luxury Collection
      </h1>
      <div className="HotelsContainer">
        <div className="row">
          <Hotel_Luxury
            runner={AmillaPopup}
            image={amillaimage}
            title="Amilla Fushi"
          />
          <Hotel_Luxury
            runner={AnantaraDhiguPopup}
            image={dhiguimage}
            title="Anantara Dhigu"
          />
          <Hotel_Luxury
            runner={BarosPopup}
            image={barosimage}
            title="Baros Maldives"
          />
          <Hotel_Luxury
            runner={COMOPopup}
            image={cocoaimage}
            title="COMO Cocoa Island"
          />
          <Hotel_Luxury
            runner={MaalifushiPopup}
            image={maalifushiimage}
            title="COMO Maalifushi"
          />
        </div>
        <div className="row">
          <Hotel_Luxury
            runner={ConradPopup}
            image={conradimage}
            title="Conrad Maldives"
          />
          <Hotel_Luxury
            runner={GiliPopup}
            image={giliimage}
            title="Gili Lankanfushi"
          />
          <Hotel_Luxury
            runner={HuraPopup}
            image={hurawalhiimage}
            title="Hurawalhi Island"
          />
          <Hotel_Luxury
            runner={KandolhuPopup}
            image={kandolhuimage}
            title="Kandolhu Maldives"
          />
          <Hotel_Luxury
            runner={MilaiPopup}
            image={milaidooimage}
            title="Milaidhoo Island"
          />
        </div>
        <div className="row">
          <Hotel_Luxury
            runner={NiyamaPopup}
            image={niyamaimage}
            title="Niyama Private Islands"
          />
          <Hotel_Luxury
            runner={PatinaPopup}
            image={patinaimage}
            title="Patina Maldives"
          />
          <Hotel_Luxury
            runner={SixPopup}
            image={siximage}
            title="Six Senses Laamu"
          />
          <Hotel_Luxury
            runner={RegisPopup}
            image={regisimage}
            titlelong="The St. Regis Maldives Vommuli Resort"
          />
          <Hotel_Luxury
            runner={VakkaruPopup}
            image={vakkaruimage}
            title="Vakkaru Maldives"
          />
        </div>
        <div className="row">
          <Hotel_Luxury
            runner={VelessaruPopup}
            image={velessaruimage}
            title="Velessaru Maldives"
          />
          <Hotel_Luxury runner={WPopup} image={wimage} title="W Maldives" />
        </div>
      </div>
    </div>
  );
};

export default Hotels_Luxury;
