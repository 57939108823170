import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Budsd6_slide from "./Budsd6_slide";

const Tour_BudsDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Budsd6_slide />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Anuradhapura - Sigiriya [01:00] - Kegalle [03:30]</h2>
      </div>
      <p>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</p>
      <p>Walk through a Sri Lankan spice garden with Lunch [01:30]</p>
    </div>
  );
};

export default Tour_BudsDay6;
