import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Getawayd10_slide from "./Getawayd10_slide";

const Tour_GetawayDay10 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Getawayd10_slide />
      </div>
      <div className="line">
        <h3>Day 10 : </h3>
        <h2>South Coast Beach - CMB Airport </h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_GetawayDay10;
