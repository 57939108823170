import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Getawayd2_slide from "./Getawayd2_slide";

const Tour_GetawayDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Getawayd2_slide />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Negombo - Sigiriya [02:30]</h2>
      </div>
      <p>Visit the Dambulla Cave Temple-UNESCO [00:40]</p>
    </div>
  );
};

export default Tour_GetawayDay2;
