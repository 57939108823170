import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import Ignited11_slide from "./Ignited11_slide";

const Tour_IgniteDay11 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited11_slide />
      </div>
      <div className="line">
        <h3>Day 11 : </h3>
        <h2>Negombo - CMB Airport [00:30]</h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_IgniteDay11;
