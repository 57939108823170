import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/villahundria.jpg";
import two from "../assets/images/slhotels/aliyaresort.jpg";
import three from "../assets/images/slhotels/kuwerakandy.jpg";
import four from "../assets/images/slhotels/ekho.jpg";
import five from "../assets/images/slhotels/cocobay.jpg";

const SuperiorCollection_Getaway = () => {
  return (
    <div className="collection">
      <h3>Superior Collection</h3>
      <HotelSL
        image={one}
        color="#ddbf61"
        catName="Superior "
        hotel="Villa Hundira "
      />
      <HotelSL
        image={two}
        color="#ddbf61"
        catName="Superior "
        hotel="Aliya Resort & Spa "
      />
      <HotelSL
        image={three}
        color="#ddbf61"
        catName="Superior "
        hotel="Kuwera Kandy"
      />
      <HotelSL
        image={four}
        color="#ddbf61"
        catName="Superior "
        hotel="EKHO Ella"
      />
      <HotelSL
        image={five}
        color="#ddbf61"
        catName="Superior "
        hotel="CocoBay Unawatuna"
      />
    </div>
  );
};

export default SuperiorCollection_Getaway;
