import React from "react";
// JSX
import HeroSlider, { Slide, SideNav, OverlayContainer } from "hero-slider";
import Wrapper from "../UI/Wrapper/Wrapper";

import styled from "styled-components";

import slide1 from "./Desktop/why.jpg";
import slide2 from "./Desktop/slide2.jpg";
import slide3 from "./Desktop/slide3.jpg";
import slide4 from "./Desktop/slide4.png";
import slide5 from "./Desktop/slide5.jpg";
import slide6 from "./Desktop/slide6.jpg";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: 0.5s;
  /* margin-top: 310px; */
  z-index: 100;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    /* margin-top: -600px; */
  }
`;
const Header = styled.h2`
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: white;
  margin-top: 60px;
  text-transform: uppercase;
  font-family: "Yeseva One", cursive;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 10px;
`;

const Content = styled.p`
  font-family: "Exo 2", sans-serif;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: white;
  padding: 20px;
  padding-bottom: 200px;
  text-align: center;
  font-size: 13px;
`;

const app = () => {
  return (
    <HeroSlider
      slidingAnimation="left_to_right"
      orientation="vertical"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={(nextSlide) => console.log("onChange", nextSlide)}
      onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "#000",
      }}
      settings={{
        slidingDuration: 400,
        slidingDelay: 100,
        shouldAutoplay: true,
        shouldDisplayButtons: true,
        autoplayDuration: 3000,
        height: "45vh",
      }}
    >
      <Slide
        navDescription="Black Widow"
        background={{
          //   backgroundAttachment: "fixed",
          backgroundColor: "#6D9B98",
          backgroundSize: "cover",
          backgroundImage: slide1,
        }}
      >
        <OverlayContainer>
          <Wrapper>
            {/* <MainHeader>Why Pick Us?</MainHeader> */}
            <Container>
              <Header>Why Pick Us?</Header>
              <Content>
                We provide pre-eminent experience and offer unforgettable
                holidays to travellers from around the globe through our years
                of unparalleled expertise in the industry. Whether you are a
                backpacker that focuses on experiential travel, or you may be
                seeking for the ideal tour for you and your family, or perhaps
                you simply look forward to an idyllic holiday at first-rate
                hotels, we have it all and we’ve done it all – The smarter way
                the affordable way!
              </Content>
              {/* <MainHeader>Why Pick Us?</MainHeader> */}
            </Container>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <Slide
        navDescription="Captain America"
        background={{
          // backgroundAttachment: "fixed",
          backgroundColor: "#8A8A8A",
          backgroundImage: slide2,
          backgroundSize: "cover",
        }}
      >
        <OverlayContainer>
          <Wrapper>
            {/* <MainHeader>Why Pick Us?</MainHeader> */}
            <Container>
              <Header>Commitment To Responsible Travel</Header>
              <Content>
                Responsible travel is one of the main pillars in our thinking
                process while creating your holidays. We have provided exclusive
                training to our staff all service providers about Environmental
                awareness. Our partners have been given the first-hand
                experience through community engagement projects to have a clear
                conscious and focus to design respectful and sustainable
                holidays. We are currently focused in acquiring the GSTC and
                Travelife membership and encourage our partners to be certified
                in sustainability.
              </Content>
              {/* <MainHeader>Why Pick Us?</MainHeader> */}
            </Container>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <Slide
        navDescription="Iron Man"
        background={{
          // backgroundAttachment: "fixed",
          // backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#EA2329",
          backgroundImage: slide3,
        }}
      >
        <OverlayContainer>
          <Wrapper>
            {/* <MainHeader>Why Pick Us?</MainHeader> */}
            <Container>
              <Header>Prioritizing Health & Safety</Header>
              <Content>
                We always put the client’s health and safety first even if it
                means recommending another destination instead of Sri Lanka or
                Maldives. We only promote Hotels, Experiences Logistic Services
                which are certified by respective government authorities after
                being inspected cleared by our own team of specialists. We do
                not hesitate to say “no” to a product or service that will
                threaten the health safety of our clients.
              </Content>
              {/* <MainHeader>Why Pick Us?</MainHeader> */}
            </Container>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <Slide
        navDescription="Thor"
        background={{
          // backgroundAttachment: "fixed",
          backgroundColor: "#2D7791",
          backgroundImage: slide4,
          backgroundSize: "cover",
        }}
      >
        <OverlayContainer>
          <Wrapper>
            {/* <MainHeader>Why Pick Us?</MainHeader> */}
            <Container>
              <Header>Flexible: COVID-19 Cancellation Policy</Header>
              <Content>
                We do understand that people may be hesitant to book a holiday
                at the moment given current concerns over global travel. To help
                reassure and support travellers, we have partnered with our
                Hotels Suppliers to deliver very special, extremely-flexible
                cancellation policy up until the client’s arrival to the
                country.
              </Content>
              {/* <MainHeader>Why Pick Us?</MainHeader> */}
            </Container>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <Slide
        navDescription="Thor"
        background={{
          // backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundColor: "#2D7791",
          backgroundImage: slide5,
        }}
      >
        <OverlayContainer>
          <Wrapper>
            {/* <MainHeader>Why Pick Us?</MainHeader> */}
            <Container>
              <Header>24 x 7 Customer Care Service</Header>
              <Content>
                Our clients are never alone in the country as the 24 x 7
                on-ground customer care team will be at their fingertips through
                a Call, Text Message, Email or even by WhatsApp to attend all
                questions and concerns from the time they pack their bags and
                until they are unpacking them back at home.
              </Content>
              {/* <MainHeader>Why Pick Us?</MainHeader> */}
            </Container>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <Slide
        navDescription="Thor"
        background={{
          // backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundColor: "#2D7791",
          backgroundImage: slide6,
        }}
      >
        <OverlayContainer>
          <Wrapper>
            {/* <MainHeader>Why Pick Us?</MainHeader> */}
            <Container>
              <Header>Global Representation</Header>
              <Content>
                Our representative network has been growing from Asia to Europe
                with France, Italy, Spain Portugal being the latest addition to
                our portfolio. With these new additions, we try to be close to
                our Agents while planning the best getaways for their beloved
                clients.
              </Content>
              {/* <MainHeader>Why Pick Us?</MainHeader> */}
            </Container>
          </Wrapper>
        </OverlayContainer>
      </Slide>

      <SideNav />
    </HeroSlider>
  );
};

export default app;
