import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Essenced2_slide from "./Essenced2_slide";
import Essenced3_slide from "./Essenced3_slide";

const Tour_EssenceDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Essenced3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Yala National Park</h2>
      </div>
      <p>Morning Jeep Safari in Yala National Park [03:00]</p>
    </div>
  );
};

export default Tour_EssenceDay3;
