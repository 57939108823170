import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd1_slide from "./Serenityd1_slide";

const Tour_SerenityDay1 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd1_slide />
      </div>
      <div className="line">
        <h3>Day 01 : </h3>
        <h2>CMB Airport - Negombo [00:30]</h2>
      </div>
      <p>Unwind</p>
    </div>
  );
};

export default Tour_SerenityDay1;
