import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Budsd10_slide from "./Budsd10_slide";

const Tour_BudsDay10 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Budsd10_slide />
      </div>
      <div className="line">
        <h3>Day 10 : </h3>
        <h2>Galle - Colombo [02:00]</h2>
      </div>
      <p>Learn the art of Cinnamon [01:00]</p>
      <p>Taste the street-food of Colombo [01:00]</p>
    </div>
  );
};

export default Tour_BudsDay10;
