import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd7_slide from "./Serenityd7_slide";

const Tour_SerenityDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Kandy - Hatton [02:00] </h2>
      </div>
      <p>Visit the Temple of the Sacred Tooth Relic -UNESCO [01:30]</p>
      <p>Visit the Peradeniya Royal Botanical Gardens [01:00]</p>
      <p>Spa Treatment [00:45]</p>
    </div>
  );
};

export default Tour_SerenityDay7;
