import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Budsd3_slide from "./Budsd3_slide";

const Tour_BudsDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Budsd3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Mannar - Jaffna [02:30]</h2>
      </div>
      <p>Tour around the streets of Jaffna [01:30]</p>
      <p>Experience the spicy cuisine of the Northern Peninsula </p>
    </div>
  );
};

export default Tour_BudsDay3;
