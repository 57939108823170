import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";
import water from "../assets/images/slhotels/watergarden.jpg";
import kings from "../assets/images/slhotels/kingspavilion.jpg";
import amangalla from "../assets/images/slhotels/amangalla.jpg";

const LuxuryCollection_History = () => {
  return (
    <div className="collection">
      <h3>Luxury Collection</h3>
      <HotelSL
        image={water}
        color="#d4af38"
        catName="Luxury "
        hotel="Water Garden Sigiriya  "
      />
      <HotelSL
        image={kings}
        color="#d4af38"
        catName="Luxury "
        hotel="King's Pavilion Kandy"
      />
      <HotelSL
        image={amangalla}
        color="#d4af38"
        catName="Luxury "
        hotel="Amangalla  "
      />
    </div>
  );
};

export default LuxuryCollection_History;
