import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/pledgescape.jpg";
import two from "../assets/images/slhotels/ulagalla.jpg";
import three from "../assets/images/slhotels/wildgrass.jpg";
import four from "../assets/images/slhotels/santani.jpg";
import five from "../assets/images/slhotels/camellia.jpg";
import six from "../assets/images/slhotels/kahanda.jpg";

const SignatureCollection_Serenity = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="Pledge Scape Negombo"
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="Ulagalla by Uga"
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Signature "
        hotel="Wild Grass Nature Resort  "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Signature "
        hotel="Santani Resort & Spa (ECO)"
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Signature "
        hotel="Camellia Hills  (AI) "
      />
      <HotelSL
        image={six}
        color="#d5b23f"
        catName="Signature "
        hotel="Kahanda Kanda "
      />
    </div>
  );
};

export default SignatureCollection_Serenity;
