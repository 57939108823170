import React, { useState } from "react";
import sample from "../assets/images/Maldives/sampeimg.jpg";
import styled from "styled-components";

const HotelWrapper = styled.div`
  width: 270px;
  height: auto;
  background-color: white;
  /* margin-right: 70px; */
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  }

  @media screen and (max-width: 768px) {
    width: 250px;
    margin-bottom: 15px;
    height: fit-content;
  }
  @media screen and (max-width: 1366px) {
    width: 220px;
    margin-bottom: 15px;
    height: fit-content;
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Image = styled.img`
  width: 270px;
  /* border-radius: 10px; */
  overflow: hidden;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  @media screen and (max-width: 768px) {
    width: 250px;
  }

  @media screen and (max-width: 1366px) {
    width: 220px;
  }
`;

const Title = styled.h2`
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

  font-family: "Raleway", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 4px;
  font-size: 18px;
`;

const LongTitle = styled.h3`
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

  font-family: "Raleway", sans-serif;
  font-weight: 400;
  text-align: center;
  /* margin-top: 10px; */
  /* height: 50px; */
  font-size: 18px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100px;

  @media screen and (max-width: 1366px) {
    height: 85px;
  }

  @media screen and (max-width: 768px) {
    height: 85px;
  }
`;

const Subtitle = styled.h4`
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

  font-family: "Raleway", sans-serif;
  font-weight: 400;
  text-align: center;
  color: #ddbf61;
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 4px;
`;

const Description = styled.p`
  text-align: center;
  color: gray;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "century-gothic", sans-serif;
`;

const ButtonWrapper = styled.div`
  height: 50px;

  background-color: #ddbf61;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  align-items: center;
  display: flex;
`;

const Button = styled.button`
  padding: 7px 40px;
  align-self: center;
  margin: auto;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  transition: 0.5s;

  &:hover {
    background-color: white;
    color: #9b59b6;
    cursor: pointer;
  }
`;

const Hotel_SuperDeluxe = ({ id, runner, title, titlelong, image }) => {
  const [value, setValue] = useState();

  return (
    <HotelWrapper>
      <Image src={image} />
      <TitleWrapper>
        <Subtitle>Super Deluxe</Subtitle>
        <Title>{title}</Title>
        <LongTitle>{titlelong}</LongTitle>
        {/* <Description>
          Click on inquire to get a quote
        </Description> */}
      </TitleWrapper>
      <ButtonWrapper>
        <Button onClick={runner}>Enquire</Button>
      </ButtonWrapper>
    </HotelWrapper>
  );
};

export default Hotel_SuperDeluxe;
