import React, { useEffect, useState } from "react";
import "../assets/css/Bond.css";
import bond from "../assets/images/bond.jpg";

import Footer from "./Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { HashLink } from "react-router-hash-link";

import Days_Dimension from "./Days_Dimension";
import CollectionNav_SinglePre from "./CollectionNav_SinglePre";
import PremiumCollection_Dimension from "./Premium_Dimension";
import Days_Bond from "./Days_Bond";
import CollectionNav_Premium from "./CollectionNav_Premium";
import SignatureCollection_Bond from "./Signature_Bond";
import PremiumCollection_Bond from "./Premium_Bond";
import SignatureCollectionBeach_Bond from "./SignatureBeach_Bond";
import PremiumCollectionBeach_Bond from "./PremiumBeach_Bond";
import NavbarSriLanka from "./NavbarSriLanka";
import DropdownSriLanka from "./DropdownSriLanka";
import NavbarDarkSriLanka from "./NavbarDarkSriLanka";
import Footer_SriLanka from "./Footer_SriLanka";
import styled from "styled-components";

const BlankComponent = styled.div`
  @media screen and (max-width: 1366px) {
    margin-top: -100px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CustomButton = styled(HashLink)`
  text-decoration: none;
  padding: 10px 15px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  background-color: #008080;
  border-radius: 5px;
  border: none;
  transition: 0.4s;
  margin-bottom: 20px;
  margin-top: -10px;

  &:hover {
    cursor: pointer;
    background-color: #d0b49f;
  }
`;

const Bond = () => {
  const beachhotelData = [
    {
      cat: "Signature Collection",
    },
    {
      cat: "Premium Signature Collection",
    },
  ];

  const hotelData = [
    {
      cat: "Signature Collection",
    },
    {
      cat: "Premium Signature Collection",
    },
  ];

  useEffect(() => {
    document.title = "Bond with Your Loved Ones";
  }, []);

  const [hotel, setHotel] = useState([]);
  const [beach, setBeach] = useState([]);
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sriLanka_Package">
      <head></head>
      <div className="nav">
        <NavbarDarkSriLanka toggle={toggle} />
        <DropdownSriLanka isOpen={isOpen} toggle={toggle} />
      </div>
      {/* navbar */}
      <div className="headerBond">
        <div className="leftSide">
          <img alt="" src={bond} />
        </div>
        <div className="rightSide">
          <HashLink smooth to="#enquire" className="fixedAnchorButton">
            Enquire
          </HashLink>
          {/* <h3>Sri Lanka</h3> */}
          <h1>Bond with Your Loved Ones</h1>

          <HashLink
            className="button"
            smooth={true}
            offset={-70}
            duration={500}
            to="#enquire"
          >
            Enquire
          </HashLink>
        </div>
      </div>
      <div className="packageDes_Container">
        <p className="packageDes">
          <span>“</span>Strengthening the bonds between loved ones is what we
          love to do. Every aspect of this tour has been thoroughly thought to
          result in deepening the bonds between families. The activities and
          excursions selected are suitable for all ages; from very young to the
          ‘not so young’ ones.
          <span>”</span>
        </p>
      </div>
      <div className="tourDetails">
        <Days_Bond />
      </div>
      <BlankComponent />
      <div className="hotels">
        <h1 className="topic">
          A
          <span
            style={{
              color: "#D0B49F",
              fontFamily: `'Cormorant Garamond', serif`,
              fontWeight: "400",
            }}
          >
            ccommodation
          </span>
        </h1>

        <CollectionNav_Premium
          targetSignature="/lovedones"
          targetPremium="/lovedones/premium"
          signaturePrice="300"
          premiumPrice="450"
        />

        <Switch>
          <Route
            path="/lovedones/premium"
            exact
            render={() => <PremiumCollection_Bond />}
          />
          <Route
            path="/lovedones"
            render={() => <SignatureCollection_Bond />}
          />
        </Switch>
      </div>
      <div className="hotels">
        <h1 className="topic">
          Beach <span className="special">Extensions</span>
        </h1>
        <CollectionNav_Premium
          targetSignature="/lovedones"
          targetPremium="/lovedones/beach_premium"
          signaturePrice="300"
          premiumPrice="450"
        />

        <Switch>
          <Route
            path="/lovedones/beach_premium"
            exact
            render={() => <PremiumCollectionBeach_Bond />}
          />
          <Route
            path="/lovedones"
            render={() => <SignatureCollectionBeach_Bond />}
          />
        </Switch>
      </div>

      <ul className="SLBullets">
        <li>
          <span>*</span>ECO - Eco Lodges
        </li>
        <li>
          <span>*</span>TC - Tented Camps
        </li>
        <li>
          <span>*</span>AI - All-inclusive
        </li>
        <li>
          <span>*</span>AITC - All-inclusive Tented Camps
        </li>
      </ul>

      <div id="enquire" className="form">
        <form
          method="POST"
          action="https://formsubmit.co/enquiries@silantravels.com"
        >
          <h2>Enquire Now</h2>
          <input
            type="hidden"
            name="_subject"
            value="Sri Lanka Tour Submission"
          />
          <input
            className="field"
            type="text"
            name="name"
            placeholder="Name"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="field"
            required
          />
          <input
            type="text"
            name="tour name"
            value="Bond with Your Loved Ones"
            style={{ display: "none" }}
          />
          <div className="seperate">
            <input
              className="field"
              type="text"
              name="nationality"
              placeholder="Nationality"
              required
            />
            <input
              className="field"
              type="number"
              name="number of adults"
              placeholder="Number of Adults"
            />
            <input
              className="field"
              type="number"
              name="number of children"
              placeholder="Number of Children"
            />
          </div>
          <div className="seperate1">
            <input
              className="field"
              type="text"
              onFocus={(e) => (e.currentTarget.type = "date")}
              onBlur={(e) => (e.currentTarget.type = "text")}
              placeholder="Arrival Date"
              name="date"
              required
            />
            <input
              className="field"
              type="number"
              name="nights"
              placeholder="Nights (Max: 20)"
              required
            />
          </div>
          <Multiselect
            style={{
              searchBox: {
                border: "none",
                backgroundColor: "#f3f3f3",
                marginBottom: "10px",
                padding: "10px",
              },
            }}
            placeholder="Hotel Category"
            type="text"
            displayValue="cat"
            onSelect={(e) => {
              setHotel(Array.isArray(e) ? e.map((x) => x.cat) : "");
            }}
            onRemove={(e) => {
              setHotel(Array.isArray(e) ? e.map((x) => x.cat) : "");
            }}
            options={hotelData}
            singleSelect={true}
            avoidHighlightFirstOption={true}
          />
          {hotel && (
            <input
              name="Hotel category"
              style={{ display: "none" }}
              value={JSON.stringify(hotel)}
            />
          )}
          <div className="seperate2">
            <label>Beach Extension</label>
            <input
              checked={checked}
              onChange={handleChange}
              className="checker"
              type="checkbox"
              name="beach extension"
            />
          </div>
          {checked ? (
            <>
              {" "}
              <Multiselect
                style={{
                  searchBox: {
                    border: "none",
                    backgroundColor: "#f3f3f3",
                    marginBottom: "10px",
                    padding: "10px",
                  },
                }}
                placeholder="Hotel Category (Beach)"
                type="text"
                displayValue="cat"
                onSelect={(e) => {
                  setBeach(Array.isArray(e) ? e.map((x) => x.cat) : "");
                }}
                onRemove={(e) => {
                  setBeach(Array.isArray(e) ? e.map((x) => x.cat) : "");
                }}
                options={beachhotelData}
                singleSelect={true}
                avoidHighlightFirstOption={true}
              />{" "}
              <input
                className="field"
                type="number"
                name="nights - Beach extension"
                placeholder="Nights (Max: 20)"
              />{" "}
            </>
          ) : (
            ""
          )}
          {beach && (
            <input
              name="Hotel category (Beach)"
              style={{ display: "none" }}
              value={JSON.stringify(beach)}
            />
          )}
          <textarea
            className="inputField"
            type="text"
            className="field"
            rows="5"
            placeholder="Customize Your Tour"
            name="Customize your tour"
          />

          <input className="button" type="submit" />
        </form>
      </div>

      {/* <div className="notes">
                <h2>Notes</h2>
                <h3>ECO - Eco Lodges</h3>
                <h3>TC- Tented Camps</h3>
                <h3>AITC - Al inclusive Tented Camps</h3>
                <p>Hotels are subject to availability.</p>
            <p>Travel durations are approximate and will depend on the traffic &amp; weather conditions.</p>
            <p>Duration for experiences are excluding the driving time to the location.</p>
            <p>The Signature &amp; Premium Signature Collections are not a category of hotels, but a set of selected properties to complement the essence of each tour respectively.</p>
            </div> */}
      <Footer_SriLanka />
    </div>
  );
};

export default Bond;
