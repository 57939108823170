import React from "react";
import styled from "styled-components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import slide1 from "./MobileSlideShow/slide1.jpg";
import slide2 from "./MobileSlideShow/slide2.jpg";
import slide3 from "./MobileSlideShow/slide3.jpg";
import slide4 from "./MobileSlideShow/slide4.jpg";
import slide5 from "./MobileSlideShow/slide5.jpg";
import slide6 from "./MobileSlideShow/slide6.jpg";
import slide7 from "./MobileSlideShow/slide7.jpg";
import slide8 from "./MobileSlideShow/slide8.jpg";

// JSX
import HeroSlider, {
  Slide,
  Nav,
  OverlayContainer,
  SideNav,
  ButtonsNav,
} from "hero-slider";
import Wrapper from "../UI/Wrapper/Wrapper";
import Title from "../UI/Title/Title";
import Subtitle from "../UI/Subtitle/Subtitle";

// Images
const bogliasco = "https://i.imgur.com/Gu5Cznz.jpg";
const countyClare = "https://i.imgur.com/idjXzVQ.jpg";
const craterRock = "https://i.imgur.com/8DYumaY.jpg";
const giauPass = "https://i.imgur.com/8IuucQZ.jpg";

const SliderWrapper = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
`;

const SmallTitle = styled.p`
  @import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dosis:wght@700&display=swap");

  text-align: center;
  color: #f4f4f4;
  font-family: "Exo 2", sans-serif;
  font-size: 35px;
  margin-bottom: 20px;
  width: 100%;
  margin-top: -100px;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const MainTitle = styled.h1`
  /* font-family: "Yeseva One", cursive; */
  /* font-family: "Bebas Neue", cursive; */
  @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

  text-transform: uppercase;
  width: 50%;
  line-height: 100px;
  text-align: center;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    width: 80%;
    line-height: 55px;

    span {
      color: #d7be69 !important;
      font-family: "Cormorant Garamond", serif;
      text-transform: uppercase;
      text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.8);
    }
  }
`;

const Button = styled.button`
  align-self: flex-start;
  padding: 10px 25px;
  font-family: "Exo 2", sans-serif;
  color: white;
  text-transform: uppercase;
  background: transparent;
  border: 1px solid white;
  border-radius: 40px;
  margin-left: 860px;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 600;
  transition: 0.3s;

  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`;

const ParaM = styled.p`
  @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
  font-family: "Didact Gothic", sans-serif;
  color: #fff;
  max-width: 80%;
  text-align: center;
`;

const BasicSliderMobile = () => {
  return (
    <SliderWrapper>
      <HeroSlider
        slidingAnimation="left_to_right"
        orientation="horizontal"
        initialSlide={1}
        onBeforeChange={(previousSlide, nextSlide) =>
          console.log("onBeforeChange", previousSlide, nextSlide)
        }
        onChange={(nextSlide) => console.log("onChange", nextSlide)}
        onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.33)",
        }}
        settings={{
          slidingDuration: 250,
          isSmartSliding: true,
          slidingDelay: 100,
          shouldAutoplay: true,
          shouldDisplayButtons: false,
          autoplayDuration: 3000,
          height: "100vh",
        }}
      >
        <Slide
          background={{
            backgroundImage: slide1,
            backgroundAnimation: "zoom",

            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Sri Lanka</SmallTitle> */}
              <MainTitle>
                The Land <br /> Of The <span>Smiles</span>
              </MainTitle>
              <ParaM>
                “The land of smiles with 2500+ years of written history &
                ancient civilizations.”
              </ParaM>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide2,
            backgroundAnimation: "zoom",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Sri Lanka</SmallTitle> */}
              <MainTitle>
                <span>D</span>iversity
              </MainTitle>
              <ParaM>
                “History, archaeology, religion, culture, food, arts & music
                diverse with multi-cultures, multi-ethnics & multi-religious
                people living under one flag.”
              </ParaM>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide3,
            backgroundAnimation: "zoom",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Sri Lanka</SmallTitle> */}
              <MainTitle>
                Geographical <span>Marvel</span>
              </MainTitle>
              <ParaM>
                “Sunny Beaches to Lush Green Mountains 2500+ ft. above sea level
                within just 4-5 hour.”
              </ParaM>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide4,
            backgroundAnimation: "zoom",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Sri Lanka</SmallTitle> */}
              <MainTitle>
                <span>B</span>iodiversity
              </MainTitle>
              <ParaM>
                “Biodiversity filled with magnificent whales, Elephants,
                Leopards, Sloth Bears, Deers & Birds.”
              </ParaM>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide5,
            backgroundAnimation: "zoom",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Sri Lanka</SmallTitle> */}
              <MainTitle>
                <span>A</span>dventure
              </MainTitle>
              <ParaM>
                “Intrepid Adventures with rafting, kite surfing, surfing,
                hiking, trekking, canyoning & camping.”
              </ParaM>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide6,
            backgroundAnimation: "zoom",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Sri Lanka</SmallTitle> */}
              <MainTitle>
                <span>L</span>uxury
              </MainTitle>
              <ParaM>
                “Enjoy a royal lavishness through a combination of Modern &
                Colonial luxury presented with world class Sri Lankan
                hospitality.”
              </ParaM>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide7,
            backgroundAnimation: "zoom",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Sri Lanka</SmallTitle> */}
              <MainTitle>
                Wellness & <span>Ayurveda</span>
              </MainTitle>
              <ParaM>
                “Indigenous ayurveda medicine & wellness heritage dating back to
                more than 5000 years.”
              </ParaM>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <Slide
          background={{
            backgroundImage: slide8,
            backgroundAnimation: "zoom",
            // backgroundAnimation: "zoom",
          }}
        >
          <OverlayContainer>
            <Wrapper>
              {/* <SmallTitle>Country 2</SmallTitle> */}
              <MainTitle>
                <span>M</span>aldives
              </MainTitle>
              <ParaM>
                “Escape to the uninterrupted bespoke views of the white sandy
                beaches or the astonishing turquoise lagoons.”
              </ParaM>
            </Wrapper>
          </OverlayContainer>
        </Slide>

        <SideNav />
        {/* <ButtonsNav /> */}
        <Nav />
      </HeroSlider>
    </SliderWrapper>
  );
};

export default BasicSliderMobile;
