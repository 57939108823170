import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_HistoryDay3 from "./TourSlides_HistoryDay3";

const Tour_HistoryDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_HistoryDay3 />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Sigiriya - Polonnaruwa [01:30] - Sigiriya</h2>
      </div>
      <p>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</p>
      <p>Visit the Ancient Kingdom of Polonnaruwa-UNESCO [02:30]</p>
      <p>Jeep safari in Minneriya National Park [03:00]</p>
    </div>
  );
};

export default Tour_HistoryDay3;
