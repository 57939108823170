import React from "react";

const SriLanka = () => {
  return (
    <div>
      <h1>Sri lanka page</h1>
    </div>
  );
};

export default SriLanka;
