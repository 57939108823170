import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond11_slide from "./Dimensiond11_slide";

const Tour_DimensionDay11 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond11_slide />
      </div>
      <div className="line">
        <h3>Day 11 : </h3>
        <h2>Belihuloya - Galle [04:00] </h2>
      </div>
      <p>Elephant Transit Home [01:00] </p>
      <p>Walk inside the ramparts of Galle Fort -UNESCO [01:00]</p>
    </div>
  );
};

export default Tour_DimensionDay11;
