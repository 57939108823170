import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/palagama.jpg";
import two from "../assets/images/slhotels/palmyrah.jpg";
import three from "../assets/images/slhotels/foxresort.jpg";
import four from "../assets/images/slhotels/forestrock.jpg";
import five from "../assets/images/slhotels/rosyth.jpg";
import six from "../assets/images/slhotels/whyhouse.jpg";
import seven from "../assets/images/slhotels/galleface.jpg";

const SignatureCollection_Buds = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="Palagama Beach Hotel "
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="The Palmyrah House"
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Signature "
        hotel="Fox Resorts Jaffna "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Signature "
        hotel="Forest Rock Garden "
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Signature "
        hotel="Rosyth Estate House"
      />
      <HotelSL
        image={six}
        color="#d5b23f"
        catName="Signature "
        hotel="Why House"
      />
      <HotelSL
        image={seven}
        color="#d5b23f"
        catName="Signature "
        hotel="Galle Face Hotel"
      />
    </div>
  );
};

export default SignatureCollection_Buds;
