import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Budsd2_slide from "./Budsd2_slide";

const Tour_BudsDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Budsd2_slide />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Kalpitiya - Mannar [02:30]</h2>
      </div>
      <p>Historical Tour in Mannar Island [01:30]</p>
      <p>Day's catch Seafood Dinner </p>
    </div>
  );
};

export default Tour_BudsDay2;
