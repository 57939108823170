import React, { useState } from "react";
import FilterNavbar from "./FilterNavbar";
import "../assets/css/MaldivesOriginal.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Hotels_All from "./Hotels_All";
import Hotels_Deluxe from "./Hotels_Deluxe";
import Popup from "./Popup";
import PopupHotel2 from "./Popup_Hotel2";
import Popup_Cocoon from "./Popup_Cocoon";
import Popup_Dhigali from "./Popup_Dhigali";
import Popup_Hardrock from "./Popup_Hardrock";
import Popup_Kudafushi from "./Popup_Kudafushi";
import Popup_Kurumba from "./Popup_Kurumba";
import Popup_Saii from "./Popup_Saii";
import Popup_Sunsiyam from "./Popup_Sunsiyam";
import Popup_Emerald from "./Popup_Emerald";
import Popup_LTI from "./Popup_LTI";
import Popup_Movenpick from "./Popup_Movenpick";
import Popup_Pullman from "./Popup_Pullman";
import Popup_Seaside from "./Popup_Seaside";
import Popup_Irufushi from "./Popup_Irufushi";
import Popup_Youandme from "./Popup_Youandme";
import Popup_Amilla from "./Popup_Amilla";
import Popup_Dhigu from "./Popup_Dhigu";
import Popup_Baros from "./Popup_Baros";
import Popup_Cocoa from "./Popup_Cocoa";
import Popup_Maalifushi from "./Popup_Maalifushi";
import Popup_Conrad from "./Popup_Conrad";
import Popup_Gili from "./Popup_Gili";
import Popup_Hurawalhi from "./Popup_Hurawalhi";
import Popup_Kandolhu from "./Popup_Kandolhu";
import Popup_Milaidoo from "./Popup_Milaidoo";
import Popup_Niyama from "./Popup_Niyama";
import Popup_Six from "./Popup_Six";
import Popup_Regis from "./Popup_Regis";
import Popup_Vakkaru from "./Popup_Vakkaru";
import Popup_Velessaru from "./Popup_Velessaru";
import Popup_W from "./Popup_W";
import Popup_Patina from "./Popup_Patina";
import Popup_Kudadoo from "./Popup_Kudadoo";
import Popup_Raffles from "./Popup_Raffles";
import Popup_Soneva from "./Popup_Soneva";
import Popup_Jani from "./Popup_Jani";
import Popup_Nautilus from "./Popup_Nautilus";
import Popup_Waldorf from "./Popup_Waldorf";
import Hotels_SuperDeluxe from "./Hotels_SuperDeluxe";
import Hotels_Luxury from "./Hotels_Luxury";
import Hotels_UltraLuxury from "./Hotels_UltraLuxury";

const MaldivesSectionOriginal = ({ calling }) => {
  const [open, setOpen] = useState(false);
  const [openAnantara, setOpenAnantara] = useState(false);
  const [openCocoon, setOpenCocoon] = useState(false);
  const [openDhigali, setOpenDhigali] = useState(false);
  const [openHardrock, setOpenHardrock] = useState(false);
  const [openKudafushi, setOpenKudafushi] = useState(false);
  const [openKurumba, setOpenKurumba] = useState(false);
  const [openSaii, setOpenSaii] = useState(false);
  const [openSunsiyam, setOpenSunsiyam] = useState(false);
  const [openEmerald, setOpenEmerald] = useState(false);
  const [openLTI, setOpenLTI] = useState(false);
  const [openMovenpick, setOpenMovenpick] = useState(false);
  const [openPullman, setOpenPullman] = useState(false);
  const [openSeaside, setOpenSeaside] = useState(false);
  const [openIrufushi, setOpenIrufushi] = useState(false);
  const [openYouandme, setOpenYouandme] = useState(false);
  const [openAmilla, setOpenAmilla] = useState(false);
  const [openDhigu, setOpenDhigu] = useState(false);
  const [openBaros, setOpenBaros] = useState(false);
  const [openCocoa, setOpenCocoa] = useState(false);
  const [openMaalifushi, setOpenMaalifushi] = useState(false);
  const [openConrad, setOpenConrad] = useState(false);
  const [openGili, setOpenGili] = useState(false);
  const [openHurawalhi, setOpenhurawalhi] = useState(false);
  const [openKandolhu, setOpenKandolhu] = useState(false);
  const [openMiladoo, setOpenMiladoo] = useState(false);
  const [openNiyama, setOpenNiyama] = useState(false);
  const [openPatina, setOpenPatina] = useState(false);
  const [openSix, setOpenSix] = useState(false);
  const [openRegis, setOpenRegis] = useState(false);
  const [openVakkaru, setOpenVakkaru] = useState(false);
  const [openVelessaru, setOpenVelessaru] = useState(false);
  const [openW, setOpenW] = useState(false);
  const [openkudadoo, setOpenkudadoo] = useState(false);
  const [openraffles, setOpenraffles] = useState(false);
  const [opensoneva, setOpensoneva] = useState(false);
  const [openjani, setOpenjani] = useState(false);
  const [opennautilus, setOpennautilus] = useState(false);
  const [openwaldorf, setOpenwaldorf] = useState(false);

  const handleOpenkudadoo = () => {
    setOpenkudadoo(true);
  };
  const handleClosekudadoo = () => {
    setOpenkudadoo(false);
  };

  const handleOpenraffles = () => {
    setOpenraffles(true);
  };
  const handleCloseraffles = () => {
    setOpenraffles(false);
  };

  const handleOpensoneva = () => {
    setOpensoneva(true);
  };
  const handleClosesoneva = () => {
    setOpensoneva(false);
  };

  const handleOpenjani = () => {
    setOpenjani(true);
  };
  const handleClosejani = () => {
    setOpenjani(false);
  };

  const handleOpennautilus = () => {
    setOpennautilus(true);
  };
  const handleClosenautilus = () => {
    setOpennautilus(false);
  };

  const handleOpenwalfdorf = () => {
    setOpenwaldorf(true);
  };
  const handleClosewaldorf = () => {
    setOpenwaldorf(false);
  };

  const handleOpenAmilla = () => {
    setOpenAmilla(true);
  };

  const handleCloseAmilla = () => {
    setOpenAmilla(false);
  };
  const handleOpenDhigu = () => {
    setOpenDhigu(true);
  };

  const handleCloseDhigu = () => {
    setOpenDhigu(false);
  };
  const handleOpenBaros = () => {
    setOpenBaros(true);
  };

  const handleCloseBaros = () => {
    setOpenBaros(false);
  };
  const handleOpenCocoa = () => {
    setOpenCocoa(true);
  };

  const handleCloseCocoa = () => {
    setOpenCocoa(false);
  };
  const handleOpenMaalifushi = () => {
    setOpenMaalifushi(true);
  };

  const handleCloseMaalifushi = () => {
    setOpenMaalifushi(false);
  };

  const handleOpenConrad = () => {
    setOpenConrad(true);
  };

  const handleCloseConrad = () => {
    setOpenConrad(false);
  };

  const handleOpenGili = () => {
    setOpenGili(true);
  };

  const handleCloseGili = () => {
    setOpenGili(false);
  };
  const handleOpenhurawalhi = () => {
    setOpenhurawalhi(true);
  };

  const handleCloseHurawalhi = () => {
    setOpenhurawalhi(false);
  };

  const handleOpenkandolhu = () => {
    setOpenKandolhu(true);
  };

  const handleClosekandolhu = () => {
    setOpenKandolhu(false);
  };
  const handleOpenMilaidoo = () => {
    setOpenMiladoo(true);
  };

  const handleCloseMilaidoo = () => {
    setOpenMiladoo(false);
  };
  const handleOpenNiyama = () => {
    setOpenNiyama(true);
  };

  const handleCloseNiyama = () => {
    setOpenNiyama(false);
  };
  const handleOpenPatina = () => {
    setOpenPatina(true);
  };

  const handleClosePatina = () => {
    setOpenPatina(false);
  };

  const handleOpenSix = () => {
    setOpenSix(true);
  };

  const handleCloseSix = () => {
    setOpenSix(false);
  };
  const handleOpenRegis = () => {
    setOpenRegis(true);
  };

  const handleCloseregis = () => {
    setOpenRegis(false);
  };
  const handleOpenvakkaru = () => {
    setOpenVakkaru(true);
  };

  const handleClosevakkaru = () => {
    setOpenVakkaru(false);
  };
  const handleOpenvelessaru = () => {
    setOpenVelessaru(true);
  };

  const handleClosevelessaru = () => {
    setOpenVelessaru(false);
  };
  const handleOpenW = () => {
    setOpenW(true);
  };

  const handleCloseW = () => {
    setOpenW(false);
  };
  const handleOpenEmerald = () => {
    setOpenEmerald(true);
  };

  const handleCloseEmerald = () => {
    setOpenEmerald(false);
  };
  const handleOpenLTI = () => {
    setOpenLTI(true);
  };

  const handleCloseLTI = () => {
    setOpenLTI(false);
  };
  const handleOpenMovenpick = () => {
    setOpenMovenpick(true);
  };

  const handleCloseMocvenpick = () => {
    setOpenMovenpick(false);
  };

  const handleOpenPullman = () => {
    setOpenPullman(true);
  };

  const handleClosePullman = () => {
    setOpenPullman(false);
  };

  const handleOpenSeaside = () => {
    setOpenSeaside(true);
  };

  const handleCloseSeaside = () => {
    setOpenSeaside(false);
  };

  const handleOpenIrufushi = () => {
    setOpenIrufushi(true);
  };

  const handleCloseIrufushi = () => {
    setOpenIrufushi(false);
  };

  const handleOpenYouandme = () => {
    setOpenYouandme(true);
  };

  const handleCloseYouandme = () => {
    setOpenYouandme(false);
  };

  const handleOpenHardrock = () => {
    setOpenHardrock(true);
  };

  const handleCloseHardrock = () => {
    setOpenHardrock(false);
  };

  const handleOpenKurumba = () => {
    setOpenKurumba(true);
  };

  const handleCloseKurumba = () => {
    setOpenKurumba(false);
  };

  const handleOpenKudafushi = () => {
    setOpenKudafushi(true);
  };

  const handleCloseKudafushi = () => {
    setOpenKudafushi(false);
  };
  const handleOpenSaii = () => {
    setOpenSaii(true);
  };

  const handleCloseSaii = () => {
    setOpenSaii(false);
  };

  const handleOpenSunsiyam = () => {
    setOpenSunsiyam(true);
  };

  const handleCloseSunsiyam = () => {
    setOpenSunsiyam(false);
  };

  const handleDhigaliOpen = () => {
    setOpenDhigali(true);
  };

  const handleCloseDhigali = () => {
    setOpenDhigali(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleHotel2Open = () => {
    setOpenAnantara(true);
  };

  const handleHotel2Close = () => {
    setOpenAnantara(false);
  };

  const handleOpenCocoon = () => {
    setOpenCocoon(true);
  };

  const handleCloseCocoon = () => {
    setOpenCocoon(false);
  };

  return (
    <Router>
      <Switch>
        <div id="maldives" className="maldivesSection">
          <h1>M<span>aldives</span></h1>
          <PopupHotel2
            open={openAnantara}
            handleClickOpen={handleClickOpen}
            handleClose={handleHotel2Close}
          />
          <Popup_Waldorf
            open={openwaldorf}
            handleClickOpen={handleOpenwalfdorf}
            handleClose={handleClosewaldorf}
          />
          <Popup_Nautilus
            open={opennautilus}
            handleClickOpen={handleOpennautilus}
            handleClose={handleClosenautilus}
          />
          <Popup_Jani
            open={openjani}
            handleClickOpen={handleOpenjani}
            handleClose={handleClosejani}
          />
          <Popup_Soneva
            open={opensoneva}
            handleClickOpen={handleOpensoneva}
            handleClose={handleClosesoneva}
          />
          <Popup_Raffles
            open={openraffles}
            handleClickOpen={handleOpenraffles}
            handleClose={handleCloseraffles}
          />
          <Popup_Kudadoo
            open={openkudadoo}
            handleClickOpen={handleOpenkudadoo}
            handleClose={handleClosekudadoo}
          />
          <Popup_W
            open={openW}
            handleClickOpen={handleOpenW}
            handleClose={handleCloseW}
          />
          <Popup_Velessaru
            open={openVelessaru}
            handleClickOpen={handleOpenvelessaru}
            handleClose={handleClosevelessaru}
          />
          <Popup_Vakkaru
            open={openVakkaru}
            handleClickOpen={handleOpenvakkaru}
            handleClose={handleClosevakkaru}
          />
          <Popup_Regis
            open={openRegis}
            handleClickOpen={handleOpenRegis}
            handleClose={handleCloseregis}
          />
          <Popup_Six
            open={openSix}
            handleClickOpen={handleOpenSix}
            handleClose={handleCloseSix}
          />
          <Popup_Patina
            open={openPatina}
            handleClickOpen={handleOpenPatina}
            handleClose={handleClosePatina}
          />
          <Popup_Niyama
            open={openNiyama}
            handleClickOpen={handleOpenNiyama}
            handleClose={handleCloseNiyama}
          />
          <Popup_Milaidoo
            open={openMiladoo}
            handleClickOpen={handleOpenMilaidoo}
            handleClose={handleCloseMilaidoo}
          />
          <Popup_Kandolhu
            open={openKandolhu}
            handleClickOpen={handleOpenkandolhu}
            handleClose={handleClosekandolhu}
          />
          <Popup_Hurawalhi
            open={openHurawalhi}
            handleClickOpen={handleOpenhurawalhi}
            handleClose={handleCloseHurawalhi}
          />
          <Popup_Gili
            open={openGili}
            handleClickOpen={handleOpenGili}
            handleClose={handleCloseGili}
          />
          <Popup_Conrad
            open={openConrad}
            handleClickOpen={handleOpenConrad}
            handleClose={handleCloseConrad}
          />
          <Popup_Maalifushi
            open={openMaalifushi}
            handleClickOpen={handleOpenMaalifushi}
            handleClose={handleCloseMaalifushi}
          />
          <Popup_Cocoa
            open={openCocoa}
            handleClickOpen={handleOpenCocoa}
            handleClose={handleCloseCocoa}
          />
          <Popup_Baros
            open={openBaros}
            handleClickOpen={handleOpenBaros}
            handleClose={handleCloseBaros}
          />
          <Popup_Dhigu
            open={openDhigu}
            handleClickOpen={handleOpenDhigu}
            handleClose={handleCloseDhigu}
          />
          <Popup_Amilla
            open={openAmilla}
            handleClickOpen={handleOpenAmilla}
            handleClose={handleCloseAmilla}
          />
          <Popup_Youandme
            open={openYouandme}
            handleClickOpen={handleOpenYouandme}
            handleClose={handleCloseYouandme}
          />
          <Popup_Irufushi
            open={openIrufushi}
            handleClickOpen={handleOpenIrufushi}
            handleClose={handleCloseIrufushi}
          />
          <Popup_Seaside
            open={openSeaside}
            handleClickOpen={handleOpenSeaside}
            handleClose={handleCloseSeaside}
          />
          <Popup_Pullman
            open={openPullman}
            handleClickOpen={handleOpenPullman}
            handleClose={handleClosePullman}
          />
          <Popup_Movenpick
            open={openMovenpick}
            handleClickOpen={handleOpenMovenpick}
            handleClose={handleCloseMocvenpick}
          />
          <Popup_LTI
            open={openLTI}
            handleClickOpen={handleOpenLTI}
            handleClose={handleCloseLTI}
          />
          <Popup_Emerald
            open={openEmerald}
            handleClickOpen={handleOpenEmerald}
            handleClose={handleCloseEmerald}
          />
          <Popup_Sunsiyam
            open={openSunsiyam}
            handleClickOpen={handleOpenSunsiyam}
            handleClose={handleCloseSunsiyam}
          />
          <Popup_Saii
            open={openSaii}
            handleClickOpen={handleOpenSaii}
            handleClose={handleCloseSaii}
          />
          <Popup_Kurumba
            open={openKurumba}
            handleClickOpen={handleOpenKurumba}
            handleClose={handleCloseKurumba}
          />
          <Popup_Kudafushi
            open={openKudafushi}
            handleClickOpen={handleOpenKudafushi}
            handleClose={handleCloseKudafushi}
          />
          <Popup_Hardrock
            open={openHardrock}
            handleClickOpen={handleOpenHardrock}
            handleClose={handleCloseHardrock}
          />
          <Popup_Dhigali
            open={openDhigali}
            handleClickOpen={handleDhigaliOpen}
            handleClose={handleCloseDhigali}
          />
          <Popup_Cocoon
            open={openCocoon}
            handleClickOpen={handleOpenCocoon}
            handleClose={handleCloseCocoon}
          />
          <Popup
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />

          <div className="contentContainer">
            <FilterNavbar />
            {/* <Route
              path="/"
              exact
              render={() => (
                <Hotels_All
                  AnantaraPopup={handleClickOpen}
                  CocoonPopup={handleOpenCocoon}
                  DhigaliPopup={handleDhigaliOpen}
                  HardRockPopup={handleOpenHardrock}
                  KudafushiPopup={handleOpenKudafushi}
                  KurumbaPopup={handleOpenKurumba}
                  SaiiPopup={handleOpenSaii}
                  SunsiyamPopup={handleOpenSunsiyam}
                  EmeraldPopup={handleOpenEmerald}
                  LTIPopup={handleOpenLTI}
                  MovenpickPopup={handleOpenMovenpick}
                  PullmanPopup={handleOpenPullman}
                  FinohluPopup={handleOpenSeaside}
                  IrufushiPopup={handleOpenIrufushi}
                  YouandmePopup={handleOpenYouandme}
                  AmillaPopup={handleOpenAmilla}
                  AnantaraDhiguPopup={handleOpenDhigu}
                  BarosPopup={handleOpenBaros}
                  COMOPopup={handleOpenCocoa}
                  MaalifushiPopup={handleOpenMaalifushi}
                  ConradPopup={handleOpenConrad}
                  GiliPopup={handleOpenGili}
                  HuraPopup={handleOpenhurawalhi}
                  KandolhuPopup={handleOpenkandolhu}
                  MilaiPopup={handleOpenMilaidoo}
                  NiyamaPopup={handleOpenNiyama}
                  PatinaPopup={handleOpenPatina}
                  SixPopup={handleOpenSix}
                  RegisPopup={handleOpenRegis}
                  VakkaruPopup={handleOpenvakkaru}
                  VelessaruPopup={handleOpenvelessaru}
                  WPopup={handleOpenW}
                  KudadooPopup={handleOpenkudadoo}
                  RafflesPopup={handleOpenraffles}
                  SonevaPopup={handleOpensoneva}
                  SonevajaniPopup={handleOpenjani}
                  NautilusPopup={handleOpennautilus}
                  WaldorfPopup={handleOpenwalfdorf}
                />
              )}
            /> */}
            <Route
              path="/"
              exact
              render={() => (
                <Hotels_Deluxe
                  CocoonPopup={handleOpenCocoon}
                  AnantaraPopup={handleClickOpen}
                  DhigaliPopup={handleDhigaliOpen}
                  HardRockPopup={handleOpenHardrock}
                  KudafushiPopup={handleOpenKudafushi}
                  KurumbaPopup={handleOpenKurumba}
                  SaiiPopup={handleOpenSaii}
                  SunsiyamPopup={handleOpenSunsiyam}
                />
              )}
            />
            <Route
              path="/maldives/superdeluxe"
              exact
              render={() => (
                <Hotels_SuperDeluxe
                  EmeraldPopup={handleOpenEmerald}
                  LTIPopup={handleOpenLTI}
                  MovenpickPopup={handleOpenMovenpick}
                  PullmanPopup={handleOpenPullman}
                  FinohluPopup={handleOpenSeaside}
                  IrufushiPopup={handleOpenIrufushi}
                  YouandmePopup={handleOpenYouandme}
                />
              )}
            />
            <Route
              path="/maldives/luxury"
              exact
              render={() => (
                <Hotels_Luxury
                  AmillaPopup={handleOpenAmilla}
                  AnantaraDhiguPopup={handleOpenDhigu}
                  BarosPopup={handleOpenBaros}
                  COMOPopup={handleOpenCocoa}
                  MaalifushiPopup={handleOpenMaalifushi}
                  ConradPopup={handleOpenConrad}
                  GiliPopup={handleOpenGili}
                  HuraPopup={handleOpenhurawalhi}
                  KandolhuPopup={handleOpenkandolhu}
                  MilaiPopup={handleOpenMilaidoo}
                  NiyamaPopup={handleOpenNiyama}
                  PatinaPopup={handleOpenPatina}
                  SixPopup={handleOpenSix}
                  RegisPopup={handleOpenRegis}
                  VakkaruPopup={handleOpenvakkaru}
                  VelessaruPopup={handleOpenvelessaru}
                  WPopup={handleOpenW}
                />
              )}
            />
            <Route
              path="/maldives/ultraluxury"
              exact
              render={() => (
                <Hotels_UltraLuxury
                  KudadooPopup={handleOpenkudadoo}
                  RafflesPopup={handleOpenraffles}
                  SonevaPopup={handleOpensoneva}
                  SonevajaniPopup={handleOpenjani}
                  NautilusPopup={handleOpennautilus}
                  WaldorfPopup={handleOpenwalfdorf}
                />
              )}
            />
          </div>
        </div>
      </Switch>
    </Router>
  );
};

export default MaldivesSectionOriginal;
