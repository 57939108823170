import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/tvlakelodge.jpg";
import two from "../assets/images/slhotels/wildgrass.jpg";
import three from "../assets/images/slhotels/theva.jpg";
import four from "../assets/images/slhotels/98acres.jpg";
import five from "../assets/images/slhotels/ceylonwild.jpg";
import six from "../assets/images/slhotels/gallefortt.jpg";

const DeluxeCollection_Postcard = () => {
  return (
    <div className="collection">
      <h3>Deluxe Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Taru Villas - Lake Lodge"
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Wild Grass Nature Resort  "
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Theva Residency "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Deluxe "
        hotel="98 Acres Resort & Spa "
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Ceylon Wild Safaris (AITC)"
      />
      <HotelSL
        image={six}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Galle Fort Hotel "
      />
    </div>
  );
};

export default DeluxeCollection_Postcard;
