import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/whyhouse.jpg";
import two from "../assets/images/slhotels/heritage.jpg";
import three from "../assets/images/slhotels/galoyalodge.jpg";
import four from "../assets/images/slhotels/ulagalla.jpg";
import five from "../assets/images/slhotels/wallawwa.jpg";

const SignatureCollection_Ignite = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="Why House"
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="Living Heritage Koslanda (ECO)"
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Signature "
        hotel="Gal Oya Lodge (ECO) "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Signature "
        hotel="Ulagalla by Uga"
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Signature "
        hotel="The Wallawwa "
      />
    </div>
  );
};

export default SignatureCollection_Ignite;
