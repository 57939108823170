import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import Getawayd6_slide from "./Getawayd6_slide";

const Tour_GetawayDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Getawayd6_slide />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Kandy- Nuwara Eliya [02:00] - Ella [03:00]</h2>
      </div>
      <p>Visit a tea factory in the tea country [00:40]</p>
      <p>Scenic train journey from Nuwara Eliya to Ella [03:00]</p>
    </div>
  );
};

export default Tour_GetawayDay6;
