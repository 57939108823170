import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond9_slide from "./Immersiond9_slide";

const Tour_immersionDay9 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond9_slide />
      </div>
      <div className="line">
        <h3>Day 09 : </h3>
        <h2>Sigiriya - Polonnaruwa [01:30] - Sigiriya</h2>
      </div>
      <p>Cycle through the Ancient Kingdom of Polonnaruwa-UNESCO [02:30]</p>
      <p>Afternoon Jeep Safari in Minneriya National Park [03:00]</p>
    </div>
  );
};

export default Tour_immersionDay9;
