import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Wildlifed6_slide from "./Wildlifed6_slide";

const Tour_WildlifeDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Wildlifed6_slide />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Yala National Park - Gal Oya National Park [03:00]</h2>
      </div>
      <p>A walk with the Veddas, Sri Lanka’s indigenous people [03:00]</p>
    </div>
  );
};

export default Tour_WildlifeDay6;
