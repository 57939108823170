import React from 'react'
import { Link } from 'react-router-dom'
import "../assets/css/CollectionNav.css"
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';


const CollectionNav = ({ targetStandard, targetSuperior, targetDeluxe, targetLuxury, standardPrice, superiorPrice, deluxePrice, luxuryPrice }) => {
    return (
        <div className="collectionNav">
                    <Link  to={targetStandard} className="linkx" >Standard Collection <span>${standardPrice}<ArrowUpwardIcon className="icon" /></span></Link>
                    <Link   to={targetSuperior}  className="linkx" > Superior Collection<span>${superiorPrice}<ArrowUpwardIcon className="icon" /></span> </Link>
                    <Link  to={targetDeluxe}  className="linkx" >Deluxe Collection <span>${deluxePrice}<ArrowUpwardIcon className="icon" /></span></Link>
                    <Link  to={targetLuxury}  className="linkx" >Luxury Collection <span>${luxuryPrice}<ArrowUpwardIcon className="icon" /></span></Link>
        </div>
    )
}

export default CollectionNav
