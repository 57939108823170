import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../assets/css/SlickSlider.css";
import slick1 from "../assets/images/SlickSlider/newimages/history.png";
import slick2 from "../assets/images/SlickSlider/newimages/islandromance.png";
import slick3 from "../assets/images/SlickSlider/newimages/postcard.png";
import slick4 from "../assets/images/SlickSlider/newimages/immersion.png";
import slick5 from "../assets/images/SlickSlider/newimages/getaway.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

function Arrow(props) {
  let className = props.type === "next" ? "nextArrow" : "prevArrow";
  className += " arrow";
  const char =
    props.type === "next" ? (
      <ArrowForwardIosIcon style={{ marginLeft: "-5px" }} />
    ) : (
      <ArrowBackIosIcon style={{ marginRight: "5px" }} />
    );
  return (
    <span
      style={{ cursor: "pointer", color: "#008080" }}
      className={className}
      onClick={props.onClick}
    >
      {char}
    </span>
  );
}

const SlickSlider = () => {
  var settings = {
    dots: false,
    infinite: true,

    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Slider
      nextArrow={<Arrow type="next" />}
      prevArrow={<Arrow type="prev" />}
      {...settings}
      className="total"
    >
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick1} />
        </div>
        <h2>Relive the History & Heritage</h2>
        <div className="overlay">
          <a href="/historyandheritage" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick2} />
        </div>
        <h2>Island Romance</h2>
        <div className="overlay">
          <a href="/islandromance" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick3} />
        </div>
        <h2>Escape to Postcard Pictures</h2>
        <div className="overlay">
          <a href="/escapetopostcard" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick4} />
        </div>
        <h2>Immersion into Wilderness</h2>
        <div className="overlay">
          <a href="/immersionintowild" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick5} />
        </div>
        <h2>Family Getaway</h2>
        <div className="overlay">
          <a href="/familygetaway" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
    </Slider>
  );
};

export default SlickSlider;
