import React from "react";
import "../assets/css/footer.css";
import footerimg from "../assets/images/footerimg2.png";
import logo from "../assets/images/logo2.png";
import { Link } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PinterestIcon from "@material-ui/icons/Pinterest";

const Footer = () => {
  return (
    <footer className="footerHome" id="reachus">
      <div className="tophalf">
        <div className="main">
          <div className="icons">
            <h3>Follow Us</h3>
            <div className="iconsContainer">
              <a
                href="https://www.facebook.com/silantravels/?fref=ts "
                target="_Blank"
              >
                <FacebookIcon className="icon" />
              </a>
              <a href="https://www.instagram.com/silantravels " target="_Blank">
                <InstagramIcon className="icon" />
              </a>
              <a
                href="https://www.pinterest.com/silantravels/_saved/  "
                target="_Blank"
              >
                <PinterestIcon className="icon" />
              </a>
            </div>
          </div>
          <div className="contentSection">
            <h1>
              Reach <span>Us</span>{" "}
            </h1>
            <h4>enquiries@silantravels.com | +94 778 583 284 </h4>
          </div>

          <div className="newsletter">
            <h3>Sign Up to Our Newsletter</h3>
            <form
              action="https://formsubmit.co/enquiries@silantravels.com"
              method="POST"
            >
              <input
                type="hidden"
                name="_subject"
                value="Newsletter Subscription!"
              />
              <input
                className="input"
                placeholder="Email"
                name="Newsletter Subcriber"
                type="email"
                required
              />
              <input type="submit" className="button" />
            </form>
          </div>
        </div>
        <div className="solutionSection"></div>
      </div>
      <div id="bottomNewCont">
        <h3 id="leftSolution">© 2021 Silan Travels. All rights reserved.</h3>
        <h3 id="rightSolution">
          Solution by
          <a href="https://lunarscript.com" target="_blank">
            {" "}
            LunarScript
          </a>
        </h3>
      </div>
    </footer>
  );
};

export default Footer;
