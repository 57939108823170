import React from "react";

import "../assets/css/Tour_Destinations.css";
import Destinations14_slide from "./Destinations14_slide";

import Destinations1_slide from "./Destinations1_slide";

const Destinations14 = () => {
  return (
    <div className="tour_Destinations">
      <div className="slides">
        <Destinations14_slide />
      </div>
      {/* <div className="line"></div> */}
    </div>
  );
};

export default Destinations14;
