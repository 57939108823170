import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Essenced9_slide from "./Essenced9_slide";

const Tour_EssenceDay9 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Essenced9_slide />
      </div>
      <div className="line">
        <h3>Day 09 : </h3>
        <h2>Sigiriya - Polonnaruwa [01:30] - Sigiriya </h2>
      </div>
      <p>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</p>
      <p>Visit the Ancient Kingdom of Polonnaruwa-UNESCO [02:30]</p>
    </div>
  );
};

export default Tour_EssenceDay9;
