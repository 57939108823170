import React from "react";

import "../assets/css/Tour_Destinations.css";

import Destinations1_slide from "./Destinations1_slide";
import Destinations3_slide from "./Destinations3_slide";

const Destinations3 = () => {
  return (
    <div className="tour_Destinations">
      <div className="slides">
        <Destinations3_slide />
      </div>
      {/* <div className="line"></div> */}
    </div>
  );
};

export default Destinations3;
