import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Ignited4_slide from "./Ignited4_slide";

const Tour_IgniteDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited4_slide />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Haputale</h2>
      </div>
      <p>Leisure</p>
    </div>
  );
};

export default Tour_IgniteDay4;
