import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import anantaraVeli from "../assets/images/Maldives/sampeimg.jpg";
import Cocoon from "../assets/images/Maldives/sampeimg.jpg";
import Dhigali from "../assets/images/Maldives/sampeimg.jpg";
import HardRock from "../assets/images/Maldives/sampeimg.jpg";
import Kudafushi from "../assets/images/Maldives/sampeimg.jpg";
import Kurumba from "../assets/images/Maldives/sampeimg.jpg";
import SaiiLagoon from "../assets/images/Maldives/sampeimg.jpg";
import SunSiyam from "../assets/images/Maldives/sampeimg.jpg";
import Emereld from "../assets/images/Maldives/sampeimg.jpg";
import lti from "../assets/images/Maldives/sampeimg.jpg";
import Movenpick from "../assets/images/Maldives/sampeimg.jpg";
import Pullman from "../assets/images/Maldives/sampeimg.jpg";
import Seaside from "../assets/images/Maldives/sampeimg.jpg";
import IruFushi from "../assets/images/Maldives/sampeimg.jpg";
import YouandMe from "../assets/images/Maldives/sampeimg.jpg";
import Amilla from "../assets/images/Maldives/sampeimg.jpg";
import Dhigu from "../assets/images/Maldives/sampeimg.jpg";
import Baros from "../assets/images/Maldives/sampeimg.jpg";
import Como from "../assets/images/Maldives/sampeimg.jpg";
import ComoMaali from "../assets/images/Maldives/sampeimg.jpg";
import Gili from "../assets/images/Maldives/sampeimg.jpg";
import Huruwalhi from "../assets/images/Maldives/sampeimg.jpg";
import Kandolhu from "../assets/images/Maldives/sampeimg.jpg";
import Milaidhoo from "../assets/images/Maldives/sampeimg.jpg";
import Niyama from "../assets/images/Maldives/sampeimg.jpg";
import Patina from "../assets/images/Maldives/sampeimg.jpg";
import SixSenses from "../assets/images/Maldives/sampeimg.jpg";
import Vommuli from "../assets/images/Maldives/sampeimg.jpg";
import Vakkaru from "../assets/images/Maldives/sampeimg.jpg";
import Velessaru from "../assets/images/Maldives/sampeimg.jpg";
import WMaldives from "../assets/images/Maldives/sampeimg.jpg";
import Kudadoo from "../assets/images/Maldives/sampeimg.jpg";
import Raffles from "../assets/images/Maldives/sampeimg.jpg";
import Sonevafushi from "../assets/images/Maldives/sampeimg.jpg";
import SonevaJani from "../assets/images/Maldives/sampeimg.jpg";
import Nautilus from "../assets/images/Maldives/sampeimg.jpg";
import Waldorf from "../assets/images/Maldives/sampeimg.jpg";
import Conrad from "../assets/images/Maldives/sampeimg.jpg";

import { styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import image from "../assets/images/image.png";
import "../assets/css/Pop.css";
import MaldivesSectionOriginal from "./MaldivesSectionOriginal";
const Popup_Hurawalhi = ({ open, handleClickOpen, handleClose }) => {
  //   const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [year, setYear] = useState([]);
  const [month, setMonth] = useState([]);
  const [adults, setAdults] = useState([]);
  const [children, setChildren] = useState([]);
  const [nights, setNights] = useState([]);

  const nightsData = [
    {
      number: "1",
    },
    {
      number: "2",
    },
    {
      number: "3",
    },
    {
      number: "4",
    },
    {
      number: "5",
    },
    {
      number: "6",
    },
    {
      number: "7",
    },
    {
      number: "8",
    },
    {
      number: "9",
    },
    {
      number: "10",
    },
    {
      number: "11",
    },
    {
      number: "12",
    },
    {
      number: "13",
    },
    {
      number: "14",
    },
    {
      number: "15",
    },
    {
      number: "16",
    },
    {
      number: "17",
    },
    {
      number: "18",
    },
    {
      number: "19",
    },
    {
      number: "20",
    },
  ];

  const childrenData = [
    {
      number: "1",
    },
    {
      number: "2",
    },
    {
      number: "3",
    },
    {
      number: "4",
    },
    {
      number: "5",
    },
    {
      number: "6",
    },
    {
      number: "7",
    },
    {
      number: "8",
    },
    {
      number: "9",
    },
    {
      number: "10",
    },
    {
      number: "11",
    },
    {
      number: "12",
    },
  ];

  const adultsData = [
    {
      number: "1",
    },
    {
      number: "2",
    },
    {
      number: "3",
    },
    {
      number: "4",
    },
    {
      number: "5",
    },
    {
      number: "6",
    },
    {
      number: "7",
    },
    {
      number: "8",
    },
    {
      number: "9",
    },
    {
      number: "10",
    },
  ];

  const monthData = [
    {
      month: "January",
    },
    {
      month: "February",
    },
    {
      month: "March",
    },
    {
      month: "April",
    },
    {
      month: "May",
    },
    {
      month: "June",
    },
    {
      month: "July",
    },
    {
      month: "August",
    },
    {
      month: "September",
    },
    {
      month: "October",
    },
    {
      month: "November",
    },
    {
      month: "December",
    },
  ];

  const yearData = [
    {
      year: "2021",
    },
    {
      year: "2022",
    },
    {
      year: "2023",
    },
    {
      year: "2024",
    },
    {
      year: "2025",
    },
  ];

  const data = [
    {
      id: 1,
      image: anantaraVeli,
      title: "Anantara Veli",
      category: "Deluxe",
    },
    {
      id: 2,
      image: Cocoon,
      title: "Cocoon Maldives",
      category: "Deluxe",
    },
    {
      id: 3,
      image: Dhigali,
      title: "Dhigali Maldives",
      category: "Deluxe",
    },
    {
      id: 4,
      image: HardRock,
      title: "Hard Rock Hotel",
      category: "Deluxe",
    },
    {
      id: 5,
      image: Kudafushi,
      title: "Kudafushi Resort & Spa",
      category: "Deluxe",
    },
    {
      id: 6,
      image: Kurumba,
      title: "Kurumba Maldives",
      category: "Deluxe",
    },
    {
      id: 7,
      image: SaiiLagoon,
      title: "SAii Laggon, Curio Collection by Hilton",
      category: "Deluxe",
    },
    {
      id: 8,
      image: SunSiyam,
      title: "Sun Siyam Vilu Reef",
      category: "Deluxe",
    },
    {
      id: 9,
      image: Emereld,
      title: "Emerald Maldives",
      category: "SuperDeluxe",
    },
    {
      id: 10,
      image: lti,
      title: "LTI Maafushivaru",
      category: "SuperDeluxe",
    },
    {
      id: 11,
      image: Movenpick,
      title: "Movenpick Resort Kuredhivaru",
      category: "SuperDeluxe",
    },
    {
      id: 12,
      image: Pullman,
      title: "Pullman Maldives Maamutaa",
      category: "SuperDeluxe",
    },
    {
      id: 13,
      image: Seaside,
      title: "Seaside Finolhu",
      category: "SuperDeluxe",
    },
    {
      id: 14,
      image: IruFushi,
      title: "Sun Siyam Iru Fushi",
      category: "SuperDeluxe",
    },
    {
      id: 15,
      image: YouandMe,
      title: "You & Me Maldives",
      category: "SuperDeluxe",
    },
    {
      id: 16,
      image: Amilla,
      title: "Amilla Fushi",
      category: "Luxury",
    },
    {
      id: 17,
      image: Dhigu,
      title: "Anantara Dhigu",
      category: "Luxury",
    },
    {
      id: 18,
      image: Baros,
      title: "Baros Maldives",
      category: "Luxury",
    },
    {
      id: 19,
      image: Como,
      title: "COMO Cocoa Island",
      category: "Luxury",
    },
    {
      id: 20,
      image: ComoMaali,
      title: "COMO Maalifushi",
      category: "Luxury",
    },
    {
      id: 21,
      image: Conrad,
      title: "Conrad Maldives",
      category: "Luxury",
    },
    {
      id: 22,
      image: Gili,
      title: "Gili Lankanfushi",
      category: "Luxury",
    },
    {
      id: 23,
      image: Huruwalhi,
      title: "Hurawalhi Island",
      category: "Luxury",
    },
    {
      id: 24,
      image: Kandolhu,
      title: "Kandolhu Maldives",
      category: "Luxury",
    },
    {
      id: 25,
      image: Milaidhoo,
      title: "Milaidhoo Island",
      category: "Luxury",
    },
    {
      id: 26,
      image: Niyama,
      title: "Niyama Private Islands",
      category: "Luxury",
    },
    {
      id: 27,
      image: Patina,
      title: "Patina Maldives",
      category: "Luxury",
    },
    {
      id: 28,
      image: SixSenses,
      title: "Six Senses Laamu",
      category: "Luxury",
    },
    {
      id: 29,
      image: Vommuli,
      title: "The St. Regis Maldives Vommuli Resort",
      category: "Luxury",
    },
    {
      id: 30,
      image: Vakkaru,
      title: "Vakkaru Maldives",
      category: "Luxury",
    },
    {
      id: 31,
      image: Velessaru,
      title: "Velessaru Maldives",
      category: "Luxury",
    },
    {
      id: 32,
      image: WMaldives,
      title: "W Maldives",
      category: "Luxury",
    },
    {
      id: 33,
      image: Kudadoo,
      title: "Kudadoo Private Island",
      category: "Ultra Luxury",
    },
    {
      id: 34,
      image: Raffles,
      title: "Raffles Maldives Meradhoo",
      category: "Ultra Luxury",
    },
    {
      id: 35,
      image: Sonevafushi,
      title: "Soneva Fushi",
      category: "Ultra Luxury",
    },
    {
      id: 36,
      image: SonevaJani,
      title: "Soneva Jani",
      category: "Ultra Luxury",
    },
    {
      id: 37,
      image: Nautilus,
      title: "The Nautilus Maldives",
      category: "Ultra Luxury",
    },
    {
      id: 38,
      image: Waldorf,
      title: "Waldorf Astoria Maldives Ithaafushi",
      category: "Ultra Luxury",
    },
  ];

  const selectedData = [
    {
      id: 23,
      image: Huruwalhi,
      title: "Hurawalhi Island",
      category: "Luxury",
    },
  ];

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <div>
      {/* <button onClick={handleClickOpen}>Open form dialog</button> */}

      <Dialog
        className="MainContainer"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="wrapper">
          <div className="left">
            <h2 className="title">Enquire Now</h2>

            <img className="image" src={image} width="250px" alt="" />
          </div>
          <div className="right">
            <form
              action="https://formsubmit.co/enquiries@silantravels.com"
              method="POST"
            >
              <input
                type="hidden"
                name="_subject"
                value="Maldives Submission"
              />
              <input
                className="input"
                name="Customer Name"
                type="text"
                placeholder="Name"
                required
              />
              <input
                className="input"
                name="email"
                type="email"
                placeholder="Email"
                required
              />
              <Multiselect
                style={{
                  searchBox: {
                    border: "none",
                    backgroundColor: "#f3f3f3",
                    width: "94%",
                  },
                }}
                placeholder="Select Hotels (Min: 2)"
                type="text"
                groupBy="category"
                // value={data.filter((obj) => selectedValue.includes(obj.cat))}
                // onChange={handlChange}
                displayValue="title"
                onSelect={(e) => {
                  setSelectedValue(
                    Array.isArray(e) ? e.map((x) => x.title) : ""
                  );
                }}
                onRemove={(e) => {
                  setSelectedValue(
                    Array.isArray(e) ? e.map((x) => x.title) : ""
                  );
                }}
                selectionLimit={4}
                avoidHighlightFirstOption={true}
                options={data}
                selectedValues={selectedData}
              />
              {selectedValue && (
                <div style={{ marginBottom: "10px" }}>
                  <input
                    style={{ display: "none" }}
                    name="Hotels Selected"
                    value={JSON.stringify(selectedValue)}
                  ></input>
                </div>
              )}
              <input
                className="input"
                name="Nationality"
                type="text"
                placeholder="Nationality"
                required
              />
              <div style={{ marginBottom: "10px" }}>
                <Multiselect
                  style={{
                    searchBox: {
                      border: "none",
                      backgroundColor: "#f3f3f3",
                      width: "94%",
                    },
                  }}
                  options={adultsData}
                  displayValue="number"
                  onSelect={(e) => {
                    setAdults(Array.isArray(e) ? e.map((x) => x.number) : "");
                  }}
                  onRemove={(e) => {
                    setAdults(Array.isArray(e) ? e.map((x) => x.number) : "");
                  }}
                  singleSelect={true}
                  placeholder="Number of Adults"
                  avoidHighlightFirstOption={true}
                />
                {setAdults && (
                  <input
                    style={{ display: "none" }}
                    name="Number of Adults"
                    value={JSON.stringify(adults)}
                  />
                )}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Multiselect
                  style={{
                    searchBox: {
                      border: "none",
                      backgroundColor: "#f3f3f3",
                      width: "94%",
                    },
                  }}
                  placeholder="Number of Children"
                  displayValue="number"
                  options={childrenData}
                  onSelect={(e) => {
                    setChildren(Array.isArray(e) ? e.map((x) => x.number) : "");
                  }}
                  onRemove={(e) => {
                    setChildren(Array.isArray(e) ? e.map((x) => x.number) : "");
                  }}
                  avoidHighlightFirstOption={true}
                  singleSelect={true}
                />
                {children && (
                  <input
                    name="Number of Children"
                    style={{ display: "none" }}
                    value={JSON.stringify(children)}
                  />
                )}
              </div>
              <input
                name="Date"
                type="text"
                onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                placeholder="Arrival Date"
                className="input"
                required
              />
              {/* <div className="yearDiv">
                <div className="year">
                  <Multiselect
                    style={{
                      searchBox: {
                        border: "none",
                        backgroundColor: "#f3f3f3",
                        width: "94%",
                        marginBottom: "10px"
                      }
                    }}
                    onSelect={(e) => {
                      setYear(Array.isArray(e) ? e.map((x) => x.year) : "");
                    }}
                    onRemove={(e) => {
                      setYear(Array.isArray(e) ? e.map((x) => x.year) : "");
                    }}
                    placeholder="Year"
                    displayValue="year"
                    options={yearData}
                    singleSelect={true}
                    avoidHighlightFirstOption={true}
                  />
                  {year && (
                    <input
                      style={{ display: "none" }}
                      name="Year"
                      value={JSON.stringify(year)}
                    />
                  )}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <Multiselect
                    style={{
                      searchBox: {
                        width: "94%",
                        border: "none",
                        backgroundColor: "#f3f3f3"
                      }
                    }}
                    options={monthData}
                    displayValue="month"
                    singleSelect={true}
                    avoidHighlightFirstOption={true}
                    placeholder="Month"
                    onSelect={(e) => {
                      setMonth(Array.isArray(e) ? e.map((x) => x.month) : "");
                    }}
                    onRemove={(e) => {
                      setMonth(Array.isArray(e) ? e.map((x) => x.month) : "");
                    }}
                  />
                  {month && (
                    <input
                      style={{ display: "none" }}
                      name="Month"
                      value={JSON.stringify(month)}
                    />
                  )}
                </div>
              </div>
              <input
                className="input"
                type="number"
                style={{ padding: "7px" }}
                placeholder="Date"
                name="Date"
                max={31}
              /> */}
              <div style={{ marginBottom: "10px" }}>
                <Multiselect
                  style={{
                    searchBox: {
                      border: "none",
                      backgroundColor: "#f3f3f3",
                      width: "94%",
                    },
                  }}
                  placeholder="Number of Nights"
                  displayValue="number"
                  options={nightsData}
                  onSelect={(e) => {
                    setNights(Array.isArray(e) ? e.map((x) => x.number) : "");
                  }}
                  onRemove={(e) => {
                    setNights(Array.isArray(e) ? e.map((x) => x.number) : "");
                  }}
                  avoidHighlightFirstOption={true}
                  singleSelect={true}
                />
                {nights && (
                  <input
                    name="Number of Nights"
                    style={{ display: "none" }}
                    value={JSON.stringify(nights)}
                  />
                )}
              </div>
              {/* <input
                type="text"
                className="input"
                name="Tour Customizarion"
                placeholder="Customize Your Tour"
              /> */}
              <textarea
                className="input"
                name="Tour Customizarion"
                placeholder="Customize Your Tour"
              />
              <div className="buttons">
                <input type="submit" className="theButton" />
                <button onClick={handleClose} className="theButton">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Popup_Hurawalhi;
