import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Traveld9_slide from "./Traveld9_slide";

const Tour_TravelDay9 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Traveld9_slide />
      </div>
      <div className="line">
        <h3>Day 09 : </h3>
        <h2>Galle - CMB Airport [02:30]</h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_TravelDay9;
