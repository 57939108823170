import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/pledgescape.jpg";
import two from "../assets/images/slhotels/wildgrass.jpg";
import three from "../assets/images/slhotels/bougainvillea.jpg";
import four from "../assets/images/slhotels/secretella.jpg";
import five from "../assets/images/slhotels/srisharavi.jpg";

const DeluxeCollection_Getaway = () => {
  return (
    <div className="collection">
      <h3>Deluxe Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Pledge Scape Negombo "
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Wild Grass Nature Resort  "
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Bougainvillea Retreat "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Deluxe "
        hotel="The Secret Ella "
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Sri Sharavi Beach Villas & Spa "
      />
    </div>
  );
};

export default DeluxeCollection_Getaway;
