import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd8_slide from "./Serenityd8_slide";

const Tour_SerenityDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Hatton </h2>
      </div>
      <p>Visit a tea factory in the tea country [00:40]</p>
      <p>Tea Infused Dinner</p>
    </div>
  );
};

export default Tour_SerenityDay8;
