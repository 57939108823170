import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/rainforesteco.jpg";
import two from "../assets/images/slhotels/flameback.jpg";
import three from "../assets/images/slhotels/ekho.jpg";
import four from "../assets/images/slhotels/radh.jpg";
import five from "../assets/images/slhotels/backofbeyond.jpg";
import six from "../assets/images/slhotels/backanura.jpg";
import seven from "../assets/images/slhotels/villahundria.jpg";

const SuperiorCollection_Immersion = () => {
  return (
    <div className="collection">
      <h3>Superior Collection</h3>
      <HotelSL
        image={one}
        color="#ddbf61"
        catName="Superior "
        hotel="Rainforest Edge  (ECO)"
      />
      <HotelSL
        image={two}
        color="#ddbf61"
        catName="Superior "
        hotel="Flameback Eco Lodge (ECO)"
      />
      <HotelSL
        image={three}
        color="#ddbf61"
        catName="Superior "
        hotel="EKHO Ella"
      />
      <HotelSL
        image={four}
        color="#ddbf61"
        catName="Superior "
        hotel="The Radh Hotel "
      />
      <HotelSL
        image={five}
        color="#ddbf61"
        catName="Superior "
        hotel="Back of Beyond Pidurangala (ECO) "
      />
      <HotelSL
        image={six}
        color="#ddbf61"
        catName="Superior "
        hotel="Back of Beyond Anuradhapura (ECO) "
      />
      <HotelSL
        image={seven}
        color="#ddbf61"
        catName="Superior "
        hotel="Villa Hundira "
      />
    </div>
  );
};

export default SuperiorCollection_Immersion;
