import React from "react";

import "../assets/css/Tour_Destinations.css";

import Destinations1_slide from "./Destinations1_slide";
import Destinations9_slide from "./Destinations9_slide";

const Destinations9 = () => {
  return (
    <div className="tour_Destinations">
      <div className="slides">
        <Destinations9_slide />
      </div>
      {/* <div className="line"></div> */}
    </div>
  );
};

export default Destinations9;
