import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Essenced10_slide from "./Essenced10_slide";

const Tour_EssenceDay10 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Essenced10_slide />
      </div>
      <div className="line">
        <h3>Day 10 : </h3>
        <h2>Sigiriya - Kegalle [02:30]- Negombo [02:00]</h2>
      </div>
      <p>Visit the Dambulla Cave Temple-UNESCO [00:40]</p>
      <p>Walk with the elephants at Millennium Elephant Foundation [01:30]</p>
    </div>
  );
};

export default Tour_EssenceDay10;
