import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import TourSlides_HistoryDay6 from "./TourSlides_HistoryDay6";

const Tour_HistoryDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_HistoryDay6 />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Kandy - Colombo [03:00] - Galle [02:00]</h2>
      </div>
      <p>Tour around the streets of Colombo [01:00]</p>
    </div>
  );
};

export default Tour_HistoryDay6;
