import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/wallawwa.jpg";
import two from "../assets/images/slhotels/watergarden.jpg";
import three from "../assets/images/slhotels/kingspavilion.jpg";
import four from "../assets/images/slhotels/nineskies.jpg";
import five from "../assets/images/slhotels/capeweligama.jpg";

const LuxuryCollection_Getaway = () => {
  return (
    <div className="collection">
      <h3>Luxury Collection</h3>
      <HotelSL
        image={one}
        color="#d4af38"
        catName="Luxury "
        hotel="The Wallawwa "
      />
      <HotelSL
        image={two}
        color="#d4af38"
        catName="Luxury "
        hotel="Water Garden Sigiriya "
      />
      <HotelSL
        image={three}
        color="#d4af38"
        catName="Luxury "
        hotel="King's Pavilion Kandy "
      />
      <HotelSL
        image={four}
        color="#d4af38"
        catName="Luxury "
        hotel="Nine Skies Bungalow (AI)"
      />
      <HotelSL
        image={five}
        color="#d4af38"
        catName="Luxury "
        hotel="Cape Weligama"
      />
    </div>
  );
};

export default LuxuryCollection_Getaway;
