import React from "react";

import "../assets/css/StorySection.css";
import VerticalSlider from "../components/VerticalSlider/VerticalSlider";
import VerticalSlideMobile from "../components/VerticalSlider/VerticalSlideMobile";
import FeatureSlider from "./FeatureSlider";

const StorySection = ({}) => {
  return (
    <section id="story">
      {/* story */}
      <div className="story">
        <h1>
          Our <span className="special1">Story</span>
        </h1>
        <div className="content">
          <p>
            Our path to being experts in tailor-made tours to Sri Lanka &amp;
            Maldives started with the most beautiful love story of a young
            couple, who were both working at a well-renowned Destination
            Management Company at the time. In 2011, our visionaries Anne &amp;
            Nirosh took their first steps to learning the art of travel.
          </p>
          <p>
            After almost a decade together, they have been able to find their
            passion through travel and now share the same passion with
            travellers from around the world to enrich their lives by curating
            the most authentic &amp; transformational tours.
          </p>
        </div>
      </div>
      {/* slideshow */}
      <div className="slideshow">
        <FeatureSlider />
      </div>
    </section>
  );
};

export default StorySection;
