import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Rushd4_slide from "./Rushd4_slide";

const Tour_RushDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Rushd4_slide />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Knuckles Mountain Range</h2>
      </div>
      <p>Heel Oya Trek [06:00]</p>
    </div>
  );
};

export default Tour_RushDay4;
