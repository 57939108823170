import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import Amangalla from "../assets/images/slhotels/amangalla.jpg";
import nine from "../assets/images/slhotels/nineskies.jpg";
import kings from "../assets/images/slhotels/kingspavilion.jpg";
import jetwing from "../assets/images/slhotels/jetwinguyana.jpg";
import wattura from "../assets/images/slhotels/wattura.jpg";

const LuxuryCollection_Island = () => {
  return (
    <div className="collection">
      <h3>Luxury Collection</h3>
      <HotelSL
        image={Amangalla}
        color="#d4af38"
        catName="Luxury "
        hotel="Amangalla  "
      />
      <HotelSL
        image={nine}
        color="#d4af38"
        catName="Luxury "
        hotel="Nine Skies Bungalow (AI)"
      />
      <HotelSL
        image={kings}
        color="#d4af38"
        catName="Luxury "
        hotel="King's Pavilion Kandy "
      />
      <HotelSL
        image={jetwing}
        color="#d4af38"
        catName="Luxury "
        hotel="Jetwing Vil Uyana"
      />
      <HotelSL
        image={wattura}
        color="#d4af38"
        catName="Luxury "
        hotel="Wattura Resort & Spa"
      />
    </div>
  );
};

export default LuxuryCollection_Island;
