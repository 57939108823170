import React from "react";
import SlickSlider from "./SlickSlider";
import SlickSliderMobile from "./SlickSliderMobile";
import "../assets/css/SriLankaSection.css";

import "aos/dist/aos.css";

import SlickSliderPremium from "./SlickSliderPremium";
import SlickSliderMobilePremium from "./SlickSliderMobilePremium";

const SriLankaSection = () => {
  return (
    <div id="srilanka" className="container">
      <h1>
        Sri <span className="special">Lanka</span>
      </h1>
      <div className="slidersContainer">
        <div className="top">
          <h2>Classic Collection</h2>
          {/* <hr /> */}
          <div className="top__slider">
            <div className="desktopSlider">
              <SlickSlider />
            </div>
            <div className="mobileSlider">
              <SlickSliderMobile />
            </div>
          </div>
        </div>
        <div className="bottom" id="signature">
          <h2>Signature Collection</h2>
          <div className="bottom__slider">
            <div className="desktopSlider">
              <SlickSliderPremium />
            </div>
            <div className="mobileSlider">
              <SlickSliderMobilePremium />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SriLankaSection;
