import React, { useState } from "react";
import "../assets/css/Navbar.css";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { menuData } from "../assets/Data/MenuData";
import { Button } from "./Button";
import { FaBars } from "react-icons/fa";
import FacebookIcon from "@material-ui/icons/Facebook";
import logoimg from "../assets/images/logo.png";
import { HashLink } from "react-router-hash-link";
import logoimg2 from "../assets/images/logo2.png";
import { animateScroll as scroll } from "react-scroll";

const Nav = styled.nav`
  /* height: 130px; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 100;
  position: fixed;

  width: 100%;
  /* background-color: #191c1d; */
  text-decoration: none;

  @media screen and (max-width: 768px) {
    display: flex;
    background-color: #191c1d;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const NavLink = css`
  ${"" /* color: white; */}
  ${"" /* font-weight: 600; */}
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
`;

const Logo = styled(Link)`
  ${NavLink}
`;

const LogoImage = styled.img`
  width: 250px;
  height: auto;

  /* margin: auto; */
  /* margin-bottom: 25px; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const LogoImage2 = styled.img`
  width: 150px;
  height: auto;

  /* margin: auto; */
  /* margin-bottom: 25px; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const LogoImageMobile = styled.img`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    margin: auto;
    width: 120px;
    margin-top: -5px;
  }
`;

const MenuBars = styled(FaBars)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    color: white;

    font-size: 25px;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 3px;
    margin-left: -5px;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  /* margin-right: -48px; */
  /* margin: auto; */
  /* position: absolute; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  ${NavLink}
  @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,600&display=swap");
  font-family: "century-gothic", sans-serif;
  /* padding: 15px; */
  /* text-shadow: 0 0 25px rgba(0, 0, 0, 0.8); */
  border-radius: 5px;
  margin-left: 40px;
`;

const NavMenuLinksA = styled.a`
  ${NavLink}
  @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,600&display=swap");
  font-family: "century-gothic", sans-serif;
  /* padding: 15px; */
  /* text-shadow: 0 0 25px rgba(0, 0, 0, 0.8); */
  border-radius: 5px;
  margin-left: 40px;
`;

const NavAnchorLinks = styled(HashLink)`
  ${NavLink}
  @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,600&display=swap");
  font-family: "century-gothic", sans-serif;
  /* padding: 15px; */
  /* text-shadow: 0 0 25px rgba(0, 0, 0, 0.8); */
  border-radius: 5px;
  margin-left: 40px;
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  /* margin: auto; */
  display: flex;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileView = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
  }
`;

const Navbar = ({ toggle, powered, mark }) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 130) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <Nav className={navbar ? "active" : ""}>
        <MobileView>
          <MenuBars onClick={toggle} />
          <LogoImageMobile src={logoimg} />
        </MobileView>
        {/* <Logo>SILAN TRAVELS</Logo> */}
        <LogoWrapper>
          {/* <MenuBars onClick={toggle} /> */}
          <LogoImage src={logoimg} className={navbar ? "disable" : ""} />
          <LogoImage2 src={logoimg2} className={navbar ? "" : " disable"} />
        </LogoWrapper>

        <NavMenu>
          <NavAnchorLinks
            className={navbar ? "colorchange" : "stable"}
            activeClass="pressed"
            // activeStyle={{ color: "red" }}
            smooth={true}
            offset={-70}
            duration={500}
            to="#home"
          >
            HOME
          </NavAnchorLinks>
          <NavAnchorLinks
            className={navbar ? "colorchange" : "stable"}
            activeClass="pressed"
            // activeStyle={{ color: "red" }}
            smooth={true}
            offset={-70}
            duration={500}
            to="#srilanka"
          >
            SRI LANKA
          </NavAnchorLinks>
          <NavAnchorLinks
            className={navbar ? "colorchange" : "stable"}
            activeClass="pressed"
            // activeStyle={{ color: "red" }}
            smooth={true}
            offset={-70}
            duration={500}
            to="#maldives"
          >
            MALDIVES
          </NavAnchorLinks>
          <NavMenuLinksA
            className={navbar ? "colorchange" : "stable"}
            href="/partners"
          >
            PARTNER WITH US
          </NavMenuLinksA>
          <NavAnchorLinks
            className={navbar ? "colorchange" : "stable"}
            activeClass="pressed"
            // activeStyle={{ color: "red" }}
            smooth={true}
            offset={-70}
            duration={500}
            to="#reachus"
          >
            REACH US
          </NavAnchorLinks>
        </NavMenu>
        {/* <NavBtn>
        <Button to="/contact" primary="true">
          Contact Us 
        </Button>
      </NavBtn> */}
      </Nav>
    </>
  );
};

export default Navbar;
