import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Traveld7_slide from "./Traveld7_slide";

const Tour_TravelDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Traveld7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Ella - Galle [03:30]</h2>
      </div>
      <p>Tuk Tuk ride from Ella to Kuda Oya [01:30]</p>
      <p>Ravana Falls [00:15]</p>
    </div>
  );
};

export default Tour_TravelDay7;
