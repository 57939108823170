import React from "react";
import "../assets/css/NavbarDark.css";
import { HashLink } from "react-router-hash-link";
import Scrollspy from "react-scrollspy";
import { BrowserRouter as Router } from "react-router-dom";
import logo from "../assets/images/logonew.png";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";

import logoWhite from "../assets/images/logoWhite.png";

export default class NavbarDarkSriLanka extends React.Component {
  constructor() {
    super();
    this.state = {
      show: true,
      scrollPos: 0,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    // console.log(document.body.getBoundingClientRect());
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > this.state.scrollPos,
    });
  };
  render() {
    console.log(this.state);
    return (
      <Router>
        <div className="mobileView">
          <FaBars onClick={this.props.toggle} className="icon" />
          <img alt="" src={logo} />
        </div>
        <div id="navbar" className={this.state.show ? "active" : "hidden"}>
          <div className="image">
            {/* <img alt="" src={logo} /> */}
            <a className="imglink" href="/">
              <img className="white" alt="" src={logoWhite} />
            </a>
          </div>
          <div className="contents">
            <div className="links">
              <Scrollspy
                className="spy"
                items={["home", "srilanka", "maldives", "partners", "reachus"]}
                currentClassName="is-current"
              >
                <li>
                  <a
                    style={{ fontWeight: "600" }}
                    className="oneLink"
                    smooth
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontWeight: "600" }}
                    className="oneLink"
                    smooth
                    href="#"
                  >
                    Sri Lanka
                  </a>
                </li>

                <li>
                  <a
                    style={{ fontWeight: "600" }}
                    className="oneLink"
                    smooth
                    href="/partners"
                  >
                    Partner with Us
                  </a>
                </li>
              </Scrollspy>
            </div>
            {/* <div className="icons">
                    <a href="https://www.facebook.com/silantravels/?fref=ts"><FacebookIcon className="icon" /></a>
                    <a href="https://www.instagram.com/silantravels "><InstagramIcon className="icon" /></a>
                    <a href="https://twitter.com/SilanTravels"><TwitterIcon className="icon" /></a>
                </div> */}
          </div>
        </div>
      </Router>
    );
  }
}
