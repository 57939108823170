import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Bondd5_slide from "./Bondd5_slide";

const Tour_BondDay5 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Bondd5_slide />
      </div>
      <div className="line">
        <h3>Day 05 : </h3>
        <h2>Yala National Park - Hatton [00:30] </h2>
      </div>
      <p>Helicopter Transfer from Yala to Hatton [00:30]</p>
      <p>Visit a tea factory in the tea country [00:40]</p>
    </div>
  );
};

export default Tour_BondDay5;
