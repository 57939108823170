import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/amanwella.jpg";
import two from "../assets/images/slhotels/ugabay.jpg";

const PremiumCollectionBeach_Essence = () => {
  return (
    <div className="collection">
      <h3>Premium Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Amanwella "
      />
      <HotelSL
        image={two}
        color="#d4af38"
        catName="Premium Signature "
        hotel="‎Uga Bay "
      />
    </div>
  );
};

export default PremiumCollectionBeach_Essence;
