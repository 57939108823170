import React from 'react'
import { Link } from 'react-router-dom'
import "../assets/css/CollectionNav.css"
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';


const CollectionNav_SinglePre = ({ targetPremium, premiumPrice }) => {
    return (
        <div className="collectionNav">
                    <Link  to={targetPremium} className="linkx" >Premium Signature Collection <span>${premiumPrice}<ArrowUpwardIcon className="icon" /></span></Link>
                    
        </div>
    )
}

export default CollectionNav_SinglePre
