import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Budsd11_slide from "./Budsd11_slide";

const Tour_BudsDay11 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Budsd11_slide />
      </div>
      <div className="line">
        <h3>Day 11 : </h3>
        <h2>Colombo </h2>
      </div>
      <p>Tour around the streets of Colombo [01:00]</p>
      <p>Theatrical tasting menu experience at Culinary Ceylon [02:30]</p>
    </div>
  );
};

export default Tour_BudsDay11;
