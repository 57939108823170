import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import Ignited2_slide from "./ignited2_slide";

const Tour_IgniteDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited2_slide />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Galle </h2>
      </div>
      <p>Walk inside the ramparts of Galle Fort -UNESCO [01:00]</p>
      <p>Roti dinner experience in Galle </p>
    </div>
  );
};

export default Tour_IgniteDay2;
