import React from "react";
import styled from "styled-components";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

const Cover = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 270px;
  max-width: 270px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  margin-right: 25px;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }

  @media screen and (max-width: 1366px) {
    width: 220px;
  }

  @media screen and (max-width: 768px) {
    width: 250px;
    max-width: 300px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Image = styled.img`
  width: 270px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  @media screen and (max-width: 1366px) {
    width: 220px;
  }

  @media screen and (max-width: 768px) {
    width: 250px;
  }
`;

const HotelName = styled.h2`
  @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

  padding: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
`;

const Category = styled.h3`
  @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin-top: 10px;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 0px;
`;

const HotelSL = ({ image, catName, hotel, color }) => {
  return (
    <Cover>
      <Image alt="" src={image} />
      <Category style={{ color: `${color}` }}>{catName}</Category>
      <HotelName>{hotel}</HotelName>
    </Cover>
  );
};

export default HotelSL;
