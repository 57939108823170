import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";
import coco from "../assets/images/slhotels/cocobay.jpg";
import cbeyond from "../assets/images/slhotels/cbeyond.jpg";

const SuperiorCollectionBeach_History = () => {
  return (
    <div className="collection">
      <h3>Superior Collection</h3>
      <HotelSL
        image={coco}
        color="#ddbf61"
        catName="Superior "
        hotel="CocoBay Unawatuna"
      />
      <HotelSL
        image={cbeyond}
        color="#ddbf61"
        catName="Superior "
        hotel="C Beyond Nilaveli  "
      />
    </div>
  );
};

export default SuperiorCollectionBeach_History;
