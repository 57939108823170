import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/jetwinglagoon.jpg";
import two from "../assets/images/slhotels/amayalake.jpg";
import three from "../assets/images/slhotels/cinnamoncitadel.jpg";
import four from "../assets/images/slhotels/ellaflower.jpg";
import five from "../assets/images/slhotels/waligama.jpg";

const StandardCollection_Getaway = () => {
  return (
    <div className="collection">
      <h3>Standard Collection</h3>
      <HotelSL
        image={one}
        color="#dec267"
        catName="Standard "
        hotel="Jetwing Lagoon  "
      />
      <HotelSL
        image={two}
        color="#dec267"
        catName="Standard "
        hotel="Amaya Lake Resort & Spa  "
      />
      <HotelSL
        image={three}
        color="#dec267"
        catName="Standard "
        hotel="Cinnamon Citadel  "
      />
      <HotelSL
        image={four}
        color="#dec267"
        catName="Standard "
        hotel="Ella Flower Garden  "
      />
      <HotelSL
        image={five}
        color="#dec267"
        catName="Standard "
        hotel="Weligama Bay Marriott Resort & Spa "
      />
    </div>
  );
};

export default StandardCollection_Getaway;
