import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";
import lakelodge from "../assets/images/slhotels/lakelodge.jpg";
import clove from "../assets/images/slhotels/clovevilla.jpg";
import printers from "../assets/images/slhotels/fortprinters.jpg";

const SuperiorCollection_History = () => {
  return (
    <div className="collection">
      <h3>Superior Collection</h3>
      <HotelSL
        image={lakelodge}
        color="#ddbf61"
        catName="Superior "
        hotel="Lake Lodge Kandalama"
      />
      <HotelSL
        image={clove}
        color="#ddbf61"
        catName="Superior "
        hotel="Clove Villa  "
      />
      <HotelSL
        image={printers}
        color="#ddbf61"
        catName="Superior "
        hotel="The Fort Printers "
      />
    </div>
  );
};

export default SuperiorCollection_History;
