import React from "react";
import styled from "styled-components";
import Days_Destinations from "./Days_Destinations";
import Days_DestinationsMobile from "./Days_DestinationsMobile";
import Days_Dimension from "./Days_Dimension";
import image from "../assets/images/destinations/destBack.png";
import "../assets/css/Destinations.css";
import Destination_AwesomeSlider from "./Destination_AwesomeSlider";
import DesSpringSlider from "./Destination_3dSlider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: #fff; */

  padding: 0px 40px;
  margin-top: -100px;

  @media screen and (max-width: 1366px) {
    margin-top: -100px;
  }

  @media screen and (max-width: 768px) {
    margin-top: -70px;
  }
`;

const Topic = styled.h3`
  @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");

  font-family: "Cormorant Garamond", serif;
  font-size: 19px;
  margin: auto;
  font-weight: 400;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
`;

const Con = styled.div`
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
`;

const Destinations = () => {
  return (
    <Container id="destinations" className="contDest">
      <Topic>Destinations</Topic>
      <Con>
        <DesSpringSlider />
        {/* <Destination_AwesomeSlider /> */}
        {/* <Days_Destinations />
        <Days_DestinationsMobile /> */}
      </Con>
    </Container>
  );
};

export default Destinations;
