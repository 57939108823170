import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../assets/css/SlickSlider.css";
import slick1 from "../assets/images/SlickSlider/newimages/reignite.png";
import slick2 from "../assets/images/SlickSlider/newimages/essence.png";
import slick3 from "../assets/images/SlickSlider/newimages/likelocals.png";
import slick4 from "../assets/images/SlickSlider/newimages/adrenaline.png";
import slick5 from "../assets/images/SlickSlider/newimages/wildlife.png";
import slick6 from "../assets/images/SlickSlider/newimages/tastebuds.png";
import slick7 from "../assets/images/SlickSlider/newimages/serenity.png";
import slick8 from "../assets/images/SlickSlider/newimages/dimension.png";
import slick9 from "../assets/images/SlickSlider/newimages/bond.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

function Arrow(props) {
  let className = props.type === "next" ? "nextArrow" : "prevArrow";
  className += " arrow";
  const char =
    props.type === "next" ? (
      <ArrowForwardIosIcon style={{ marginLeft: "5px" }} />
    ) : (
      <ArrowBackIosIcon style={{ marginRight: "5px" }} />
    );
  return (
    <span
      style={{ cursor: "pointer", color: "#008080" }}
      className={className}
      onClick={props.onClick}
    >
      {char}
    </span>
  );
}

const SlickSliderPremium = () => {
  var settings = {
    dots: false,
    infinite: true,

    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Slider
      nextArrow={<Arrow type="next" />}
      prevArrow={<Arrow type="prev" />}
      {...settings}
      className="total"
    >
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick1} />
        </div>
        <h2>Re-Ignite the Flame</h2>
        <div className="overlay">
          <a href="/reignitetheflame" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick2} />
        </div>
        <h2>Essence of Love</h2>
        <div className="overlay">
          <a href="/essenceoflove" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick3} />
        </div>
        <h2>Travel Like a Local</h2>
        <div className="overlay">
          <a href="/travellikealocal" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick4} />
        </div>
        <h2>Adrenaline Rush</h2>
        <div className="overlay">
          <a href="/adrenalinerush" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick5} />
        </div>
        <h2>Exhilarating Wildlife</h2>
        <div className="overlay">
          <a
            href="/exhilaratingwildlife"
            className="slickButton"
            target="_blank"
          >
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick6} />
        </div>
        <h2>Resuscitate your Taste Buds</h2>
        <div className="overlay">
          <a href="/tastebuds" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick7} />
        </div>
        <h2>Serenity in Serendib</h2>
        <div className="overlay">
          <a href="/serenityinserendib" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick8} />
        </div>
        <h2>Ascending to another Dimension</h2>
        <div className="overlay">
          <a href="/anotherdimension" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
      <div className="slickModule">
        <div className="imageContainer">
          <img alt="" src={slick9} />
        </div>
        <h2>Bond with your Loved Ones</h2>
        <div className="overlay">
          <a href="/lovedones" className="slickButton" target="_blank">
            More Details
          </a>
        </div>
      </div>
    </Slider>
  );
};

export default SlickSliderPremium;
