import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import rain from "../assets/images/slhotels/rainforesteco.jpg";
import leop from "../assets/images/slhotels/leopardsafari.jpg";
import secret from "../assets/images/slhotels/secretella.jpg";
import theva from "../assets/images/slhotels/theva.jpg";
import wild from "../assets/images/slhotels/wildgrass.jpg";
import forest from "../assets/images/slhotels/forestrock.jpg";
import pledge from "../assets/images/slhotels/pledgescape.jpg";

const DeluxeCollection_Immersion = () => {
  return (
    <div className="collection">
      <h3>Deluxe Collection</h3>
      <HotelSL
        image={rain}
        color="#d5b23f"
        catName="Deluxe "
        hotel="The Rainforest Eco lodge (ECO)"
      />
      <HotelSL
        image={leop}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Leopard Safaris Yala (AITC)"
      />
      <HotelSL
        image={secret}
        color="#d5b23f"
        catName="Deluxe "
        hotel="The Secret Ella "
      />
      <HotelSL
        image={theva}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Theva Residency "
      />
      <HotelSL
        image={wild}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Wild Grass Nature Resort  "
      />
      <HotelSL
        image={forest}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Forest Rock Garden  "
      />
      <HotelSL
        image={pledge}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Pledge Scape "
      />
    </div>
  );
};

export default DeluxeCollection_Immersion;
