import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd3_slide from "./Serenityd3_slide";

const Tour_SerenityDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Anuradhapura </h2>
      </div>
      <p>Morning Jungle Kayaking [01:00]</p>
      <p>Organic garden tour with cookery demonstration & lunch [02:00]</p>
      <p>Visit the Ancient Kingdom of Anuradhapura-UNESCO [02:00]</p>
    </div>
  );
};

export default Tour_SerenityDay3;
