import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Budsd8_slide from "./Budsd8_slide";

const Tour_BudsDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Budsd8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Kagalle -Galle [02:00]</h2>
      </div>
      <p>Walk with the elephants at Millennium Elephant Foundation [01:30]</p>
      <p>Roti dinner experience in Galle </p>
    </div>
  );
};

export default Tour_BudsDay8;
