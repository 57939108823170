import React from "react";
import Slider from "react-slick";
import "../assets/css/FeatureSlider.css";
import sample from "../assets/images/SlickSlider/newimages/bond.png";
import slide1 from "../assets/images/why.jpg";
import slide2 from "../assets/images/slide2.png";
import slide3 from "../assets/images/slide3.png";
import slide4 from "../assets/images/slide4.png";
import slide5 from "../assets/images/slide5.png";
import slide6 from "../assets/images/slide6.png";

const FeatureSlider = () => {
  return (
    <Slider
      slidesToShow={1}
      dots={false}
      autoplay={true}
      autoplaySpeed={2000}
      arrows={false}
      infinite={true}
      slidesToScroll={1}
      className="containerCover"
    >
      <div className="slideCover">
        <img src={slide1} alt="" />
        <h3>Why Pick Us?</h3>
        <p>
          We provide pre-eminent experiences and offer unforgettable holidays to
          travellers from around the globe through our years of unparalleled
          experience in the industry. Whether it is transformational travel, an
          ideal tour for a family or simply an idyllic tour with first-rate
          services, we have it all and we’ve done it all – The smarter way &amp;
          the affordable way!
        </p>
      </div>
      <div className="slideCover">
        <img src={slide2} alt="" />
        <h3>Commitment To Responsible Travel</h3>
        <p>
          Responsible travel is one of the main pillars in our thinking process
          while creating your holidays. We have provided exclusive training to
          our staff & all service providers about Environmental awareness. Our
          partners have been given the first-hand experience through community
          engagement projects to have a clear conscious and focus to design
          respectful and sustainable holidays.
          {/* We are currently focused in
          acquiring the GSTC and Travelife membership and encourage our partners
          to be certified in sustainability. */}
        </p>
      </div>
      <div className="slideCover">
        <img src={slide3} alt="" />
        <h3>Prioritizing Health & Safety</h3>
        <p>
          {/* We always put the client’s health and safety first even if it means
          recommending another destination instead of Sri Lanka or Maldives. We
          only promote Hotels, Experiences Logistic Services which are certified
          by respective government authorities after being inspected &amp;
          certified by our own team of specialists. We do not hesitate to say
          “no” to a product or service that will threaten the health & safety of
          our clients. */}
          We always put our clients’ health &amp; safety first even if it means
          encouraging them to opt for another destination other than Sri Lanka
          or Maldives. We only partner with and promote suppliers who have been
          inspected and certified by respective government authorities and by
          our own team of specialists. We do not hesitate to say no to a service
          provider that might threaten the health &amp; safety of our clients.
        </p>
      </div>
      <div className="slideCover">
        <img src={slide4} alt="" />
        <h3>Flexible: COVID-19 Cancellation Policy</h3>
        <p>
          {/* We do understand that people may be hesitant to book a holiday at the
          moment given current concerns over global travel. To help reassure and
          support travellers, we have partnered with our Hotels Suppliers to
          deliver very special, extremely-flexible cancellation policy up until
          the client’s arrival to the country. */}
          We are well aware that people may be hesitant to book a holiday
          presently given the current concerns over global travel. To help
          reassure and support travellers, we have worked on a special and
          extremely flexible cancellation policy with our Hotels &amp;
          Suppliers.
        </p>
      </div>
      <div className="slideCover">
        <img src={slide5} alt="" />
        <h3>24 x 7 Customer Care Service</h3>
        <p>
          {/* {" "}
          Our clients are never alone in the country as the 24 x 7 on-ground
          customer care team will be at their fingertips through a Call, Text
          Message, Email or even by WhatsApp to attend all questions and
          concerns from the time they pack their bags and until they are
          unpacking them back at home. */}
          Our clients are never alone while they travel! Our 24/7 dedicated
          customer care team will be at their fingertips through a call, text
          message, or by WhatsApp to attend to all questions and concerns from
          the time they pack their bags until they are unpacking them back at
          home.
        </p>
      </div>
      <div className="slideCover">
        <img src={slide6} alt="" />
        <h3>Global Representation - France &amp; Portugal</h3>
        <p>
          {/* Our representative network has been growing from Asia to Europe with
          France, Italy, Spain & Portugal being the latest addition to our
          portfolio. With these new additions, we try to be close to our Agents
          while planning the best getaways for their beloved clients. */}
          Our representative network has been growing from Europe with France,
          Italy, Spain &amp; Portugal to Latin America as the latest addition to
          our portfolio. As we continue to expand to new horizons, our priority
          is to strengthen our relationship with our partners by planning the
          best getaways for their valued clients.
        </p>
      </div>
    </Slider>
  );
};

export default FeatureSlider;
