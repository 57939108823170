import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond4_slide from "./Immersiond4_slide";

const Tour_immersionDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond4_slide />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Sinharaja Forest Reserve - Yala National Park [02:30]</h2>
      </div>
      <p>Elephant Transit Home [01:00]</p>
    </div>
  );
};

export default Tour_immersionDay4;
