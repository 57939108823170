import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Essenced8_slide from "./Essenced8_slide";

const Tour_EssenceDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Essenced8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Kandy - Sigiriya [03:30]</h2>
      </div>
      <p>Tuk Tuk ride through the streets of Kandy [00:30]</p>
      <p>Visit the Temple of the Sacred Tooth Relic -UNESCO [01:30]</p>
    </div>
  );
};

export default Tour_EssenceDay8;
