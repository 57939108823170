import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import TourSlides_IslandDay6 from "./TourSlides_IslandDay6";

const Tour_IslandDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_IslandDay6 />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Sigiriya - Anuradhapura [01:30] - Sigiriya </h2>
      </div>
      <p>Visit the Ancient Kingdom of Anuradhapura-UNESCO [02:00]</p>
    </div>
  );
};

export default Tour_IslandDay6;
