import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Ignited10_slide from "./Ignited10_slide";

const Tour_IgniteDay10 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited10_slide />
      </div>
      <div className="line">
        <h3>Day 10 : </h3>
        <h2>Anuradhapura -Sigiriya [01:00] - Negombo [03:30]</h2>
      </div>
      <p>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</p>
    </div>
  );
};

export default Tour_IgniteDay10;
