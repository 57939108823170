import React from "react";

import Hotel_SuperDeluxe from "./Hotel_SuperDeluxe";
import emeraldimage from "../assets/images/Maldives/emerald.png";
import ltiimage from "../assets/images/Maldives/LTI.png";
import movenpickimage from "../assets/images/Maldives/movenpick.png";
import pullmanimage from "../assets/images/Maldives/pullman.png";
import seasideimage from "../assets/images/Maldives/seaside.png";
import irufushiimage from "../assets/images/Maldives/irufushi.png";
import youandmeimage from "../assets/images/Maldives/youandme.png";

const Hotels_SuperDeluxe = ({
  EmeraldPopup,
  LTIPopup,
  MovenpickPopup,
  PullmanPopup,
  FinohluPopup,
  IrufushiPopup,
  YouandmePopup,
}) => {
  return (
    <div className="hotels">
      <h1
        style={{ fontFamily: `"Cormorant Garamond", serif`, fontSize: "19px" }}
        className="Heading"
      >
        Super Deluxe Collection
      </h1>
      <div className="HotelsContainer">
        <div className="row">
          <Hotel_SuperDeluxe
            runner={EmeraldPopup}
            image={emeraldimage}
            title="Emerald Maldives"
          />
          <Hotel_SuperDeluxe
            runner={LTIPopup}
            image={ltiimage}
            title="LTI Maafushivaru"
          />
          <Hotel_SuperDeluxe
            runner={MovenpickPopup}
            image={movenpickimage}
            title="Movenpick Resort Kuredhivaru"
          />
          <Hotel_SuperDeluxe
            runner={PullmanPopup}
            image={pullmanimage}
            title="Pullman Maldives Maamutaa"
          />
          <Hotel_SuperDeluxe
            runner={FinohluPopup}
            image={seasideimage}
            title="Seaside Finolhu"
          />
        </div>
        <div className="row">
          <Hotel_SuperDeluxe
            runner={IrufushiPopup}
            image={irufushiimage}
            title="Sun Siyam Iru Fushi"
          />
          <Hotel_SuperDeluxe
            runner={YouandmePopup}
            image={youandmeimage}
            title="You & Me Maldives"
          />
        </div>
      </div>
    </div>
  );
};

export default Hotels_SuperDeluxe;
