import React from "react";

import "../assets/css/Tour_Destinations.css";
import Destinations11_slide from "./Destinations11_slide";

import Destinations1_slide from "./Destinations1_slide";

const Destinations11 = () => {
  return (
    <div className="tour_Destinations">
      <div className="slides">
        <Destinations11_slide />
      </div>
      {/* <div className="line"></div> */}
    </div>
  );
};

export default Destinations11;
