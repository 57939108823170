import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import kumu from "../assets/images/slhotels/kumu.jpg";
import uga from "../assets/images/slhotels/ugabay.jpg";

const LuxuryCollectionBeach_Island = () => {
  return (
    <div className="collection">
      <h3>Luxury Collection</h3>
      <HotelSL
        image={kumu}
        color="#d4af38"
        catName="Luxury "
        hotel="Kumu Beach "
      />
      <HotelSL
        image={uga}
        color="#d4af38"
        catName="Luxury "
        hotel=" ‎Uga Bay "
      />
    </div>
  );
};

export default LuxuryCollectionBeach_Island;
