import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import boulder from "../assets/images/slhotels/bouldergarden.jpg";
import leopard from "../assets/images/slhotels/leopardtrails.jpg";
import acres from "../assets/images/slhotels/98acres.jpg";
import kings from "../assets/images/slhotels/kingspavilion.jpg";
import jetwing from "../assets/images/slhotels/jetwinguyana.jpg";
import ulagalla from "../assets/images/slhotels/ulagalla.jpg";
import wallawwa from "../assets/images/slhotels/wallawwa.jpg";

const LuxuryCollection_Immersion = () => {
  return (
    <div className="collection">
      <h3>Luxury Collection</h3>
      <HotelSL
        image={boulder}
        color="#d4af38"
        catName="Luxury "
        hotel="Boulder Garden Resort (ECO) "
      />
      <HotelSL
        image={leopard}
        color="#d4af38"
        catName="Luxury "
        hotel="Leopard Trails Yala (AITC)"
      />
      <HotelSL
        image={acres}
        color="#d4af38"
        catName="Luxury "
        hotel="98 Acres Resort & Spa "
      />
      <HotelSL
        image={kings}
        color="#d4af38"
        catName="Luxury "
        hotel="King's Pavilion Kandy"
      />
      <HotelSL
        image={jetwing}
        color="#d4af38"
        catName="Luxury "
        hotel="Jetwing Vil Uyana "
      />
      <HotelSL
        image={ulagalla}
        color="#d4af38"
        catName="Luxury "
        hotel="Ulagalla by Uga "
      />
      <HotelSL
        image={wallawwa}
        color="#d4af38"
        catName="Luxury "
        hotel="The Wallawwa"
      />
    </div>
  );
};

export default LuxuryCollection_Immersion;
