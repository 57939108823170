import React, { useEffect, useState } from "react";
import "../assets/css/History.css";
import history from "../assets/images/history.jpg";
import Tour from "./Tour";
import Footer from "./Footer";
import Tour_HistoryDay1 from "./Tour_HistoryDay1";
import Tour_HistoryDay2 from "./Tour_HistoryDay2";
import Tour_HistoryDay3 from "./Tour_HistoryDay3";
import Tour_HistoryDay4 from "./Tour_HistoryDay4";
import Tour_HistoryDay5 from "./Tour_HistoryDay5";
import Tour_HistoryDay6 from "./Tour_HistoryDay6";
import Tour_HistoryDay7 from "./Tour_HistoryDay7";
import Tour_HistoryDay8 from "./Tour_HistoryDay8";
import sample from "../assets/images/SlickSlider/newimages/bond.png";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Multiselect from "multiselect-react-dropdown";
import { HashLink } from "react-router-hash-link";
import Days_History from "./Days_History";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import StandardCollection_History from "./StandardCollection_History";
import CollectionNav from "./CollectionNav";
import SuperiorCollection_History from "./SuperiorCollection_History";
import DeluxeCollection_History from "./DeluxeCollection_History";
import LuxuryCollection_History from "./LuxuryCollection_History";
import StandardCollectionBeach_History from "./StandardCollectionBeach_History";
import SuperiorCollectionBeach_History from "./SuperiorCollectionBeach_History";
import DeluxeCollectionBeach_History from "./DeluxeCollectionBeach_History";
import LuxuryCollectionBeach_History from "./LuxuryCollectionBeach_History";
import NavbarSriLanka from "./NavbarSriLanka";
import DropdownSriLanka from "./DropdownSriLanka";
import NavbarDarkSriLanka from "./NavbarDarkSriLanka";
import Footer_SriLanka from "./Footer_SriLanka";
import styled from "styled-components";

const BlankComponent = styled.div`
  @media screen and (max-width: 1366px) {
    margin-top: -230px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CustomButton = styled(HashLink)`
  text-decoration: none;
  padding: 10px 15px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  background-color: #008080;
  border-radius: 5px;
  border: none;
  transition: 0.4s;
  margin-bottom: 20px;
  margin-top: -10px;

  &:hover {
    cursor: pointer;
    background-color: #d0b49f;
  }
`;

const HistoryandHeritage = () => {
  const hotelData = [
    {
      cat: "Standard Collection",
    },
    {
      cat: "Superior Collection",
    },
    {
      cat: "Deluxe Collection",
    },
    {
      cat: "Luxury Collection",
    },
  ];

  useEffect(() => {
    document.title = "Relive the History & Heritage";
  }, []);

  const [hotel, setHotel] = useState([]);
  const [beach, setBeach] = useState([]);
  const [checked, setChecked] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <Router>
      <div className="sriLanka_Package">
        <div className="nav">
          <NavbarDarkSriLanka toggle={toggle} />
          <DropdownSriLanka isOpen={isOpen} toggle={toggle} />
        </div>
        {/* navbar */}
        <div className="headerHistory">
          <div className="leftSide">
            <img alt="" src={history} />
          </div>
          <div className="rightSide">
            <HashLink smooth to="#enquire" className="fixedAnchorButton">
              Enquire
            </HashLink>
            {/* <h3>Sri Lanka</h3> */}
            <h1>Relive the History & Heritage</h1>

            <HashLink
              className="button"
              smooth={true}
              offset={-70}
              duration={500}
              to="#enquire"
            >
              Enquire
            </HashLink>
          </div>
        </div>
        <div className="packageDes_Container">
          <p className="packageDes">
            <span>“</span>Relive the history through the UNESCO heritage sites
            with a touch of nature and culture. Immerse yourself in the legacy
            of Sri Lanka from the ruins of Anuradhapura to the last Hill Capital
            of Kandy and from the Rock Fortress of King “Kashyapa” to the Dutch
            Fort of Galle.
            <span>”</span>
          </p>
        </div>
        {/* <h1 className="tourHeading">Topic <span className="special">Here</span></h1> */}
        <div className="tourDetails">
          <Days_History />
        </div>
        <BlankComponent />
        <div className="hotels">
          <h1 className="topic">
            A
            <span
              style={{
                color: "#D0B49F",
                fontFamily: `'Cormorant Garamond', serif`,
                fontWeight: "400",
              }}
            >
              ccommodation
            </span>
          </h1>

          <CollectionNav
            targetStandard="/historyandheritage"
            targetSuperior="/historyandheritage/superior"
            targetDeluxe="/historyandheritage/deluxe"
            targetLuxury="/historyandheritage/luxury"
            standardPrice="100"
            superiorPrice="170"
            deluxePrice="230"
            luxuryPrice="300"
          />

          <Switch>
            <Route
              path="/historyandheritage/superior"
              exact
              render={() => <SuperiorCollection_History />}
            />
            <Route
              path="/historyandheritage/deluxe"
              exact
              render={() => <DeluxeCollection_History />}
            />
            <Route
              path="/historyandheritage/luxury"
              exact
              render={() => <LuxuryCollection_History />}
            />
            <Route
              path="/historyandheritage"
              render={() => <StandardCollection_History />}
            />
          </Switch>
        </div>
        <div className="hotels">
          <h1 className="topic">
            Beach <span className="special">Extensions</span>
          </h1>
          <CollectionNav
            targetStandard="/historyandheritage"
            targetSuperior="/historyandheritage/beach_superior"
            targetDeluxe="/historyandheritage/beach_deluxe"
            targetLuxury="/historyandheritage/beach_luxury"
            standardPrice="100"
            superiorPrice="170"
            deluxePrice="230"
            luxuryPrice="300"
          />

          <Switch>
            <Route
              path="/historyandheritage/beach_luxury"
              exact
              render={() => <LuxuryCollectionBeach_History />}
            />
            <Route
              path="/historyandheritage/beach_deluxe"
              exact
              render={() => <DeluxeCollectionBeach_History />}
            />
            <Route
              path="/historyandheritage/beach_superior"
              exact
              render={() => <SuperiorCollectionBeach_History />}
            />
            <Route
              path="/historyandheritage"
              render={() => <StandardCollectionBeach_History />}
            />
          </Switch>
        </div>

        <ul className="SLBullets">
          <li>
            <span>*</span>ECO - Eco Lodges
          </li>
          <li>
            <span>*</span>TC - Tented Camps
          </li>
          <li>
            <span>*</span>AI - All-inclusive
          </li>
          <li>
            <span>*</span>AITC - All-inclusive Tented Camps
          </li>
        </ul>

        <div id="enquire" className="form">
          <form
            method="POST"
            action="https://formsubmit.co/enquiries@silantravels.com"
          >
            <h2>Enquire Now</h2>
            <input
              type="hidden"
              name="_subject"
              value="Sri Lanka Tour Submission"
            />
            <input
              className="field"
              type="text"
              name="name"
              placeholder="Name"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="field"
              required
            />
            <input
              type="text"
              name="tour name"
              value="Relive History and Heritage"
              style={{ display: "none" }}
            />
            <div className="seperate">
              <input
                className="field"
                type="text"
                name="nationality"
                placeholder="Nationality"
                required
              />
              <input
                className="field"
                type="number"
                name="number of adults"
                placeholder="Number of Adults"
              />
              <input
                className="field"
                type="number"
                name="number of children"
                placeholder="Number of Children"
              />
            </div>
            <div className="seperate1">
              <input
                className="field"
                type="text"
                onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                placeholder="Arrival Date"
                name="date"
                required
              />
              <input
                className="field"
                type="number"
                name="nights"
                placeholder="Nights (Max: 20)"
                required
              />
            </div>
            <Multiselect
              style={{
                searchBox: {
                  border: "none",
                  backgroundColor: "#f3f3f3",
                  marginBottom: "10px",
                  padding: "10px",
                },
              }}
              placeholder="Hotel Category"
              type="text"
              displayValue="cat"
              onSelect={(e) => {
                setHotel(Array.isArray(e) ? e.map((x) => x.cat) : "");
              }}
              onRemove={(e) => {
                setHotel(Array.isArray(e) ? e.map((x) => x.cat) : "");
              }}
              options={hotelData}
              singleSelect={true}
              avoidHighlightFirstOption={true}
            />
            {hotel && (
              <input
                name="Hotel category"
                style={{ display: "none" }}
                value={JSON.stringify(hotel)}
              />
            )}

            <div className="seperate2">
              <label>Beach Extension</label>
              <input
                checked={checked}
                onChange={handleChange}
                className="checker"
                type="checkbox"
                name="beach extension"
              />
            </div>
            {checked ? (
              <>
                {" "}
                <Multiselect
                  style={{
                    searchBox: {
                      border: "none",
                      backgroundColor: "#f3f3f3",
                      marginBottom: "10px",
                      padding: "10px",
                    },
                  }}
                  placeholder="Hotel Category (Beach)"
                  type="text"
                  displayValue="cat"
                  onSelect={(e) => {
                    setBeach(Array.isArray(e) ? e.map((x) => x.cat) : "");
                  }}
                  onRemove={(e) => {
                    setBeach(Array.isArray(e) ? e.map((x) => x.cat) : "");
                  }}
                  options={hotelData}
                  singleSelect={true}
                  avoidHighlightFirstOption={true}
                />{" "}
                <input
                  className="field"
                  type="number"
                  name="nights - Beach extension"
                  placeholder="Nights (Max: 20)"
                />{" "}
              </>
            ) : (
              ""
            )}
            {beach && (
              <input
                name="Hotel category (Beach)"
                style={{ display: "none" }}
                value={JSON.stringify(beach)}
              />
            )}
            <textarea
              className="inputField"
              type="text"
              className="field"
              rows="5"
              placeholder="Customize Your Tour"
              name="Customize your tour"
            />

            <input className="button" type="submit" />
          </form>
        </div>

        {/* <div className="notes">
                <h2>Notes</h2>
                <h3>ECO - Eco Lodges</h3>
                <h3>TC- Tented Camps</h3>
                <h3>AITC - Al inclusive Tented Camps</h3>
                <p>Hotels are subject to availability.</p>
            <p>Travel durations are approximate and will depend on the traffic &amp; weather conditions.</p>
            <p>Duration for experiences are excluding the driving time to the location.</p>
            <p>The Signature &amp; Premium Signature Collections are not a category of hotels, but a set of selected properties to complement the essence of each tour respectively.</p>
            </div> */}
        <Footer_SriLanka />
      </div>
    </Router>
  );
};

export default HistoryandHeritage;
