import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_IslandDay7 from "./TourSlides_IslandDay7";

const Tour_IslandDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_IslandDay7 />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Sigiriya - Polonnaruwa [01:30] - Sigiriya</h2>
      </div>
      <p>Cycle through the Ancient Kingdom of Polonnaruwa-UNESCO [02:30]</p>
      <p>Afternoon Jeep Safari in Minneriya National Park [03:00]</p>
    </div>
  );
};

export default Tour_IslandDay7;
