import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import TourSlides_HistoryDay4 from "./TourSlides_HistoryDay4";

const Tour_HistoryDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_HistoryDay4 />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Sigiriya - Kandy [03:30]</h2>
      </div>
      <p>Visit the Dambulla Cave Temple-UNESCO [00:40]</p>
    </div>
  );
};

export default Tour_HistoryDay4;
