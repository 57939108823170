import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd4_slide from "./Serenityd4_slide";

const Tour_SerenityDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd4_slide />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Anuradgapura - Sigiriya [01:00]</h2>
      </div>
      <p>Spa Treatment [00:45]</p>
    </div>
  );
};

export default Tour_SerenityDay4;
