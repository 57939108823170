import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Ignited9_slide from "./Ignited9_slide";

const Tour_IgniteDay9 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited9_slide />
      </div>
      <div className="line">
        <h3>Day 09 : </h3>
        <h2>Anuradhapura </h2>
      </div>
      <p>Jungle Kayaking [01:00]</p>
      <p>Visit the Ancient Kingdom of Anuradhapura-UNESCO [02:00]</p>
      <p>BBQ dinner at Wiluwwa </p>
    </div>
  );
};

export default Tour_IgniteDay9;
