import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Wildlifed10_slide from "./Wildlifed10_slide";

const Tour_WildlifeDay10 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Wildlifed10_slide />
      </div>
      <div className="line">
        <h3>Day 10 : </h3>
        <h2>Wilpattu National Park</h2>
      </div>
      <p>Morning Jeep Safari in Wilpattu National Park [03:00]</p>
    </div>
  );
};

export default Tour_WildlifeDay10;
