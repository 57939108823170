import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Wildlifed7_slide from "./Wildlifed7_slide";

const Tour_WildlifeDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Wildlifed7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Gal Oya National Park</h2>
      </div>
      <p>Boat safari on the Senanyake Samudra with picnic [06:00]</p>
    </div>
  );
};

export default Tour_WildlifeDay7;
