import React, { useEffect, useState } from "react";
import { SliderData } from "./assets/Data/SliderData";
import Dropdown from "./components/Dropdown";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import GlobalStyle from "./globalStyle";
import BasicSlider from "../src/components/BasicSlider/BasicSlider";
import VerticalSlider from "../src/components/VerticalSlider/VerticalSlider";
import StorySection from "./components/StorySection";
import SriLankaSection from "./components/SriLankaSection";
import BasicSliderMobile from "./components/BasicSlider/BasicSliderMobile";
import "./assets/css/Home.css";
import MaldivesSection from "./components/MaldivesSection";
import MaldivesSectionOriginal from "./components/MaldivesSectionOriginal";
import TeamSection from "./components/TeamSection";
import Footer from "./components/Footer";
import NavbarSample from "./components/NavbarSample";
import NavbarDark from "./components/NavbarDark";
import Destinations from "./components/Destinations";

const mark = "red";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.title = "Silan Travels";
  }, []);

  const red = "#000";

  // const [offsetY, setOffsetY] = useState(0);
  // const handleScroll = () => setOffsetY(window.pageYOffset);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <div>
      {/* <GlobalStyle /> */}
      {/* <NavbarSample toggle={toggle} /> */}
      <NavbarDark toggle={toggle} />
      {/* <Navbar toggle={toggle} red="black" mark={mark} /> */}
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <div className="basicSlider">
        <BasicSlider />
      </div>
      <div className="basicSliderMobile">
        <BasicSliderMobile />
      </div>
      <StorySection />
      <SriLankaSection id="srilanka" />
      {/* <Destinations id="destinations" /> */}
      <MaldivesSectionOriginal />
      <TeamSection />
      <Footer />
      {/* <MaldivesSection /> */}
      {/* <VerticalSlider /> */}
      {/* <Hero slides={SliderData} /> */}
    </div>
  );
};

export default Home;
