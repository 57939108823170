import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Getawayd3_slide from "./Getawayd3_slide";

const Tour_GetawayDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Getawayd3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Sigiriya - Polonnaruwa [01:30] - Sigiriya</h2>
      </div>
      <p>Meet the Monkeys of Polonnaruwa [02:00]</p>
      <p>Visit the Ancient Kingdom of Polonnaruwa-UNESCO [02:30]</p>
      <p>Afternoon Jeep Safari in Minneriya National Park [03:00]</p>
    </div>
  );
};

export default Tour_GetawayDay3;
