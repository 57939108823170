import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/trihotel.jpg";
import two from "../assets/images/slhotels/thotalgala.jpg";
import three from "../assets/images/slhotels/galoyalodge.jpg";
import four from "../assets/images/slhotels/ulagalla.jpg";
import five from "../assets/images/slhotels/wattura.jpg";

const PremiumCollection_Ignite = () => {
  return (
    <div className="collection">
      <h3>Premium Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Tri Hotel Koggala by Reveal"
      />
      <HotelSL
        image={two}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Thotalagala (AI)"
      />
      <HotelSL
        image={three}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Gal Oya Lodge (ECO) "
      />
      <HotelSL
        image={four}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Ulagalla by Uga"
      />
      <HotelSL
        image={five}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Wattura Resort & Spa"
      />
    </div>
  );
};

export default PremiumCollection_Ignite;
