import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Ignited1_slide from "./Ignited1_slide";

const Tour_IgniteDay1 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited1_slide />
      </div>
      <div className="line">
        <h3>Day 01 : </h3>
        <h2>CMB Airport - Galle [02:30]</h2>
      </div>
      <p>Unwind</p>
    </div>
  );
};

export default Tour_IgniteDay1;
