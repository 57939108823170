import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import uuidv4 from "uuid";
import { config } from "react-spring";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import "../assets/css/DesSpringSlider.css";

import slide1 from "../assets/images/newdest/Slide1.PNG";
import slide2 from "../assets/images/newdest/Slide2.PNG";
import slide3 from "../assets/images/newdest/Slide3.PNG";
import slide4 from "../assets/images/newdest/Slide4.PNG";
import slide5 from "../assets/images/newdest/Slide5.PNG";
import slide6 from "../assets/images/newdest/Slide6.PNG";
import slide7 from "../assets/images/newdest/Slide7.PNG";
import slide8 from "../assets/images/newdest/Slide8.PNG";
import slide9 from "../assets/images/newdest/Slide9.PNG";
import slide10 from "../assets/images/newdest/Slide10.PNG";
import slide11 from "../assets/images/newdest/Slide11.PNG";
import slide12 from "../assets/images/newdest/Slide12.PNG";
import slide13 from "../assets/images/newdest/Slide13.PNG";
import slide14 from "../assets/images/newdest/Slide14.PNG";
import slide15 from "../assets/images/newdest/Slide15.PNG";

export default class DesSpringSlider extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  };

  slides = [
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide1} alt="1" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide2} alt="2" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide3} alt="3" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide4} alt="4" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide5} alt="5" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide6} alt="6" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide7} alt="7" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide8} alt="8" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide9} alt="9" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide10} alt="10" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide11} alt="11" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide12} alt="12" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide13} alt="13" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide14} alt="14" />,
    },
    {
      key: uuidv4(),
      content: <img className="slideImg3d" src={slide15} alt="15" />,
    },
  ];

  render() {
    return (
      <div className="slider3dcontainer">
        <Carousel
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          animationConfig={this.state.config}
        />
        <div
          style={{
            margin: "0 auto",
            marginTop: "2rem",
            width: "50%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div>
            <ArrowBackIos
              className="forwardandbackicons"
              onClick={() => {
                this.setState({ goToSlide: this.state.goToSlide - 1 });
              }}
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
            <ArrowForwardIosIcon
              className="forwardandbackicons"
              onClick={() => {
                this.setState({ goToSlide: this.state.goToSlide + 1 });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
