import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_IslandDay9 from "./TourSlides_IslandDay9";

const Tour_IslandDay9 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_IslandDay9 />
      </div>
      <div className="line">
        <h3>Day 09 : </h3>
        <h2>Negombo - CMB Airport [00:30]</h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_IslandDay9;
