import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/jetwingKurulubedda.jpg";
import two from "../assets/images/slhotels/rainforesteco.jpg";
import three from "../assets/images/slhotels/ceylonwild.jpg";
import four from "../assets/images/slhotels/galoyalodge.jpg";
import five from "../assets/images/slhotels/wildgrass.jpg";
import six from "../assets/images/slhotels/leopardtrails.jpg";
import seven from "../assets/images/slhotels/dutchbay.jpg";

const SignatureCollection_Wildlife = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="Jetwing Kurulubedda (ECO) "
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="The Rainforest Eco lodge (ECO) "
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Signature "
        hotel="Ceylon Wild Safaris (AITC) "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Signature "
        hotel="Gal Oya Lodge (ECO)"
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Signature "
        hotel="Wild Grass Nature Resort "
      />
      <HotelSL
        image={six}
        color="#d5b23f"
        catName="Signature "
        hotel="Leopard Trails Yala (AITC) "
      />
      <HotelSL
        image={seven}
        color="#d5b23f"
        catName="Signature "
        hotel="Dutch Bay Resort "
      />
    </div>
  );
};

export default SignatureCollection_Wildlife;
