import React from "react";

import "../assets/css/Tour_Destinations.css";

import Destinations1_slide from "./Destinations1_slide";
import Destinations6_slide from "./Destinations6_slide";

const Destinations6 = () => {
  return (
    <div className="tour_Destinations">
      <div className="slides">
        <Destinations6_slide />
      </div>
      {/* <div className="line"></div> */}
    </div>
  );
};

export default Destinations6;
