import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Bondd3_slide from "./Bondd3_slide";

const Tour_BondDay3 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Bondd3_slide />
      </div>
      <div className="line">
        <h3>Day 03 : </h3>
        <h2>Galle - Yala National Park [02:30] </h2>
      </div>
      <p>Afternoon Jeep Safari in Yala National Park [03:00]</p>
    </div>
  );
};

export default Tour_BondDay3;
