import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond9_slide from "./Immersiond9_slide";
import Immersiond10_slide from "./Immersiond10_slide";

const Tour_immersionDay10 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond10_slide />
      </div>
      <div className="line">
        <h3>Day 10 : </h3>
        <h2>Sigiriya - Anuradhapura [01:30]</h2>
      </div>
      <p>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</p>
      <p>Visit the Ancient Kingdom of Anuradhapura-UNESCO [02:00]</p>
    </div>
  );
};

export default Tour_immersionDay10;
