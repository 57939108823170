import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Bondd4_slide from "./Bondd4_slide";

const Tour_BondDay4 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Bondd4_slide />
      </div>
      <div className="line">
        <h3>Day 04 : </h3>
        <h2>Yala National Park </h2>
      </div>
      <p>Morning Jeep Safari in Yala National Park [03:00]</p>
    </div>
  );
};

export default Tour_BondDay4;
