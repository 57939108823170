import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import partner from "../assets/images/partner.jpg";
import "../assets/css/Partners.css";
import Footer from "./Footer";
import Multiselect from "multiselect-react-dropdown";
import david from "../assets/images/team/davidgomes.png";
import irene from "../assets/images/team/irenenew1.png";
import NavbarSample from "./NavbarSample";
import NavbarPartners from "./NavbarPartners";
import DropdownPartners from "./DropdownPartners";
import NavbarDarkPartners from "./NavbarDarkPartners";
import Footer_Partners from "./Footer_Partners";

const networkData = [
  {
    option: "Traveller Made",
  },
  {
    option: "American Express",
  },
  {
    option: "Pure Life Experiences",
  },
  {
    option: "Virtuoso",
  },
  {
    option: "Emotions Travel Community",
  },
  {
    option: "Connections Luxury",
  },
  {
    option: "Signature Travel Network",
  },
  {
    option: "Travel Leaders",
  },
  {
    option: "Private Luxury Forms",
  },
  {
    option: "Luxperience",
  },
  {
    option: "Not Applicable",
  },
];

const companyData = [
  {
    type: "Travel Agent (FIT)",
  },
  {
    type: "Travel Agent (MICE)",
  },
  {
    type: "Tour Operator",
  },
  {
    type: "Travel Designer (Independant)",
  },
  {
    type: "Tourism Marketing (Representatives)",
  },
];

const Partners = () => {
  const [network, setNetwork] = useState([]);
  const [comptype, setComptype] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.title = "Partner with Us";
  }, []);

  return (
    <div className="partnerWithUs">
      <div className="navBar">
        <NavbarDarkPartners toggle={toggle} />
      </div>
      <div className="indexofz">
        <DropdownPartners isOpen={isOpen} toggle={toggle} />
      </div>
      {/* navbar */}
      <div className="headerContent">
        <h1>
          Partner <span>With Us</span>
        </h1>
      </div>
      <div className="formContainer">
        <form
          action="https://formsubmit.co/enquiries@silantravels.com"
          method="POST"
        >
          <input type="hidden" name="_subject" value="Partner Submission!" />
          <input
            className="inputField"
            type="text"
            name="Company Name"
            placeholder="Company Name"
            required
          />
          <input
            className="inputField"
            type="email"
            placeholder="Email"
            name="Email"
            required
          />
          <div className="seperate">
            <input
              className="inputField1"
              type="country"
              name="Country"
              placeholder="Country"
              required
            />
            <input
              className="inputField2"
              type="mobile"
              placeholder="Telephone"
              name="Telephone"
            />
          </div>
          <div className="seperate">
            <Multiselect
              style={{
                searchBox: {
                  border: "none",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  padding: "17px 10px",
                  marginBottom: "10px",
                  borderRadius: "10px",
                  fontStyle: "sans-serif",
                  marginRight: "10px",
                  zIndex: "3 !important",
                },
              }}
              placeholder="Company Type"
              displayValue="type"
              options={companyData}
              onSelect={(e) => {
                setComptype(Array.isArray(e) ? e.map((x) => x.type) : "");
              }}
              onRemove={(e) => {
                setComptype(Array.isArray(e) ? e.map((x) => x.type) : "");
              }}
              avoidHighlightFirstOption={true}
              singleSelect={true}
            />
            {network && (
              <input
                name="Company Type"
                style={{ display: "none" }}
                value={JSON.stringify(comptype)}
              />
            )}
            <Multiselect
              style={{
                searchBox: {
                  zIndex: 10,
                  border: "none",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  padding: "17px 10px",
                  marginBottom: "10px",
                  borderRadius: "10px",
                  fontStyle: "sans-serif",
                },
              }}
              placeholder="Travel Network"
              displayValue="option"
              options={networkData}
              onSelect={(e) => {
                setNetwork(Array.isArray(e) ? e.map((x) => x.option) : "");
              }}
              onRemove={(e) => {
                setNetwork(Array.isArray(e) ? e.map((x) => x.option) : "");
              }}
              avoidHighlightFirstOption={true}
              singleSelect={true}
            />
            {network && (
              <input
                name="Travel Network"
                style={{ display: "none" }}
                value={JSON.stringify(network)}
              />
            )}
          </div>

          <textarea
            className="inputField"
            type="text"
            rows="5"
            placeholder="Your Message"
            name="Message"
          />
          <div className="checkarea">
            <label className="label">Subscribe to Our Newsletter</label>
            <input className="checker" type="checkbox" name="Newsletter" />
          </div>

          <input
            type="hidden"
            name="_subject"
            value="Partner Submission"
          ></input>
          <input className="partnerSubmit" type="submit" />
        </form>
      </div>
      {/* <h2 className="botTitle">
        Meet Our <span className="specialPart">Representatives</span>
      </h2>
      <div className="bottomContent">
        <div className="block">
          <img alt="" src={david} />
          <h2>David Gomes</h2>
          <p>david@silantravels.com</p>
          <p>+33 612 321 725</p>
        </div>
        <div className="block">
          <img alt="" src={irene} />
          <h2>Irene Santiyago</h2>
          <p>irene@silantravels.com</p>
          <p>+34 630 206 596</p>
        </div>
      </div> */}
      <div className="footer">
        <Footer_Partners />
      </div>
    </div>
  );
};

export default Partners;
