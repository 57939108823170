import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Serenityd9_slide from "./Serenityd9_slide";

const Tour_SerenityDay9 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Serenityd9_slide />
      </div>
      <div className="line">
        <h3>Day 09 : </h3>
        <h2>Hatton - Galle [00:30] </h2>
      </div>
      <p>Domestic flight from Hatton to Galle [00:30]</p>
      <p>Walk indside the ramparts of Galle Fort [01:00]</p>
    </div>
  );
};

export default Tour_SerenityDay9;
