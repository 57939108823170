import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Budsd7_slide from "./Budsd7_slide";

const Tour_BudsDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Budsd7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Kegalle - Kandy [01:00] - Kagalle </h2>
      </div>
      <p>
        Visit the organic kitchen garden with the Chef and cook authentic Sri
        Lankan curries [02:00]
      </p>
      <p>Visit the Temple of the Sacred Tooth Relic -UNESCO [01:30]</p>
    </div>
  );
};

export default Tour_BudsDay7;
