import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond12_slide from "./Immersiond12_slide";

const Tour_immersionDay12 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond12_slide />
      </div>
      <div className="line">
        <h3>Day 12 : </h3>
        <h2>Negombo - CMB Airport [00:30]</h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_immersionDay12;
