import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Rushd7_slide from "./Rushd7_slide";

const Tour_RushDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Rushd7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Kithulgala</h2>
      </div>
      <p>Rafting and Canyoning on the Kelani River [03:00]</p>
    </div>
  );
};

export default Tour_RushDay7;
