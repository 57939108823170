import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/calamansi.jpg";
import two from "../assets/images/slhotels/sunsiyam.jpg";

const SuperiorCollectionBeach_Getaway = () => {
  return (
    <div className="collection">
      <h3>Superior Collection</h3>
      <HotelSL
        image={one}
        color="#ddbf61"
        catName="Superior "
        hotel="Calamansi Cove Villas by Jetwing "
      />
      <HotelSL
        image={two}
        color="#ddbf61"
        catName="Superior "
        hotel="Sun Siyam Pasikudah "
      />
    </div>
  );
};

export default SuperiorCollectionBeach_Getaway;
