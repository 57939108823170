import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Wildlifed2_slide from "./Wildlifed2_slide";

const Tour_WildlifeDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Wildlifed2_slide />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Galle - Sinharaja Forest Reserve [03:30]</h2>
      </div>
      <p>Trek through the Kanneliya Rainforest with lunch [03:00]</p>
    </div>
  );
};

export default Tour_WildlifeDay2;
