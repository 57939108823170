import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";
import wild from "../assets/images/slhotels/wildgrass.jpg";
import bouga from "../assets/images/slhotels/bougainvillea.jpg";
import galle from "../assets/images/slhotels/gallefortt.jpg";

const DeluxeCollection_History = () => {
  return (
    <div className="collection">
      <h3>Deluxe Collection</h3>
      <HotelSL
        image={wild}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Wild Grass Nature Resort  "
      />
      <HotelSL
        image={bouga}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Bougainvillea Retreat  "
      />
      <HotelSL
        image={galle}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Galle Fort Hotel  "
      />
    </div>
  );
};

export default DeluxeCollection_History;
