import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond5_slide from "./Immersiond5_slide";

const Tour_immersionDay5 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond5_slide />
      </div>
      <div className="line">
        <h3>Day 05 : </h3>
        <h2>Yala National Park - Ella [02:30]</h2>
      </div>
      <p>Morning Jeep Safari in Yala National Park [03:00]</p>
      <p>Ravana Falls [00:15]</p>
    </div>
  );
};

export default Tour_immersionDay5;
