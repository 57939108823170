import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Immersiond6_slide from "./Immersiond6_slide";

const Tour_immersionDay6 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Immersiond6_slide />
      </div>
      <div className="line">
        <h3>Day 06 : </h3>
        <h2>Ella </h2>
      </div>
      <p>Hike through the Horton Plains National Park [04:00]</p>
      <p>Nine Arch Bridge [00:30]</p>
    </div>
  );
};

export default Tour_immersionDay6;
