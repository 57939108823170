import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond2_slide from "./Dimensiond2_slide";

const Tour_DimensionDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond2_slide />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Negombo - Anuradhapura [04:00]</h2>
      </div>
      <p>Gin Oya River Safari [01:00]</p>
    </div>
  );
};

export default Tour_DimensionDay2;
