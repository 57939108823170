import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_PostcardDay8 from "./TourSlides_PostcardDay8";

const Tour_PostcardDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_PostcardDay8 />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Galle - CMB Airport [02:30]</h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_PostcardDay8;
