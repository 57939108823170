import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond1_slide from "./Dimensiond1_slide";

const Tour_DimensionDay1 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond1_slide />
      </div>
      <div className="line">
        <h3>Day 01 : </h3>
        <h2>CMB Airport - Negombo [00:30]</h2>
      </div>
      <p>Unwind</p>
    </div>
  );
};

export default Tour_DimensionDay1;
