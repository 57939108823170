import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_PostcardDay2 from "./TourSlides_PostcardDay2";

const Tour_PostcardDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_PostcardDay2 />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Colombo - Kegalle [02:00] - Sigiriya [02:30]</h2>
      </div>
      <p>Walk with the elephants at Millennium Elephant Foundation [01:30]</p>
      <p>Visit the Dambulla Cave Temple-UNESCO [00:40]</p>
    </div>
  );
};

export default Tour_PostcardDay2;
