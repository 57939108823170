import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/cocobay.jpg";
import two from "../assets/images/slhotels/cbeyond.jpg";

const SuperiorCollectionBeach_Postcard = () => {
  return (
    <div className="collection">
      <h3>Superior Collection</h3>
      <HotelSL
        image={one}
        color="#ddbf61"
        catName="Superior "
        hotel="CocoBay Unawatuna"
      />
      <HotelSL
        image={two}
        color="#ddbf61"
        catName="Superior "
        hotel="C Beyond Nilaveli  "
      />
    </div>
  );
};

export default SuperiorCollectionBeach_Postcard;
