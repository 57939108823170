import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/amangalla.jpg";
import two from "../assets/images/slhotels/ugachena.jpg";
import three from "../assets/images/slhotels/ceylontea.jpg";
import four from "../assets/images/slhotels/kingspavilion.jpg";
import five from "../assets/images/slhotels/ulagalla.jpg";
import six from "../assets/images/slhotels/maniumpathy.jpg";

const PremiumCollection_Bond = () => {
  return (
    <div className="collection">
      <h3>Premium Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Amangalla "
      />
      <HotelSL
        image={two}
        color="#d4af38"
        catName="Premium Signature "
        hotel=" Chena Huts by Uga (AI)"
      />
      <HotelSL
        image={three}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Ceylon Tea Trails (AI) "
      />
      <HotelSL
        image={four}
        color="#d4af38"
        catName="Premium Signature "
        hotel="King's Pavilion "
      />
      <HotelSL
        image={five}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Ulagalla by Uga"
      />
      <HotelSL
        image={six}
        color="#d4af38"
        catName="Premium Signature "
        hotel="Maniumpathi "
      />
    </div>
  );
};

export default PremiumCollection_Bond;
