import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import TourSlides_PostcardDay7 from "./TourSlides_postcardDay7";

const Tour_PostcardDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_PostcardDay7 />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>Yala National Park - Galle [02:30]</h2>
      </div>
      <p>Walk inside the ramparts of Galle Fort -UNESCO [01:00]</p>
    </div>
  );
};

export default Tour_PostcardDay7;
