import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Ignited7_slide from "./Ignited7_slide";

const Tour_IgniteDay7 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited7_slide />
      </div>
      <div className="line">
        <h3>Day 07 : </h3>
        <h2>
          Gal Oya National Park - Polonnaruwa [02:30] - Anuradhapura [01:30]{" "}
        </h2>
      </div>
      <p>Cycle through the Ancient Kingdom of Polonnaruwa-UNESCO [02:30]</p>
    </div>
  );
};

export default Tour_IgniteDay7;
