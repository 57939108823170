import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond8_slide from "./Dimensiond8_slide";

const Tour_DimensionDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Gal Oya National Park </h2>
      </div>
      <p>Boat safari on the Senanyake Samudra with picnic [06:00] </p>
    </div>
  );
};

export default Tour_DimensionDay8;
