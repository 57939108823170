import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/kk.jpg";
import two from "../assets/images/slhotels/anantayaresort.jpg";

const DeluxeCollectionBeach_Postcard = () => {
  return (
    <div className="collection">
      <h3>Deluxe Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Deluxe "
        hotel="KK Beach "
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Deluxe "
        hotel="Anantaya Resort and Spa Passikuda"
      />
    </div>
  );
};

export default DeluxeCollectionBeach_Postcard;
