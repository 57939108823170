import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import TourSlides_HistoryDay2 from "./TourSlides_HistoryDay2";

const Tour_HistoryDay2 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <TourSlides_HistoryDay2 />
      </div>
      <div className="line">
        <h3>Day 02 : </h3>
        <h2>Sigiriya - Anuradhapura [01:30] - Sigiriya </h2>
      </div>
      <p>Visit the Ancient Kingdom of Anuradhapura-UNESCO [02:00]</p>
      <p>Visit the Mihintale Temple [00:30]</p>
    </div>
  );
};

export default Tour_HistoryDay2;
