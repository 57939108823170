import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Bondd11_slide from "./Bondd11_slide";

const Tour_BondDay11 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Bondd11_slide />
      </div>
      <div className="line">
        <h3>Day 11 : </h3>
        <h2>Anuradhapura - Sigiriya [01:00] - Colombo [03:30] </h2>
      </div>
      <p>Climb the steps of the Sigiriya Rock Fortress -UNESCO [02:00]</p>
    </div>
  );
};

export default Tour_BondDay11;
