import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sample from "../assets/images/SlickSlider/newimages/history.png";
import "../assets/css/TourSlides.css";

import one from "../assets/images/spatreatment2.png";

const Serenityd4_slide = () => {
  var settings = {
    dots: false,
    infinite: true,

    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="slider">
      <Slider {...settings}>
        <div className="tile">
          <img alt="" src={one} />
          <h2>Spa Treatment [00:45]</h2>
        </div>
      </Slider>
    </div>
  );
};

export default Serenityd4_slide;
