import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sample from "../assets/images/SlickSlider/newimages/history.png";
import "../assets/css/TourSlides_Destinations.css";

import one from "../assets/images/destinations/Slide8.PNG";

const Destinations8_slide = () => {
  var settings = {
    dots: false,
    infinite: true,

    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="slider_Destinations">
      <Slider {...settings} pauseOnHover={true}>
        <div className="tile_Destinations">
          <h2>East Coast</h2>
          <img alt="" src={one} />
        </div>
        {/* <div className="tile_Destinations">
          <img alt="" src={two} />
          <h2>Climb the steps of Sigiriya Rock Fortress</h2>
        </div>
        <div className="tile_Destinations">
          <img alt="" src={three} />
          <h2>Visit the Dambulla Cave Temple</h2>
        </div>
        <div className="tile_Destinations">
          <img alt="" src={four} />
          <h2>Fly above the Cultural triangle in a hot air balloon</h2>
        </div>
        <div className="tile_Destinations">
          <img alt="" src={five} />
          <h2>Jeep safari in Minneriya National Park</h2>
        </div>
        <div className="tile_Destinations">
          <img alt="" src={six} />
          <h2>Visit the Ancient Kingdom of Polonnaruwa</h2>
        </div> */}
      </Slider>
    </div>
  );
};

export default Destinations8_slide;
