import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/galleface.jpg";
import two from "../assets/images/slhotels/cinnamonlodge.jpg";
import three from "../assets/images/slhotels/earlskandy.jpg";
import four from "../assets/images/slhotels/ellaflower.jpg";
import five from "../assets/images/slhotels/cinnamonwild.jpg";
import six from "../assets/images/slhotels/bungalowgalle.jpg";

const StandardCollection_Postcard = () => {
  return (
    <div className="collection">
      <h3>Standard Collection</h3>
      <HotelSL
        image={one}
        color="#dec267"
        catName="Standard "
        hotel="Galle Face Hotel "
      />
      <HotelSL
        image={two}
        color="#dec267"
        catName="Standard "
        hotel="Cinnamon Lodge Habarana "
      />
      <HotelSL
        image={three}
        color="#dec267"
        catName="Standard "
        hotel="Earl's Regency Hotel"
      />
      <HotelSL
        image={four}
        color="#dec267"
        catName="Standard "
        hotel="Ella Flower Garden "
      />
      <HotelSL
        image={five}
        color="#dec267"
        catName="Standard "
        hotel="Cinnamon Wild Yala "
      />
      <HotelSL
        image={six}
        color="#dec267"
        catName="Standard "
        hotel="The Bungalow Galle Fort"
      />
    </div>
  );
};

export default StandardCollection_Postcard;
