import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/FilterNavbar.css";

const FilterNavbar = ({ Active }) => {
  return (
    <div className="navbarFilter">
      
      <Link className="link" to="/">
        Deluxe Collection
      </Link>
      <Link className="link" to="/maldives/superdeluxe">
        Super Deluxe Collection
      </Link>
      <Link className="link" to="/maldives/luxury">
        Luxury Collection
      </Link>
      <Link className="link" to="/maldives/ultraluxury">
        Ultra Luxury Collection
      </Link>
    </div>
  );
};

export default FilterNavbar;
