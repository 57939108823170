import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Dimensiond14_slide from "./Dimensiond14_slide";

const Tour_DimensionDay14 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Dimensiond14_slide />
      </div>
      <div className="line">
        <h3>Day 14 : </h3>
        <h2>Galle - CMB Airport [02:30] </h2>
      </div>
      <p>End of Tour</p>
    </div>
  );
};

export default Tour_DimensionDay14;
