import React from "react";
import HotelSL from "./HotelSL";
import "../assets/css/Collection.css";
import bond from "../assets/images/SlickSlider/newimages/bond.png";

import one from "../assets/images/slhotels/tvlakelodge.jpg";
import two from "../assets/images/slhotels/lakelodge.jpg";
import three from "../assets/images/slhotels/thotupola.jpg";
import four from "../assets/images/slhotels/secretella.jpg";
import five from "../assets/images/slhotels/lighthouseStreet.jpg";

const SignatureCollection_Travel = () => {
  return (
    <div className="collection">
      <h3>Signature Collection</h3>
      <HotelSL
        image={one}
        color="#d5b23f"
        catName="Signature "
        hotel="Taru Villas - Lake Lodge"
      />
      <HotelSL
        image={two}
        color="#d5b23f"
        catName="Signature "
        hotel="Lake Lodge Kandalama"
      />
      <HotelSL
        image={three}
        color="#d5b23f"
        catName="Signature "
        hotel="Thotupola Residence Homestay "
      />
      <HotelSL
        image={four}
        color="#d5b23f"
        catName="Signature "
        hotel="Secret Ella "
      />
      <HotelSL
        image={five}
        color="#d5b23f"
        catName="Signature "
        hotel="41 Lighthouse Street "
      />
    </div>
  );
};

export default SignatureCollection_Travel;
