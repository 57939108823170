import { Translate } from "@material-ui/icons";
import React, { Component } from "react";
import Slider from "react-slick";
import "../assets/css/Days_Wildlife.css"
import Tour_GetawayDay1 from "./Getawayd1";
import Tour_GetawayDay10 from "./Getawayd10";
import Tour_GetawayDay2 from "./Getawayd2";
import Tour_GetawayDay3 from "./Getawayd3";
import Tour_GetawayDay4 from "./Getawayd4";
import Tour_GetawayDay5 from "./Getawayd5";
import Tour_GetawayDay6 from "./Getawayd6";
import Tour_GetawayDay7 from "./Getawayd7";
import Tour_GetawayDay8 from "./Getawayd8";
import Tour_GetawayDay9 from "./Getawayd9";
import Tour_IgniteDay1 from "./Ignited1";
import Tour_IgniteDay10 from "./Ignited10";
import Tour_IgniteDay2 from "./Ignited2";
import Tour_IgniteDay3 from "./Ignited3";
import Tour_IgniteDay4 from "./Ignited4";
import Tour_IgniteDay5 from "./Ignited5";
import Tour_IgniteDay6 from "./Ignited6";
import Tour_IgniteDay7 from "./Ignited7";
import Tour_IgniteDay8 from "./Ignited8";
import Tour_IgniteDay9 from "./Ignited9";
import Tour_IgniteDay11 from "./Ignitedd11";
import Tour_immersionDay1 from "./Immersiond1";
import Tour_immersionDay10 from "./Immersiond10";
import Tour_immersionDay11 from "./Immersiond11";
import Tour_immersionDay12 from "./Immersiond12";
import Tour_immersionDay2 from "./Immersiond2";
import Tour_immersionDay3 from "./Immersiond3";
import Tour_immersionDay4 from "./Immersiond4";
import Tour_immersionDay5 from "./Immersiond5";
import Tour_immersionDay6 from "./Immersiond6";
import Tour_immersionDay7 from "./immersiond7";
import Tour_immersionDay8 from "./Immersiond8";
import Tour_immersionDay9 from "./Immersiond9";
import Tour_WildlifeDay1 from "./Wildlifed1";
import Tour_WildlifeDay10 from "./Wildlifed10";
import Tour_WildlifeDay11 from "./Wildlifed11";
import Tour_WildlifeDay12 from "./Wildlifed12";
import Tour_WildlifeDay13 from "./Wildlifed13";
import Tour_WildlifeDay2 from "./Wildlifed2";
import Tour_WildlifeDay3 from "./Wildlifed3";
import Tour_WildlifeDay4 from "./Wildlifed4";
import Tour_WildlifeDay5 from "./Wildlifed5";
import Tour_WildlifeDay6 from "./Wildlifed6";
import Tour_WildlifeDay7 from "./Wildlifed7";
import Tour_WildlifeDay8 from "./Wildlifed8";
import Tour_WildlifeDay9 from "./Wildlifed9";


export default class Days_Wildlife extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      bdcolor: false,
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d9bdcolor: "#008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d13bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bxshadow: "0 0 4px #008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bxshadow: "0 0 4px #008080",
    };
  }

  changeColor = (e) => {
    this.setState({
        bdcolor: true
    })
  }

  changeColord1 = (e) => {
    this.setState({
      d1bdcolor: "#f1c40f",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d9bdcolor: "#008080",
      d1bxshadow: "0 0 4px #f1c40f",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }
  changeColord2 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#f1c40f",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #f1c40f",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord3 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#f1c40f",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #f1c40f",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord4 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#f1c40f",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #f1c40f",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord5 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#f1c40f",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #f1c40f",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord6 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#f1c40f",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #f1c40f",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord7 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#f1c40f",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #f1c40f",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord8 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#f1c40f",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #f1c40f",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord9 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#f1c40f",
      d9bxshadow: "0 0 4px #f1c40f",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord10 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#f1c40f",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #f1c40f",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord11 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#f1c40f",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #f1c40f",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord12 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#f1c40f",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #f1c40f",
      d13bdcolor: "#008080",
      d13bxshadow: "0 0 4px #008080",
    })
  }

  changeColord13 = (e) => {
    this.setState({
      d1bdcolor: "#008080",
      d2bdcolor: "#008080",
      d3bdcolor: "#008080",
      d4bdcolor: "#008080",
      d5bdcolor: "#008080",
      d6bdcolor: "#008080",
      d7bdcolor: "#008080",
      d8bdcolor: "#008080",
      d1bxshadow: "0 0 4px #008080",
      d2bxshadow: "0 0 4px #008080",
      d3bxshadow: "0 0 4px #008080",
      d4bxshadow: "0 0 4px #008080",
      d5bxshadow: "0 0 4px #008080",
      d6bxshadow: "0 0 4px #008080",
      d7bxshadow: "0 0 4px #008080",
      d8bxshadow: "0 0 4px #008080",
      d9bdcolor: "#008080",
      d9bxshadow: "0 0 4px #008080",
      d10bdcolor: "#008080",
      d11bdcolor: "#008080",
      d12bdcolor: "#008080",
      d10bxshadow: "0 0 4px #008080",
      d11bxshadow: "0 0 4px #008080",
      d12bxshadow: "0 0 4px #008080",
      d13bdcolor: "#f1c40f",
      d13bxshadow: "0 0 4px #f1c40f",
    })
  }


  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      d1bdcolor: "#f1c40f",
      d1bxshadow: "0 0 4px #f1c40f"
    });

  }

  render() {
    return (
      <div className="superContainer_Wildlife">
    
        <Slider
            
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={13}
          swipeToSlide={true}
          focusOnSelect={true}
          vertical={true}
          centerMode={true}
          className="navSlider"
        >
          <div className="box">
          <div onClick={this.changeColord1} className="navxx" style={{ borderColor: `${this.state.d1bdcolor}`, boxShadow: `${this.state.d1bxshadow}` }} >
              <h3 tabIndex="1"  className="dayNo" >
                  Day 01
              </h3> 
              <p className="dayTour">
              CMB Airport - Galle [02:30]
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord2} className="navxx" style={{ borderColor: `${this.state.d2bdcolor}`, boxShadow: `${this.state.d2bxshadow}` }} >
              <h3 tabIndex="2" className="dayNo">
                  Day 02
              </h3>
              <p className="dayTour">
              Galle - Sinharaja Forest Reserve  [03:30]
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord3} className="navxx" style={{ borderColor: `${this.state.d3bdcolor}`, boxShadow: `${this.state.d3bxshadow}` }} >
              <h3 className="dayNo">
                  Day 03
              </h3>
              <p className="dayTour">
              Sinharaja Forest Reserve
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord4} className="navxx" style={{ borderColor: `${this.state.d4bdcolor}`, boxShadow: `${this.state.d4bxshadow}` }} >
              <h3 className="dayNo">
                  Day 04
              </h3>
              <p className="dayTour">
              Sinharaja Forest Reserve - Yala National Park [04:00]
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord5} className="navxx" style={{ borderColor: `${this.state.d5bdcolor}`, boxShadow: `${this.state.d5bxshadow}` }} >
              <h3 className="dayNo">
                  Day 05
              </h3>
              <p className="dayTour">
              Yala National Park 
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord6} className="navxx" style={{ borderColor: `${this.state.d6bdcolor}`, boxShadow: `${this.state.d6bxshadow}` }} >
              <h3 className="dayNo">
                  Day 06
              </h3>
              <p className="dayTour">
              Yala National Park - Gal Oya  National Park [03:00]
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord7} className="navxx" style={{ borderColor: `${this.state.d7bdcolor}`, boxShadow: `${this.state.d7bxshadow}` }} >
              <h3 className="dayNo">
                  Day 07
              </h3>
              <p className="dayTour">
              Gal Oya  National Park
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord8} className="navxx" style={{ borderColor: `${this.state.d8bdcolor}`, boxShadow: `${this.state.d8bxshadow}` }} >
              <h3 className="dayNo">
                  Day 08
              </h3>
              <p className="dayTour">
              Gal Oya  National Park - Polonnaruwa [02:30] - Sigiriya [01:30]
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord9} className="navxx" style={{ borderColor: `${this.state.d9bdcolor}`, boxShadow: `${this.state.d9bxshadow}` }} >
              <h3 className="dayNo">
                  Day 09
              </h3>
              <p className="dayTour">
              Sigiriya - Wilpattu National Park  [02:30]
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord10} className="navxx" style={{ borderColor: `${this.state.d10bdcolor}`, boxShadow: `${this.state.d10bxshadow}` }} >
              <h3 className="dayNo">
                  Day 10
              </h3>
              <p className="dayTour">
              Wilpattu National Park
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord11} className="navxx" style={{ borderColor: `${this.state.d11bdcolor}`, boxShadow: `${this.state.d11bxshadow}` }} >
              <h3 className="dayNo">
                  Day 11
              </h3>
              <p className="dayTour">
              Wilpattu National Park - Kalpitiya [02:00]
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord12} className="navxx" style={{ borderColor: `${this.state.d12bdcolor}`, boxShadow: `${this.state.d12bxshadow}` }} >
              <h3 className="dayNo">
                  Day 12
              </h3>
              <p className="dayTour">
              Kalpitiya  
              </p>
          </div>
          </div>
          <div className="box">
          <div onClick={this.changeColord13} className="navxx" style={{ borderColor: `${this.state.d13bdcolor}`, boxShadow: `${this.state.d13bxshadow}` }} >
              <h3 className="dayNo">
                  Day 13
              </h3>
              <p className="dayTour">
              Kalpitiya  - CMB Airport  [04:00]
              </p>
          </div>
          </div>
          
          
          
        </Slider>
        <Slider
        className="main"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          arrows={false}
          autoplay={false}
          swipeToSlide={false}
          slidesToScroll={0}
        >
         <div className="slide">
             <Tour_WildlifeDay1 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay2 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay3 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay4 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay5 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay6 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay7 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay8 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay9 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay10 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay11 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay12 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay13 />
         </div>
         
        
         
         
        </Slider>


        <Slider
        className="mainMobile"
          
          arrows={false}
          autoplay={true}
          autoplaySpeed={2000}
          swipeToSlide={true}
          slidesToScroll={1}
        >
         <div className="slide">
             <Tour_WildlifeDay1 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay2 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay3 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay4 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay5 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay6 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay7 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay8 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay9 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay10 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay11 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay12 />
         </div>
         <div className="slide">
             <Tour_WildlifeDay13 />
         </div>
         
        
         
         
        </Slider>
      </div>
    );
  }
}
