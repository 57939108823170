import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";
import Ignited8_slide from "./Ignited8_slide";

const Tour_IgniteDay8 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Ignited8_slide />
      </div>
      <div className="line">
        <h3>Day 08 : </h3>
        <h2>Anuradhapura - Wilpattu National Park [01:00] - Anuradhapura</h2>
      </div>
      <p>Organic garden tour with cookery demonstration & lunch [02:00]</p>
      <p>Afternoon Jeep Safari in Wilpattu National Park [03:00]</p>
    </div>
  );
};

export default Tour_IgniteDay8;
