import React from "react";
import TourSlides from "./TourSlides";
import "../assets/css/Tour.css";

import Essenced5_slide from "./Essenced5_slide";

const Tour_EssenceDay5 = () => {
  return (
    <div className="tour">
      <div className="slides">
        <Essenced5_slide />
      </div>
      <div className="line">
        <h3>Day 05 : </h3>
        <h2>Ella </h2>
      </div>
      <p>Little Adam’s Peak Hike [02:30]</p>
    </div>
  );
};

export default Tour_EssenceDay5;
