import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sample from "../assets/images/SlickSlider/newimages/history.png";
import "../assets/css/TourSlides.css";

import one from "../assets/images/slslides/TeaFactory.png"
import two from "../assets/images/slslides/TrainJourneyFromNuwaraEliya.png"


const TourSlides_PostcardDay5 = () => {

    var settings = {
        dots: false,
        infinite: true,
    
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
      };
    return (
        <div className="slider">
        <Slider {...settings}  >
            <div className="tile">
                <img alt="" src={one} />
                <h2>Visit a tea factory in the tea country [00:40]</h2>
            </div>
            <div className="tile">
                <img alt="" src={two} />
                <h2>Scenic train journey from Nuwara Eliya to Ella [03:00]</h2>
            </div>
            
            
        </Slider>
        </div>
    )
}

export default TourSlides_PostcardDay5
